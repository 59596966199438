import React from 'react'

const profitlossEvent = () => {
    return (
        <React.Fragment>
            <app-d-profitloss-event _nghost-hpq-c6>
                <div _ngcontent-hpq-c6 className="animated fadeIn divider-top">
                    <div _ngcontent-hpq-c6 className="row">
                        <div _ngcontent-hpq-c6 className="col-sm-12 col-md-12 p-mobile-1">
                            <div _ngcontent-hpq-c6 className="card card-accent-primary">
                                <div _ngcontent-hpq-c6 className="card-header account-detail-head"> Profit Loss Events </div>
                                <div _ngcontent-hpq-c6 className="card-body account-stat-body">
                                    <div _ngcontent-hpq-c6 className="row">
                                        <div _ngcontent-hpq-c6 className="col-md-12 p-mobile-0">
                                            <div _ngcontent-hpq-c6 className="table-responsive">
                                                <div id="DataTables_Table_26_wrapper" className="dataTables_wrapper">
                                                    <div className="dataTables_length" id="DataTables_Table_26_length"><label>Show <select name="DataTables_Table_26_length" aria-controls="DataTables_Table_26" className><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select> entries</label></div>
                                                    <div
                                                        id="DataTables_Table_26_filter" className="dataTables_filter"><label>Search:<input type="search" className placeholder aria-controls="DataTables_Table_26" /></label></div>
                                                    <table _ngcontent-hpq-c6 className="table table-bordered dataTable" datatable id="DataTables_Table_26"
                                                        role="grid" aria-describedby="DataTables_Table_26_info">
                                                        <thead _ngcontent-hpq-c6>
                                                            <tr _ngcontent-hpq-c6 role="row">
                                                                <th _ngcontent-hpq-c6 className="text-center sorting_asc" tabIndex={0} aria-controls="DataTables_Table_26" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Sport Name: activate to sort column descending">Sport Name</th>
                                                                <th _ngcontent-hpq-c6 className="text-center sorting" tabIndex={0} aria-controls="DataTables_Table_26" rowSpan={1} colSpan={1} aria-label="Event Name : activate to sort column ascending">Event Name </th>
                                                                <th _ngcontent-hpq-c6 className="text-center sorting" tabIndex={0} aria-controls="DataTables_Table_26" rowSpan={1} colSpan={1} aria-label="Profit/Loss : activate to sort column ascending">Profit/Loss </th>
                                                                <th _ngcontent-hpq-c6 className="text-center sorting" tabIndex={0} aria-controls="DataTables_Table_26" rowSpan={1} colSpan={1} aria-label="Commission : activate to sort column ascending">Commission </th>
                                                                <th _ngcontent-hpq-c6 className="text-center sorting" tabIndex={0} aria-controls="DataTables_Table_26" rowSpan={1} colSpan={1} aria-label="Total P&amp;L : activate to sort column ascending">Total P&amp;L </th>
                                                            </tr>
                                                        </thead>{/**/}
                                                        <tbody _ngcontent-hpq-c6 role="rowgroup">{/**/}
                                                            <tr _ngcontent-hpq-c6>
                                                                <td _ngcontent-hpq-c6 className="text-center">Cricket</td>
                                                                <td _ngcontent-hpq-c6 className="text-center"><a _ngcontent-hpq-c6 href="javascript:void(0)">England v Afghanistan</a></td>
                                                                <td _ngcontent-hpq-c6 className="text-center font-weight-bold text-success"> 100</td>
                                                                <td _ngcontent-hpq-c6 className="text-center"> 0</td>
                                                                <td _ngcontent-hpq-c6 className="text-center font-weight-bold text-success"> 100</td>
                                                            </tr>
                                                        </tbody>{/**/}
                                                        <tbody>
                                                            <tr className="odd">
                                                                <td valign="top" colSpan={6} className="dataTables_empty">No matching records found</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="dataTables_info" id="DataTables_Table_26_info" role="status" aria-live="polite">Showing 1 to 1 of 1 entries</div>
                                                    <div className="dataTables_paginate paging_full_numbers" id="DataTables_Table_26_paginate"><a className="paginate_button first disabled" aria-controls="DataTables_Table_26" data-dt-idx={0} tabIndex={0} id="DataTables_Table_26_first">First</a><a className="paginate_button previous disabled" aria-controls="DataTables_Table_26"
                                                        data-dt-idx={1} tabIndex={0} id="DataTables_Table_26_previous">Previous</a><span><a className="paginate_button current" aria-controls="DataTables_Table_26" data-dt-idx={2} tabIndex={0}>1</a></span><a className="paginate_button next disabled"
                                                            aria-controls="DataTables_Table_26" data-dt-idx={3} tabIndex={0} id="DataTables_Table_26_next">Next</a><a className="paginate_button last disabled" aria-controls="DataTables_Table_26" data-dt-idx={4} tabIndex={0}
                                                                id="DataTables_Table_26_last">Last</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-d-profitloss-event>
        </React.Fragment>
    )
}

export default profitlossEvent
