import React, { useState, useEffect } from "react";
import axios from "axios";
import Transparent from "../images/sprite/transparent.gif";

export default function Myprofile(props) {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [Comm, setComm] = useState("");

  useEffect(() => {
    axios
      .post("https://liveapi247.live/api1/userprofile", {
        id: props.userid,
      })
      .then((result) => {
        if (result.status === 210) {
          // console.log(result);
          setfirstname(result.data[0].firstname);
          setlastname(result.data[0].lastname);
          setComm(result.data[0].comm);

        }
      })
      .catch((e) => {
        //setIsError(true);
      });
  }, []);
  return (
    <React.Fragment>
      {/* <div _ngcontent-njs-c61 class="col-left">
        <div _ngcontent-njs-c61 class="sub_path">
          <div _ngcontent-njs-c61 class="path">

            <ul>
              <li _ngcontent-njs-c61 class="path-last">
                <a _ngcontent-njs-c61 >My Account</a>
              </li>
            </ul>
          </div>
          <ul _ngcontent-njs-c61 id="sports-path-popup" class="sports-path-popup"></ul>
          <ul _ngcontent-njs-c61 class="sports-side-menu-list">
            <li>
              <a _ngcontent-njs-c61 id="sub_menu_detail" href="/d/dash/myprofile" class="select">
                My Profile
              </a>
            </li>
            <li _ngcontent-njs-c61 >
              <a _ngcontent-njs-c61 id="sub_menu_summary" href="/d/dash/balance">
                Balance Overview
              </a>
            </li>
            <li>
              <a _ngcontent-njs-c61
                id="sub_menu_accountCashStatement_log"
                href="/d/dash/AccountStatement"
              >
                Account Statement
              </a>
            </li>
            <li>
              <a _ngcontent-njs-c61 id="sub_menu_my_bets" href="/d/dash/mybets">
                My Bets
              </a>
            </li>
            <li _ngcontent-njs-c61 >
              <a _ngcontent-njs-c61 id="sub_menu_activity_log" href="/d/dash/activitylog">
                Activity Log
              </a>
            </li>
            <li _ngcontent-njs-c61 >
              <a _ngcontent-njs-c61 id="sub_menu_activity_log" onClick={props.handlepassmodel}>
                Change password
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div _ngcontent-njs-c61 class="centar-content report">

        <div _ngcontent-njs-c61 id="message" class="message-content success">
          <a _ngcontent-njs-c61 class="close-button">Close</a>
          <p></p>
        </div>


        <h2 _ngcontent-njs-c61 >Account Details</h2>

        <div _ngcontent-njs-c61 class="event-left">
          <div _ngcontent-njs-c61 class="profile-wrap">
            <h3 _ngcontent-njs-c61 >About You</h3>
            <dl _ngcontent-njs-c61 >
              <dt _ngcontent-njs-c61 >First Name</dt>
              <dd _ngcontent-njs-c61 >{firstname}</dd>

              <dt _ngcontent-njs-c61 >Last Name</dt>
              <dd _ngcontent-njs-c61 >{lastname}</dd>

              <dt _ngcontent-njs-c61 >Birthday</dt>
              <dd _ngcontent-njs-c61 >--</dd>

              <dt _ngcontent-njs-c61 >E-mail</dt>
              <dd _ngcontent-njs-c61 >--</dd>

              <dt _ngcontent-njs-c61 >Password</dt>
              <dd _ngcontent-njs-c61 >
                ********************************
                <a _ngcontent-njs-c61 onClick={props.handlepassmodel} class="favor-set">
                  Edit
                </a>
              </dd>


            </dl>
          </div>

          <div _ngcontent-njs-c61 class="profile-wrap">
            <h3 _ngcontent-njs-c61 >Address</h3>
            <dl _ngcontent-njs-c61 >
              <dt _ngcontent-njs-c61 >Address</dt>
              <dd _ngcontent-njs-c61 >--</dd>

              <dt _ngcontent-njs-c61 >Town/City</dt>
              <dd _ngcontent-njs-c61 >--</dd>

              <dt _ngcontent-njs-c61 >Country</dt>
              <dd _ngcontent-njs-c61 >--</dd>

              <dt _ngcontent-njs-c61 >Country/State</dt>
              <dd _ngcontent-njs-c61 >--</dd>

              <dt _ngcontent-njs-c61 >Postcode</dt>
              <dd _ngcontent-njs-c61 >--</dd>

              <dt _ngcontent-njs-c61 >Timezone</dt>
              <dd _ngcontent-njs-c61 >IST</dd>
            </dl>
          </div>
        </div>

        <div _ngcontent-njs-c61 class="event-right">
          <div _ngcontent-njs-c61 class="profile-wrap">
            <h3 _ngcontent-njs-c61 >Contact Details</h3>
            <dl _ngcontent-njs-c61 >
              <dt _ngcontent-njs-c61 >Primary number</dt>
              <dd _ngcontent-njs-c61 >--</dd>
            </dl>
          </div>

          <div _ngcontent-njs-c61 class="profile-wrap">
            <h3 _ngcontent-njs-c61 >Setting</h3>
            <dl _ngcontent-njs-c61 >
              <dt _ngcontent-njs-c61 >Currency</dt>
              <dd _ngcontent-njs-c61 >USD</dd>

              <dt _ngcontent-njs-c61 >Odds Format</dt>
              <dd _ngcontent-njs-c61 >--</dd>
            </dl>
          </div>

          <div _ngcontent-njs-c61 class="profile-wrap">
            <h3 _ngcontent-njs-c61 >Commission</h3>
            <dl _ngcontent-njs-c61 >
              <dt _ngcontent-njs-c61 >Comm charged</dt>
              <dd _ngcontent-njs-c61 >{Comm} %</dd>
            </dl>
          </div>
        </div>
      </div> */}
      <div _nghost-sxu-c6>
        <div _ngcontent-sxu-c6 className="animated fadeIn divider-top">
          <div _ngcontent-sxu-c6 className="row">
            <div _ngcontent-sxu-c6 className="col-sm-12 col-md-12 p-mobile-1">
              <div _ngcontent-sxu-c6 className="card card-accent-primary">
                <div _ngcontent-sxu-c6 className="card-header account-detail-head"> Account Details </div>
                <div _ngcontent-sxu-c6 className="card-body account-detail-body">
                  <div _ngcontent-sxu-c6 className="bd-example">
                    <dl _ngcontent-sxu-c6 className="row">
                      <dt _ngcontent-sxu-c6 className="col-sm-3">Name</dt>
                      <dd _ngcontent-sxu-c6 className="col-sm-9">{firstname}</dd>
                      <dt _ngcontent-sxu-c6 className="col-sm-3">Commission</dt>
                      <dd _ngcontent-sxu-c6 className="col-sm-9"> {Comm} % </dd>
                      <dt _ngcontent-sxu-c6 className="col-sm-3">Rolling Commission</dt>
                      <dd _ngcontent-sxu-c6 className="col-sm-9">
                        <a _ngcontent-sxu-c6>
                          <i _ngcontent-sxu-c6 className="fa fa-eye fa-lg" /></a></dd>
                      <dt _ngcontent-sxu-c6 className="col-sm-3">Exposure Limit</dt>
                      <dd _ngcontent-sxu-c6 className="col-sm-9"> 000000 </dd>
                      <dt _ngcontent-sxu-c6 className="col-sm-3">Mobile Number</dt>
                      <dd _ngcontent-sxu-c6 className="col-sm-9"> 00000 </dd>
                      <dt _ngcontent-sxu-c6 className="col-sm-3">Password</dt>
                      <dd _ngcontent-sxu-c6 className="col-sm-9">
                        <span _ngcontent-sxu-c6 className="mr-3">*********</span>
                        <a _ngcontent-sxu-c6 className="edit-password" onClick={props.handlepassmodel} > Edit
                          <i _ngcontent-sxu-c6 className="fa fa-pencil-square-o fa-lg" /></a></dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/**/}{/**/}</div>
    </React.Fragment>
  );
}
