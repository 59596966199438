import React, { useState } from 'react'
import Today from './Today'
import Inplay from './Inplay'
import Tommorrow from './Tommorrow'
import SearchBar from './SearchBar'
import { Rusult } from "./Rusult"



export default function MobileInplay(props) {
    const [Eventday, setEventday] = useState(1)
    const [ResultTab, setResultTab] = useState(false)
    const [Searchbar, setSearchbar] = useState(false)



    const openSearchbar = () => {
        setSearchbar(true)
    }

    const closeSearchbar = () => {
        setSearchbar(false)
    }

    const ResultClickOpen = () => {
        setResultTab(true)
    }

    return (
        <React.Fragment>

            {ResultTab === false &&

                <div className='animated fadeIn'>
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className="row tabmenu">


                                <ul _ngcontent-nuf-c3="" className="tab col3" id="inPlayTab">
                                    <li _ngcontent-nuf-c3="" onClick={() => { setEventday(1) }}><a _ngcontent-nuf-c3="" className={`${(Eventday === 1) ? "select" : "null"}`} id="inPlay">In-Play</a></li>
                                    <li _ngcontent-nuf-c3="" onClick={() => { setEventday(2) }}><a _ngcontent-nuf-c3="" id="today" className={`${(Eventday === 2) ? "select" : "null"}`}>Today</a></li>
                                    <li _ngcontent-nuf-c3="" onClick={() => { setEventday(3) }}><a _ngcontent-nuf-c3="" id="tomorrow" className={`${(Eventday === 3) ? "select" : "null"}`}>Tomorrow</a></li>
                                </ul></div>


                            {/* {Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
                        <div _ngcontent-njs-c67="" style={{ display: "flex" }} class="tab-wrap">

                            <div _ngcontent-nuf-c3="" class="row tabmenu">
                                <ul _ngcontent-nuf-c3="" class="tab col3" id="inPlayTab">
                                    <li _ngcontent-nuf-c3="">
                                        <a _ngcontent-nuf-c3="" class="select" id="inPlay">In-Play</a></li>
                                    <li _ngcontent-nuf-c3="">
                                        <a _ngcontent-nuf-c3="" id="today">Today</a></li>
                                    <li _ngcontent-nuf-c3="">
                                        <a _ngcontent-nuf-c3="" id="tomorrow">Tomorrow</a></li>
                                </ul>
                            </div>
                            <a class="a-search" onClick={openSearchbar} href="#" >Search</a>
                        </div> */}
                            <div className='game-menu-tab inplay-match'>
                                <div className='row'>
                                    <div className='col-md-12 mobile-width'>
                                        {Eventday === 1 && <Inplay isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
                                        {Eventday === 2 && <Today isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
                                        {Eventday === 3 && <Tommorrow isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            }
            {/* {ResultTab === true && <Rusult changeState={props.changeState} />} */}
        </React.Fragment>
    )
}
