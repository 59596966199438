import React, { useEffect, useState } from "react";
import Transparent from "../images/sprite/transparent.gif";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from 'moment'
import Cookies from 'universal-cookie';
require('moment-timezone');
const cookies = new Cookies();

export default function Inplay(props) {
  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [Basketball, setBasketball] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);
  const [ScoreData, setScoreData] = useState([])






  useEffect(() => {

    axios
      .get(`https://liveapi247.live/api1/BasketballData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setBasketball(obj);
      })
      .catch((error) => console.log(error));
    axios
      .get(`https://liveapi247.live/api1/tennisData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setTennis(obj);
      })
      .catch((error) => console.log(error));

    axios
      .get(`https://liveapi247.live/api1/soccerData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setSoccer(obj);
      })

      .catch((error) => console.log(error));

    axios
      .get(`https://liveapi247.live/api1/cricketData`)

      .then((result) => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });

        setcricket(obj);
      })

      .catch((error) => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    axios
      .get(`https://liveapi247.live/api1/fancy`)

      .then((result) => {

        setFancy(result.data);
        setrefresh(false);
      })

      .catch((error) => console.log(error));


    axios.get(
      `https://liveapi247.live/api1/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));


  }, []);



  return (
    <React.Fragment>

      {refresh === true &&
        <div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
          <ul _ngcontent-qsy-c0="" class="loading">
            <li _ngcontent-qsy-c0="">
              <div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
                <div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
                  <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                  <div _ngcontent-qsy-c0=""></div>
                </div></div></li>
            <li _ngcontent-qsy-c0="">Loading...</li></ul></div>}




      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false &&
        <>

          {cricket.length > 0 &&
            <div _ngcontent-sgd-c3 className="inplay-sportwise">
              <div _ngcontent-sgd-c3 className="sporthead sporthead2">
                <h3 _ngcontent-sgd-c3>Cricket </h3>
              </div>
              <table _ngcontent-sgd-c3 className="table table-hover inplaytbl2">
                {/* <thead _ngcontent-sgd-c3>
                  <tr>
                    <th _ngcontent-sgd-c3 className="view-mobile" colSpan={2} />
                    <th _ngcontent-sgd-c3 className="text-center">1</th>
                    <th _ngcontent-sgd-c3 className="text-center">X</th>
                    <th _ngcontent-sgd-c3 className="text-center">2</th>
                  </tr>
                </thead> */}
                <tbody _ngcontent-sgd-c3>
                  <React.Fragment>
                    {cricket.map(function (item, id) {

                      let customScore = '';
                      let elapsTime = '';
                      let filteredData = ScoreData &&
                        ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                      // let filteredData = [];
                      if (filteredData && filteredData.length > 0) {
                        customScore = filteredData[0].score;
                        elapsTime = filteredData[0].timeElapsed
                      }
                      var showColor = false;
                      var showFancy = false;
                      var isFancy = false;

                      var showtv = false;
                      var isbookmaker = false;

                      if (fancy.includes(item.eventId)) {
                        isFancy = true;
                      }

                      if (tv.includes(item.eventId)) {
                        showtv = true;
                      };
                      if (bookmaker.includes(item.eventId)) {
                        isbookmaker = true;

                      };


                      return (
                        <React.Fragment key={id}>
                          <tr _ngcontent-sgd-c3="">
                            <td _ngcontent-sgd-c3="" style={{ cursor: 'pointer' }}>
                              <Link _ngcontent-sgd-c3="" to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} class="match-name">
                                <span _ngcontent-sgd-c3="">{item.eventName}</span>
                              </Link>
                              <span _ngcontent-sgd-c3="" class="in_play">In-Play</span>
                              <span _ngcontent-sgd-c3="" class="in_play">
                                <i _ngcontent-sgd-c3="" class="fa fa-television"></i>
                              </span>
                            </td>
                            <td _ngcontent-sgd-c3="">
                              {isFancy && (<span _ngcontent-sgd-c3="" class="game-fancy mr-1">F</span>)}
                              <span _ngcontent-sgd-c3="" class="game-sportbook">S</span>
                            </td>
                            {/* <td _ngcontent-sgd-c3="" class="count"><span _ngcontent-sgd-c3="" class="back">2.66</span><span _ngcontent-sgd-c3="" class="lay">2.8</span></td>
                              <td _ngcontent-sgd-c3="" class="count"><span _ngcontent-sgd-c3="" class="back">0.0</span><span _ngcontent-sgd-c3="" class="lay">0.0</span></td>
                              <td _ngcontent-sgd-c3="" class="count"><span _ngcontent-sgd-c3="" class="back">1.55</span><span _ngcontent-sgd-c3="" class="lay">1.6</span></td> */}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                </tbody>
              </table>
            </div>}
          {/* {cricket.length > 0 &&
          <div _ngcontent-njs-c67="" id="inPlayEventType_4" style={{ display: "block" }}>
          <h3>Cricket</h3>

          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_4">


            <React.Fragment>



            </React.Fragment>

            <React.Fragment>
              {cricket.map(function (item, id) {

                let customScore = '';
                let elapsTime = '';
                let filteredData = ScoreData &&
                  ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                // let filteredData = [];
                if (filteredData && filteredData.length > 0) {
                  customScore = filteredData[0].score;
                  elapsTime = filteredData[0].timeElapsed
                }
                var showColor = false;
                var showFancy = false;
                var isFancy = false;

                var showtv = false;
                var isbookmaker = false;

                if (fancy.includes(item.eventId)) {
                  isFancy = true;
                }

                if (tv.includes(item.eventId)) {
                  showtv = true;
                };
                if (bookmaker.includes(item.eventId)) {
                  isbookmaker = true;

                };


                return (
                  <React.Fragment key={id}>
                    {moment() >= moment(item.marketStartTime) && (
                      <li _ngcontent-njs-c67=""
                        id="inPlayEvent_30062394"
                        key={id}
                        className={`inplay-on`}
                      >
                        <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`}
                          id="info"
                          onClick={() => {
                            props.changeState(true);
                          }}
                        >
                          <dl _ngcontent-njs-c67="" >
                            <dt _ngcontent-njs-c67="" >
                              {showtv && <span
                                id="streamingIcon"
                                class="game-live"
                                style={{ display: "flex" }}
                              >
                                game-live
                              </span>}
                              {isFancy && (
                                <span _ngcontent-njs-c67=""
                                  id="fancyBetIcon"
                                  className={`game-fancy in-play
                                  `}
                                  style={{ display: "flex" }}
                                >
                                  <pre>in-play</pre>Fancy
                                </span>
                              )}

                              {isbookmaker &&
                                <span id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
                              <span id="inPlayStatus_30062394" class="time">
                                In-Play
                              </span>
                            </dt>
                            <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                          </dl>
                        </Link>
                        <a _ngcontent-njs-c67="" id={MKPIN + id} className={`pin-off`} onClick={() => Multimarketpin(id, item.eventId)}></a>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>


          </ul>
        </div>} */}


          {Soccer.length > 0 &&
            <div _ngcontent-sgd-c3 className="inplay-sportwise">
              <div _ngcontent-sgd-c3 className="sporthead sporthead0">
                <h3 _ngcontent-sgd-c3>Soccer </h3>
              </div>
              <table _ngcontent-sgd-c3 className="table table-hover inplaytbl0">
                {/* <thead _ngcontent-sgd-c3>
                  <tr>
                    <th _ngcontent-sgd-c3 className="view-mobile" colSpan={2} />
                    <th _ngcontent-sgd-c3 className="text-center">1</th>
                    <th _ngcontent-sgd-c3 className="text-center">X</th>
                    <th _ngcontent-sgd-c3 className="text-center">2</th>
                  </tr>
                </thead> */}
                <tbody _ngcontent-sgd-c3>
                  <React.Fragment>
                    {Soccer.map(function (item, id) {


                      let customScore = '';
                      let elapsTime = '';
                      let filteredData = ScoreData &&
                        ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                      // let filteredData = [];
                      if (filteredData && filteredData.length > 0) {
                        customScore = filteredData[0].score;
                        elapsTime = filteredData[0].timeElapsed
                      }

                      var showColor = false;
                      var showtv = false;
                      var isbookmaker = false;



                      if (tv.includes(item.eventId)) {
                        showtv = true;
                      }
                      if (bookmaker.includes(item.eventId)) {
                        isbookmaker = true;

                      }

                      return (
                        <React.Fragment key={id}>  <tr _ngcontent-sgd-c3>
                          <td _ngcontent-sgd-c3 style={{ cursor: 'pointer' }}>
                            <Link _ngcontent-sgd-c3 className="match-name" to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`}>
                              <span _ngcontent-sgd-c3>{item.eventName}</span></Link>
                            <span _ngcontent-sgd-c3 className="in_play">In-Play</span></td>
                          <td _ngcontent-sgd-c3></td>
                          {/* <td _ngcontent-sgd-c3 className="count"><span _ngcontent-sgd-c3 className="back">2.92</span><span _ngcontent-sgd-c3 className="lay">3.05</span></td>
                    <td _ngcontent-sgd-c3 className="count"><span _ngcontent-sgd-c3 className="back">3.95</span><span _ngcontent-sgd-c3 className="lay">4.2</span></td>
                  <td _ngcontent-sgd-c3 className="count"><span _ngcontent-sgd-c3 className="back">2.4</span><span _ngcontent-sgd-c3 className="lay">2.46</span></td> */}
                        </tr>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                </tbody>
              </table>
            </div>
            // <div _ngcontent-njs-c67="" id="inPlayEventType_1" style={{ display: "block" }}>
            //   <h3 _ngcontent-njs-c67="" >Soccer</h3>
            //   <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_1">






            //     <React.Fragment>
            //       {Soccer.map(function (item, id) {


            //         let customScore = '';
            //         let elapsTime = '';
            //         let filteredData = ScoreData &&
            //           ScoreData.filter(x => String(x.eventId) === String(item.eventId));
            //         // let filteredData = [];
            //         if (filteredData && filteredData.length > 0) {
            //           customScore = filteredData[0].score;
            //           elapsTime = filteredData[0].timeElapsed
            //         }

            //         var showColor = false;
            //         var showtv = false;
            //         var isbookmaker = false;



            //         if (tv.includes(item.eventId)) {
            //           showtv = true;
            //         }
            //         if (bookmaker.includes(item.eventId)) {
            //           isbookmaker = true;

            //         }

            //         return (
            //           <React.Fragment key={id}>
            //             {moment() >= moment(item.marketStartTime) && (
            //               <li _ngcontent-njs-c67=""
            //                 id="inPlayEvent_-10065371"
            //                 key={id}
            //                 className={`inplay-on`}
            //               >
            //                 <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`}
            //                   id="info"
            //                   onClick={() => {
            //                     props.changeState(true);
            //                   }}
            //                 >
            //                   <dl _ngcontent-njs-c67="" >
            //                     <dt _ngcontent-njs-c67="" >
            //                       {showtv && <span _ngcontent-njs-c67=""
            //                         id="streamingIcon"
            //                         class="game-live"
            //                         style={{ display: "flex" }}
            //                       >
            //                         game-live
            //                       </span>}

            //                       {isbookmaker &&
            //                         <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

            //                       <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
            //                       {moment() >= moment(item.marketStartTime) && (
            //                         <span id="inPlayStatus" className="time">
            //                           In-Play
            //                         </span>
            //                       )}

            //                     </dt>
            //                     <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
            //                   </dl>
            //                 </Link>
            //                 <a _ngcontent-njs-c67="" id={MKPINSC + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
            //               </li>
            //             )}
            //           </React.Fragment>
            //         );
            //       })}
            //     </React.Fragment>
            //   </ul>
            // </div>
          }


          {
            Tennis.length > 0 &&
            <div _ngcontent-sgd-c3 className="inplay-sportwise">
              <div _ngcontent-sgd-c3 className="sporthead sporthead1">
                <h3 _ngcontent-sgd-c3>Tennis </h3>
              </div>
              <table _ngcontent-sgd-c3 className="table table-hover inplaytbl1">
                {/* <thead _ngcontent-sgd-c3>
                  <tr>
                    <th _ngcontent-sgd-c3 className="view-mobile" colSpan={2} />
                    <th _ngcontent-sgd-c3 className="text-center">1</th>
                    <th _ngcontent-sgd-c3 className="text-center">X</th>
                    <th _ngcontent-sgd-c3 className="text-center">2</th>
                  </tr>
                </thead> */}
                <tbody _ngcontent-sgd-c3>
                  {Tennis.map(function (item, id) {


                    let customScore = '';
                    let elapsTime = '';
                    let filteredData = ScoreData &&
                      ScoreData.filter(x => String(x.eventId) === String(item.eventId));

                    if (filteredData && filteredData.length > 0) {
                      customScore = filteredData[0].score;
                      elapsTime = filteredData[0].timeElapsed
                    }

                    var showColor = false;

                    var showtv = false;
                    var isbookmaker = false;

                    if (tv.includes(item.eventId)) {
                      showtv = true;
                    }
                    if (bookmaker.includes(item.eventId)) {
                      isbookmaker = true;

                    }
                    return (
                      <React.Fragment key={id}>  <tr _ngcontent-sgd-c3>
                        <td _ngcontent-sgd-c3 style={{ cursor: 'pointer' }}>
                          <Link _ngcontent-sgd-c3 className="match-name" to={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`}>
                            <span _ngcontent-sgd-c3>{item.eventName}</span></Link>
                          <span _ngcontent-sgd-c3 className="in_play">In-Play</span>
                        </td>
                        <td _ngcontent-sgd-c3></td>
                        {/* <td _ngcontent-sgd-c3 className="count"><span _ngcontent-sgd-c3 className="back">1.42</span><span _ngcontent-sgd-c3 className="lay">1.43</span></td>
                    <td _ngcontent-sgd-c3 className="count"><span _ngcontent-sgd-c3 className="back">0.0</span><span _ngcontent-sgd-c3 className="lay">0.0</span></td>
                    <td _ngcontent-sgd-c3 className="count"><span _ngcontent-sgd-c3 className="back">3.35</span><span _ngcontent-sgd-c3 className="lay">3.4</span></td> */}
                      </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            // <div _ngcontent-njs-c67="" id="inPlayEventType_2" style={{ display: "block" }}>
            //     <h3 _ngcontent-njs-c67="" >Tennis</h3>
            //     <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_2">




            //       {Tennis.map(function (item, id) {


            //         let customScore = '';
            //         let elapsTime = '';
            //         let filteredData = ScoreData &&
            //           ScoreData.filter(x => String(x.eventId) === String(item.eventId));
            //         // let filteredData = [];
            //         if (filteredData && filteredData.length > 0) {
            //           customScore = filteredData[0].score;
            //           elapsTime = filteredData[0].timeElapsed
            //         }

            //         var showColor = false;

            //         var showtv = false;
            //         var isbookmaker = false;

            //         if (tv.includes(item.eventId)) {
            //           showtv = true;
            //         }
            //         if (bookmaker.includes(item.eventId)) {
            //           isbookmaker = true;

            //         }
            //         return (
            //           <React.Fragment key={id}>
            //             {moment() >= moment(item.marketStartTime) && (
            //               <li _ngcontent-njs-c67=""
            //                 id="inPlayEvent_-10065496"
            //                 key={id}
            //                 className={`inplay-on`}
            //               >
            //                 <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`}
            //                   id="info"
            //                   onClick={() => {
            //                     props.changeState(true);
            //                   }}
            //                 >
            //                   <dl _ngcontent-njs-c67="" >
            //                     <dt _ngcontent-njs-c67="" >
            //                       {showtv && <span id="streamingIcon" class="game-live">
            //                         game-live
            //                       </span>}

            //                       {isbookmaker &&
            //                         <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

            //                       <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
            //                       {moment() >= moment(item.marketStartTime) && (
            //                         <span _ngcontent-njs-c67="" id="inPlayStatus" className="time">
            //                           In-Play
            //                         </span>
            //                       )}


            //                     </dt>
            //                     <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
            //                   </dl>
            //                 </Link>
            //                 <a _ngcontent-njs-c67="" id={MKPINTN + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
            //               </li>
            //             )}
            //           </React.Fragment>
            //         );
            //       })}

            //     </ul>
            //   </div>
          }


        </>}


      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && Basketball.length === 0) && refresh === false &&
        <div _ngcontent-njs-c67="" id="noMultiMarkets" className="no-data">
          <h3 _ngcontent-njs-c67="" >There are currently no matches in Play.</h3>
          <p _ngcontent-njs-c67="" >Please check upcoming matches.</p>
        </div>
      }
    </React.Fragment>
  );
}
