import React, { memo } from 'react';

const RunnerHeader = ({ totalStake, maxStake, coinFactor, onHideMaxStack }) => {
    return (
        <>
            {/* {maxStake &&
                <div _ngcontent-njs-c67="" id="minMaxBox" class="limit_info-popup" style={{ display: ' flex' }}>
                    <a _ngcontent-njs-c67="" class="close" onClick={onHideMaxStack} >Close</a>
                    <dl _ngcontent-njs-c67="" >

                        <dt _ngcontent-njs-c67="" id="maxDt" >Max</dt>
                        <dd _ngcontent-njs-c67="" id="minMaxInfo">{parseInt(maxStake) * coinFactor}</dd>
                    </dl>
                </div>}
            <a id="minMaxButton" class="bet-limit"></a>
                <dl _ngcontent-njs-c67="" id="betsHead" className="bets-selections-head">
                    <dt _ngcontent-njs-c67="" >

                        <a _ngcontent-njs-c67="" className="a-depth" id="marketDepthBtn">Markets Depth</a>
                        <p _ngcontent-njs-c67="" ><span>Matched</span>
                            <strong id="totalMatched">USD  {totalStake}</strong>
                        </p>
                    </dt>
                    <dd _ngcontent-njs-c67="" className="mode-land" />
                    <dd _ngcontent-njs-c67="" className="mode-land" />
                    <dd _ngcontent-njs-c67="" >Back </dd>
                    <dd _ngcontent-njs-c67="" >Lay </dd>
                    <dd _ngcontent-njs-c67="" className="mode-land" />
                    <dd _ngcontent-njs-c67="" className="mode-land" />
            </dl> */}
            <thead _ngcontent-bhe-c3="">
                <th _ngcontent-bhe-c3="" class="show-xs min-max-mobile">
                    <dl _ngcontent-bhe-c3="" class="fancy-info" id="minMaxBox">
                        <dt _ngcontent-bhe-c3="">Min/Max</dt>
                        <dd _ngcontent-bhe-c3="" id="minMaxInfo">{parseInt(maxStake) * coinFactor} </dd></dl>
                </th>
                <th _ngcontent-bhe-c3="" class="hidden-xs" colspan="3"></th>
                <th _ngcontent-bhe-c3="" class="back-1">Back</th>
                <th _ngcontent-bhe-c3="" class="lay-1">Lay</th>
                <th _ngcontent-bhe-c3="" class="min-max-bet" colspan="2">
                    <dl _ngcontent-bhe-c3="" class="fancy-info" id="minMaxBox">
                        <dt _ngcontent-bhe-c3="">Min/Max</dt>
                        <dd _ngcontent-bhe-c3="" id="minMaxInfo">{parseInt(maxStake) * coinFactor} </dd></dl>
                </th>
            </thead>
        </>
    );
};

export default memo(RunnerHeader);