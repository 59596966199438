import React, { useEffect, useState, memo } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios';
import Cookies from 'universal-cookie';
import LeftSide from './LeftSide';
import { ToastContainer, toast } from 'react-toastify';

import Rightside from './Rightside'
import { useLocation } from "react-router-dom";
import transparent from '../images/sprite/transparent.gif'
// react query
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryClient } from '../serives/react-query/provider';
import { refreshBalanceApiRq, getGameBalanceApiRq, enterCasinoTransactionApiRq } from '../serives/react-query/api-actions';


const cookies = new Cookies();
toast.configure()
const Header = (props) => {

	const [openMainbalance, setopenMainbalance] = useState(false);
	const [refresh, setrefresh] = useState(false);
	const [isTransferFiald, setIsTransferFiald] = useState({ msg: '', isFaild: false });

	const apiURLHandler = 'https://liveapi247.live/api1/';

	const location = useLocation();

	var GeCredit = props.GEbalance;
	var awc_credit = props.AEBalance;
	var kg_credit = props.REBalance;
	var ezugi_credit = props.EZBalance;
	let jili_credit = props.JLBalance

	const getBalanceApiCall = async (username, providercode) => {
		try {
			let response = await axios({
				method: 'POST',
				url: apiURLHandler + 'getBalance',
				data: {
					username,
					password: 'aaaa1111',
					providercode
				}
			});
			if (response.data.status == true) {
				let value = response.data.data.balance;
				return value;
			}
			return false;
		} catch (error) {
			return false;
		}
	}
	const openMainBal = async (val) => {
		if (val == true) {
			handleLoader(true)
			const utoken = cookies.get('token');
			const user = cookies.get('id');

			// fetch specific balances
			let geBal = '0';
			let s2Bal = '0';
			let reBal = '0';
			let ezBal = '0';
			let jlBal = '0';
			let getGEBal = await getBalanceApiCall(user, 'GE');

			if (getGEBal !== false) {
				geBal = getGEBal;
			}
			if (String(geBal) !== String(props.GEbalance)) {
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, geBal, 'ge_credit', utoken)
			}


			let getS2Bal = await getBalanceApiCall(user, 'S2');

			if (getS2Bal !== false) {
				s2Bal = getS2Bal;
			}

			if (String(s2Bal) !== String(props.AEBalance)) {
				// debugger
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, s2Bal, 'awc_credit', utoken)
			}

			let getREBal = await getBalanceApiCall(user, 'RE');

			if (getREBal !== false) {
				reBal = getREBal;
			}
			if (String(reBal) !== String(props.REBalance)) {
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, reBal, 'kingmaker_credit', utoken)
			}

			let getJLBal = await getBalanceApiCall(user, 'JL');

			if (getJLBal !== false) {
				jlBal = getJLBal;
			}
			if (String(jlBal) !== String(props.JLBalance)) {
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, jlBal, 'jili_credit', utoken)
			}

			// let getEZBal =await getBalanceApiCall(user, 'EZ');

			// if (getEZBal !== false) {
			// 	ezBal = getEZBal;
			// }
			// if(String(ezBal) !== String(props.EZBalance)) {
			// 	// update Balance trigger
			// 	doUpdateBalance(user, props.mainBalance, ezBal, 'ezugi_credit', utoken)
			// }
		}
		props.setupdated(!props.updated);
		setopenMainbalance(val)
		handleLoader(false)
		// handleRefresh();
	}

	const handleLoader = (val) => {
		setrefresh(val);
	}
	/* Update Balance api from vendor side start */


	const doUpdateBalance = async (username, mainBalance, amountToBeUpdate, casinoType, token) => {
		try {
			let response = await axios({
				method: 'POST',
				url: apiURLHandler + 'update_balance',
				data: {
					id: username,
					LimitCurr: mainBalance,
					casino_value: amountToBeUpdate,
					casino_type: casinoType,
					token
				}
			});
			if (response.data) {
				// console.log('Balance updated successfully!');
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	}
	/* Update Balance api from vendor side end */

	/* Get Balance status from vendor side api start */
	const getBalance = async (username, password, providercode, mainBalance, token, refreshBalanceGameAmount) => {
		try {
			// debugger
			let response = await axios({
				method: 'POST',
				url: apiURLHandler + 'getBalance',
				data: {
					username,
					password,
					providercode
				}
			});
			if (response.data.status == true) {
				let value = response.data.data.balance;
				if (providercode === "GE") {
					let casinoTypeCode = 'ge_credit';
					//   localStorage.setItem('ge_credit', value);
					props.setGEbalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '')
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "S2") {
					let casinoTypeCode = 'awc_credit';
					//   localStorage.setItem('awc_credit', value);
					props.setAEBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "RE") {
					let casinoTypeCode = 'kingmaker_credit';
					// localStorage.setItem('kingmaker_credit', value);
					props.setREBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "JL") {
					let casinoTypeCode = 'jili_credit';
					// localStorage.setItem('kingmaker_credit', value);
					props.setJLBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "EZ") {
					let casinoTypeCode = 'ezugi_credit';
					// localStorage.setItem('ezugi_credit', value);
					props.setEZBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}
			}
			return true;
		} catch (error) {
			return false;
		}
	}

	// refresh balance rq
	const getBlanceStatus = queryClient.getQueryData({ queryKey: ['refreshBalanceStatus'] });
	const { data, status } = getBlanceStatus ? getBlanceStatus : {};
	const pendingBalance = data?.pendingBalance;

	const utoken = cookies.get('token');
	const user = cookies.get('id');

	const refreshBalanceApiAction = useMutation({
		mutationFn: refreshBalanceApiRq,
		onSuccess: rsp => {
			queryClient.setQueryData(['refreshBalanceStatus'], rsp);
		}
	});

	const handleRefresh = () => {
		refreshBalanceApiAction.mutate({ id: user, token: utoken });
	}
	useEffect(() => {
		if (props.isLoggedIn === true) {
			props.setloading(true);
			setTimeout(() => { props.setloading(false); }, 1000);
			handleRefresh();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, props.isLoggedIn]);



	// if blance is from the provider then get blance from provider
	const enterCasinoTransactionApiAction = useMutation({
		mutationFn: enterCasinoTransactionApiRq,
		onSuccess: rsp => {
			handleRefresh();
			if (rsp.data.status === false) {
				setIsTransferFiald({ msg: rsp.data.message, isFaild: true });
			}
		}
	});

	const getGameBalanceApiAction = useMutation({
		mutationFn: getGameBalanceApiRq,
		onSuccess: ({ data, status }) => {
			const gameBalance = data.data.balance;
			const { providercode, member } = pendingBalance;

			if (data && status == 200 && gameBalance > 0) {
				// transfer ammount to ac
				enterCasinoTransactionApiAction.mutate({
					username: member,
					transferType: 'w',
					providercode,
					amount: gameBalance,
					token: utoken,
				})
			}

		}
	});


	useEffect(() => {
		// const {data, status} = getBlanceStatus;
		if (status === 210 && pendingBalance && pendingBalance?.type == 0) {

			const { providercode, member } = pendingBalance;
			if (isTransferFiald.isFaild === false) {
				getGameBalanceApiAction.mutate({
					providercode,
					username: member
				})
			}

		}

		// set blance to state
		if (status === 210) {
			props.setLoggedIn(true);
			const mainBalance = data.mainBalance - Math.abs(data.exposureBalance);

			if (mainBalance) {
				props.changeBalance(mainBalance);
			}
			if (data.exposureBalance) {
				props.changeExposure(Math.abs(data.exposureBalance));
			}
		} else {
			props.setLoggedIn(false);
		}


	}, [getBlanceStatus]);


	// loading state
	useEffect(() => {
		if (getGameBalanceApiAction.isLoading || enterCasinoTransactionApiAction.isLoading || refreshBalanceApiAction.isLoading) {
			props.setloading(true);
		} else {
			props.setloading(false);
		}
	}, [refreshBalanceApiAction, enterCasinoTransactionApiAction, getGameBalanceApiAction])

	return (
		<React.Fragment >
			{props.openLeftside === true &&
				<LeftSide userid={props.userid} utoken={props.utoken} handleleftsideclose={props.handleleftsideclose} />
			}
			{props.openRightside === true &&
				<Rightside handlerightsideclose={props.handlerightsideclose} userid={props.userid} utoken={props.utoken} />
			}
			{props.refreshhh === true &&

				<div _ngcontent-qsy-c0="" className="loading-wrap" id="loading">
					<ul _ngcontent-qsy-c0="" className="loading">
						<li _ngcontent-qsy-c0="">
							<div _ngcontent-qsy-c0="" className="loadingio-spinner-dual-ball-srpf31lqtit">
								<div _ngcontent-qsy-c0="" className="ldio-dayih5pen3j">
									<div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
									<div _ngcontent-qsy-c0=""></div>
								</div></div></li>
						<li _ngcontent-qsy-c0="">{props.refreshMsg}</li></ul></div>
			}

			{
				refresh == true ?
					<div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
						<ul _ngcontent-qsy-c0="" class="loading">
							<li _ngcontent-qsy-c0="">
								<div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
									<div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
										<div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
										<div _ngcontent-qsy-c0=""></div>
									</div></div></li>
							<li _ngcontent-qsy-c0="">Loading...</li></ul></div>
					:
					null
			}


			{/* {props.isLoggedIn === false &&
					<h1 class="mob-header-logo"></h1>}

				{props.isLoggedIn === false &&
					<a _ngcontent-njs-c67="" class="mob-login-index ui-link" onClick={() => { props.checkShowLogin(true); }}>Login</a>
				}
 */}




			{props.msgNumber === 1 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content success to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Bet Matched</h4>
					<p id="info">
						<span _ngcontent-njs-c67="" id="sideType" class="lay">{props.betInfo.oddstype}</span>
						<strong id="selectionName">{props.betInfo.runner}</strong> <strong id="stake">{props.betInfo.amount}</strong> at odds <strong id="odds">{props.betInfo.rate}</strong>
					</p>
				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}></a>
			</div>}

			{props.msgNumber === 2 &&

				<div _ngcontent-njs-c67="" id="msgBox" class="message-content warning to-open_bets" >
					<div _ngcontent-njs-c67="" class="message">
						<h4 _ngcontent-njs-c67="" id="header">The stake you have entered are below the minimum.</h4>

					</div>
					<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}></a>
				</div>}



			{props.msgNumber === 3 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Bet Blocked</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}

			{props.msgNumber === 4 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Bet Closed</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}

			{props.msgNumber === 5 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Insufficient Coin</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}

			{props.msgNumber === 6 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Odds Changed</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}

			{props.msgNumber === 7 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Bet can not be placed!</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}
			{props.msgNumber === 8 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Enter valid coin value!</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}

			{props.msgNumber === 9 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Bet Over 75 not accepted!</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}

			{props.msgNumber === 10 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Duplicate Bet Not allowed!</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}
			{props.msgNumber === 11 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Min bet is {props.stakeInfo.minStake}</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}

			{props.msgNumber === 12 && <div _ngcontent-njs-c67="" id="msgBox" class="message-content error to-open_bets">
				<div _ngcontent-njs-c67="" class="message">
					<h4 _ngcontent-njs-c67="" id="header">Max bet is {props.stakeInfo.maxStake}</h4>

				</div>
				<a _ngcontent-njs-c67="" id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
			</div>}


			<div _ngcontent-dde-c0="" className="app-header navbar">
				{/**/}<button appsidebartoggler className="navbar-toggler d-lg-none" type="button">
					<span className="navbar-toggler-icon" /></button>
				<a className="navbar-brand" href="#/">
					{/**/}
					{/**/}
					<div className="navbar-brand-full">🅲 CoreUI</div>
					<div className="navbar-brand-minimized">🅲</div>
				</a> {/**/}
				<button className="navbar-toggler d-md-down-none" type="button">
					<span className="navbar-toggler-icon" /></button>
				<div _ngcontent-dde-c0="" className="welcome-text ">
					{/* mobile_header */}
					{props.isLoggedIn === false && <a _ngcontent-dde-c0="" className="logo-home" href="#/home">
						{/**/}<img _ngcontent-dde-c0="" className="logo-main" id="logoMain" src="https://s3.ap-southeast-1.amazonaws.com/cdnuniverse.site/kingexchange.png" /></a>}
				</div>

				{/* {props.msgNumber === 1 &&
					<div _ngcontent-ajq-c4="" className="toastr toastr-error ng-star-inserted" style={{ opacity: 1, transform: "translateX(0px)" }}
					>
						
						<div _ngcontent-ajq-c4="" className="ng-tns-c4-6">
							
							<span
								_ngcontent-ajq-c4=""
								className="to-message toastr-message ng-star-inserted"
								style={{}}>  You have Insufficient Balance.</span>
						</div>
					</div>} */}


				<div _ngcontent-dde-c0="" className="welcome-text mobile-after-login ">
					<a _ngcontent-dde-c0="" className="logo-home" href="#/home">
						{/**/}<img _ngcontent-dde-c0="" className="logo-main" id="logoMain" src="https://s3.ap-southeast-1.amazonaws.com/cdnuniverse.site/kingexchange.png" />
					</a>
				</div>




				<div _ngcontent-dde-c0="" className="search-top">
					<div _ngcontent-dde-c0="" className="input-group">
						<div _ngcontent-dde-c0="" className="w-100 ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid" name="SearchEvent" placeholder="Search Events" role="listbox">
							<div className="ng-select-container ng-has-value">
								<div className="ng-value-container">
									<div className="ng-placeholder">Search Events</div>
									{/**/} {/**/} {/**/}
									<div className="ng-value">
										{/**/} {/**/}
										<span aria-hidden="true" className="ng-value-icon left">×</span><span className="ng-value-label" /></div>
									{/**/} {/**/}
									<div className="ng-input">
										<input role="combobox" type="text" autoComplete="a0343f6b73b4" autoCorrect="off" autoCapitalize="off" aria-expanded="false" /></div>
								</div>
								{/**/} {/**/}
								<span className="ng-clear-wrapper" title="Clear all">
									<span aria-hidden="true" className="ng-clear">×</span></span>
								<span className="ng-arrow-wrapper">
									<span className="ng-arrow" /></span>
							</div>
							{/**/}
						</div>
					</div>
					<div _ngcontent-rop-c0="" class="pull-left bets-mbl-show show-xs">
						<a _ngcontent-rop-c0="" class="open_bets" href="javascript:;" title="Setting">
							<img _ngcontent-rop-c0="" src={Transparent} /> &nbsp;Bets</a>
						<div _ngcontent-rop-c0="" class="mobileTrans" id="google_translate_element" style={{ position: 'absolute', left: '70px', top: '0' }}></div></div>
				</div>



				{/**/}
				{props.isLoggedIn === true && <div _ngcontent-pkb-c0 className="pull-left bets-mbl-show show-xs">
					<a _ngcontent-pkb-c0 className="open_bets" onClick={props.handleleftside} title="Setting">
						<img _ngcontent-pkb-c0 src={Transparent} /> &nbsp;Bets</a>{/**/}
					<div _ngcontent-pkb-c0 className="mobileTrans" id="google_translate_element" style={{ position: 'absolute', left: '70px', top: 0 }} /></div>}
				{props.isLoggedIn === true &&
					<ul _ngcontent-pkb-c0 className="nav navbar-nav ml-auto p-rltv">
						{props.loading === false && <div _ngcontent-pkb-c0 className="user-blnc">
							<span _ngcontent-pkb-c0> Main
								<strong _ngcontent-pkb-c0> USD {props.balance.toFixed(2)}</strong></span>
							<span _ngcontent-pkb-c0 className="exp-topcount">Exposure
								<a _ngcontent-pkb-c0 className="text-white">
									<strong _ngcontent-pkb-c0>(
										<span _ngcontent-pkb-c0 className="exp-red">{props.exposure > 0 ? '(' + parseFloat(props.exposure).toFixed(2) + ')' : props.exposure.toFixed(2)}</span>)
									</strong>
								</a>
							</span>
						</div>}
						{props.loading === true && <li _ngcontent-ddt-c0="" id="menuRefreshIcon">
							<img _ngcontent-ddt-c0="" class="refresh-bar" src={transparent} /></li>}

						<div _ngcontent-pkb-c0 className>
							<span _ngcontent-pkb-c0 className="head-refresh">
								<a _ngcontent-pkb-c0 id="menuRefresh" style={{ cursor: 'pointer' }} onClick={() => { handleRefresh(); }}>Refresh</a></span>
							<a _ngcontent-pkb-c0 className="a-referesh" onClick={() => { handleRefresh(); }} href="javascript:;" title="Setting">
								<img _ngcontent-pkb-c0 src={Transparent} /></a></div>{/**/}
						<div
							_ngcontent-pkb-c0 className="bet-setting ml-2 mr-2">
							<a _ngcontent-pkb-c0 className="a-setting" onClick={() => { props.handlerightside() }} title="Setting">
								<img _ngcontent-pkb-c0 src={Transparent} /></a></div>
						<span _ngcontent-pkb-c0 className="err-invalid" style={{ display: 'block' }} /></ul>}
				{props.isLoggedIn === false &&
					<ul _ngcontent-dde-c0="" className="nav navbar-nav ml-auto p-rltv">
						{/**/}
						<form _ngcontent-dde-c0="" className="top-form hidden-xs headerfrm ng-dirty ng-touched ng-valid" noValidate>
							<div _ngcontent-dde-c0="" className="form-group mb-0 ">
								<input _ngcontent-dde-c0="" className="form-control uname ng-dirty ng-valid ng-touched" formcontrolname="username" placeholder="Username" /></div>
							<div _ngcontent-dde-c0="" className="form-group mb-0 hidden-xs">
								<input _ngcontent-dde-c0="" className="form-control pass ng-dirty ng-valid ng-touched" formcontrolname="password" placeholder="Password" type="password" /></div>
							{/**/}<button _ngcontent-dde-c0="" className="btn  btn-orange pull-right mr-2" onClick={() => { props.checkShowLogin(true); }} >  Login <i _ngcontent-dde-c0="" className="fa fa-sign-in" /></button></form>
						{/**/}
						{/* mobile_header */}
						{props.isLoggedIn === false && <button _ngcontent-dde-c0="" className="btn visible-mob btn-orange pull-right mr-2" onClick={() => { props.checkShowLogin(true); }}> Login <i _ngcontent-dde-c0="" className="fa fa-sign-in" /></button>}
						<span _ngcontent-dde-c0="" className="err-invalid" style={{ display: 'block' }} /></ul>}
				{/**/} {/**/}
				{props.isLoggedIn === true && <li _ngcontent-pkb-c0="" class="account-wrap-new dropdown hidden-xs" dropdown="" placement="bottom right">
					<a _ngcontent-pkb-c0="" aria-haspopup="true" class="nav-link myaccnt" data-toggle="dropdown" dropdowntoggle="" id="accountPopup" role="button" style={{ cursor: 'pointer' }}>My Account</a></li>}
				<button className="navbar-toggler d-md-down-none" type="button">
					<span className="navbar-toggler-icon" />
				</button> {/**/}
				<button appasidemenutoggler className="navbar-toggler d-lg-none" type="button">
					<span className="navbar-toggler-icon" />
				</button></div>


		</React.Fragment>
	)
}

export default memo(Header);
