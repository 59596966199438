import React, { memo } from 'react';
import { marketKeys } from '../../utils';

const PlaceBetKeybord = ({ renderBottom, odds, oddInputDisable, onClickOddMinus, onClickOddPlus, onClickStackMinus, onClickStackPlus, defaultStake, layBack, onCancel, onPlace, minStack, coinFactor, onInputChnageStack, ssStack, style }) => {
    return (
        // <div style={{ ...style }} className={`bet_slipbar-content ${layBack}`}>
        //     <ul className="btn-list">
        //         <li>
        //             <p class="dynamic-min-bet">&nbsp;</p>
        //             <div id="inputOdds" class={`input-num ${oddInputDisable ? "disable" : ''}`}>
        //                 <a id="oddsDown" class="icon-minus" onClick={onClickOddMinus} style={{ ...(oddInputDisable ? { display: ' none' } : {}) }}></a>
        //                 <span id="odds" class="typed">{odds}</span>
        //                 <a id="oddsUp" class="icon-plus" onClick={onClickOddPlus} style={{ ...(oddInputDisable ? { display: ' none' } : {}) }}></a>
        //             </div>
        //         </li>
        //         <li>
        //             <p class="dynamic-min-bet" style={{ textAlign: 'center' }} >Min Bet: {parseInt(minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
        //             <div id="inputStake" onChange={(e)=> onInputChnageStack(e.target.value)} type="text" value={defaultStake} class="input-num">
        //                 <a id="stakeDown" class="icon-minus" onClick={onClickStackMinus}></a>
        //                 <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
        //                 <a id="stakeUp" class="icon-plus" onClick={onClickStackPlus}></a>
        //             </div>
        //         </li>
        //     </ul>
        //     <ul id="stakePopupList" class="coin-list">
        //         <li><a id="selectStake_1" onClick={() => onInputChnageStack(ssStack.ssstake1)} stake="10"> {ssStack.ssstake1}</a></li>
        //         <li><a id="selectStake_2" onClick={() => onInputChnageStack(ssStack.ssstake2)} stake="30"> {ssStack.ssstake2}</a></li>
        //         <li><a id="selectStake_3" onClick={() => onInputChnageStack(ssStack.ssstake3)} stake="50"> {ssStack.ssstake3}</a></li>
        //         <li><a id="selectStake_4" onClick={() => onInputChnageStack(ssStack.ssstake4)} stake="200">{ssStack.ssstake4}</a></li>
        //         <li><a id="selectStake_5" onClick={() => onInputChnageStack(ssStack.ssstake5)} stake="500">{ssStack.ssstake5}</a></li>
        //         <li><a id="selectStake_6" onClick={() => onInputChnageStack(ssStack.ssstake6)} stake="1000">{ssStack.ssstake6}</a></li>
        //     </ul>

        //     <div id="keyboard" class="keyboard-wrap">
        //         <ul id="numPad" class="btn-tel">
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '1') : null} >1</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '2') : null} >2</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '3') : null} >3</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '4') : null} >4</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '5') : null} >5</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '6') : null} >6</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '7') : null} >7</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '8') : null} >8</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '9') : null} >9</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '0') : null} >0</a></li>
        //             <li><a onClick={() => (defaultStake.length < 8) ? onInputChnageStack(defaultStake + '00') : null} >00</a></li>
        //             <li><a  >.</a></li>
        //         </ul>
        //         <a id="delete" class="btn-delete" onClick={() => { onInputChnageStack(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
        //     </div>

        //     <ul className="btn-list">
        //         <li>
        //             <a id="cancel" onClick={onCancel} className="btn">Cancel
        //             </a>
        //         </li>
        //         <li>
        //             <a id="placeBet" onClick={onPlace} className="btn-send">Place Bet
        //             </a>
        //         </li>
        //     </ul>
        //     {renderBottom}
        // </div>
        <tr _ngcontent-ghr-c3="" style={{ ...style, display: 'table-row' }} className={`fancy-quick-tr  ${layBack}`} >
            <td _ngcontent-ghr-c3="" colspan="7">
                <dl _ngcontent-ghr-c3="" class="quick_bet-wrap" id="classWrap">
                    <dt _ngcontent-ghr-c3="" class="" id="fancyBetHeader" style={{ width: '35%' }}>
                        <span _ngcontent-ghr-c3="" class="bet-check" id="fancyBetAcceptCheck"></span>
                    </dt>
                    <dd _ngcontent-ghr-c3="" class="col-btn">
                        <a _ngcontent-ghr-c3="" class="btn" id="cancel" style={{ cursor: 'pointer' }}>Cancel</a>
                    </dd>
                    <dd _ngcontent-ghr-c3="" class="col-stake betfairodds">
                        <a _ngcontent-ghr-c3="" class="icon-minus" onClick={onClickOddMinus} style={{ ...(oddInputDisable ? { display: ' none' } : {}) }} id="stakeDown"></a>
                        <input _ngcontent-ghr-c3="" value={odds} class="ng-untouched ng-pristine ng-valid" />
                        <a _ngcontent-ghr-c3="" class="icon-plus" id="stakeUp" onClick={onClickOddPlus} style={{ ...(oddInputDisable ? { display: ' none' } : {}) }}></a>
                    </dd>
                    <dd _ngcontent-ghr-c3="" class="col-stake betfair-stack">
                        <a _ngcontent-ghr-c3="" class="icon-minus show-xs" onClick={onClickStackMinus} id="stakeDown"></a>
                        <input _ngcontent-ghr-c3="" onChange={(e) => onInputChnageStack(e.target.value)} class="oddinput ng-untouched ng-pristine ng-valid" value={defaultStake} />
                        <a _ngcontent-ghr-c3="" class="icon-plus show-xs" id="stakeUp" onClick={onClickStackPlus}></a>
                    </dd>
                    <dd _ngcontent-ghr-c3="" class="col-send">
                        <button _ngcontent-ghr-c3="" class="btn-send disable" style={{ cursor: 'pointer' }}>Place Bets</button></dd>
                    <dd _ngcontent-ghr-c3="" class="col-stake_list" id="stakePopupList" style={{ display: 'block' }}>
                        <ul _ngcontent-ghr-c3="">
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake1)} style={{ cursor: 'pointer' }}>{ssStack.ssstake1}</a></li>
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake2)} style={{ cursor: 'pointer' }}> {ssStack.ssstake2}</a></li>
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake3)} style={{ cursor: 'pointer' }}>{ssStack.ssstake3}</a></li>
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake4)} style={{ cursor: 'pointer' }}>{ssStack.ssstake4}</a></li>
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake5)} style={{ cursor: 'pointer' }}>{ssStack.ssstake5}</a></li>
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake6)} style={{ cursor: 'pointer' }}>{ssStack.ssstake6}</a></li>
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake7)} style={{ cursor: 'pointer' }}>{ssStack.ssstake7}</a></li>
                            <li _ngcontent-ghr-c3="">
                                <a _ngcontent-ghr-c3="" class="btn" onClick={() => onInputChnageStack(ssStack.ssstake8)} style={{ cursor: 'pointer' }}>{ssStack.ssstake8}</a></li>
                        </ul></dd><div _ngcontent-ghr-c3="" class="show-xs betmobile-btn">
                        <dd _ngcontent-ghr-c3="" class="col-btn">
                            <a _ngcontent-ghr-c3="" class="btn" id="cancel" style={{ cursor: 'pointer' }} onClick={onCancel}>Cancel</a></dd>
                        <dd _ngcontent-ghr-c3="" class="col-send">
                            <button _ngcontent-ghr-c3="" class="btn-send disable" style={{ cursor: 'pointer' }} onClick={onPlace}>Place Bets</button>
                        </dd></div></dl></td>
        </tr>
    );
};

export default memo(PlaceBetKeybord);
