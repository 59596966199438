import React, { useState, useEffect, memo } from 'react';

import { usePrevious } from "react-use";
import { marketKeys } from '../../utils';

const ValueBlinker = memo(({ rate, size, className }) => {
    const [valDetect, setValDetect] = useState(false);
    const prvStateValue = usePrevious(rate);

    useEffect(() => {
        if (rate !== prvStateValue) {
            setValDetect(true)
            setTimeout(() => setValDetect(false), 350)
        }
    }, [rate]);

    return (
        <a _ngcontent-eix-c3="" className={`${className} ${valDetect ? "spark" : ""}`}>{rate}<span _ngcontent-eix-c3=""> {size}</span></a>
    )
})

const RunnerRow = ({ className, style = {}, veriant, blockStatus, hideStatus, runnerName, firstPl, secondPl, runnerBackRate = '', runnerBackSize = '', runnerLayRate = '', runnerLaySize = '', onClick, backSelected, laySelected }) => {

    // const blockStyle = veriant != marketKeys.bookmaker ? {
    //     ...(hideStatus ? { display: 'flex', zIndex: 22  } : { display: 'none' })
    // } : {}

    return (
        <>

            {veriant == marketKeys.bookmaker ? (
                <>
                    <tr _ngcontent-eix-c3="" className="odd-row 1022023101174945791 414464">
                        <td _ngcontent-eix-c3="" className="team-name team-width">

                            <span _ngcontent-eix-c3="" className="title">{runnerName}</span>
                            <span _ngcontent-eix-c3="" style={{ display: 'flex', lineHeight: '1' }}>
                                <i _ngcontent-eix-c3="" className="fa fa-arrow-right" />  {firstPl}  {secondPl}</span></td>
                        <td _ngcontent-eix-c3="" colSpan={3} style={{
                            padding:
                                0, position: 'relative'
                        }}>
                            {blockStatus}

                            <dl _ngcontent-eix-c3="" className="back-gradient">
                                <dd _ngcontent-eix-c3="" style={{ cursor: 'pointer' }} className="count 414464 back_3">
                                    <a _ngcontent-eix-c3=""> 1.26 <span _ngcontent-eix-c3="">30M</span></a></dd>
                                <dd _ngcontent-eix-c3="" style={{ cursor: 'pointer' }} className="count 414464 back_2">
                                    <a _ngcontent-eix-c3=""> 1.27 <span _ngcontent-eix-c3="">20M</span></a></dd>
                                <dd _ngcontent-eix-c3="" className="count 414464 back_1 bookcount" onClick={() => (runnerBackRate != ' ' && runnerBackRate > 0) ? onClick('back', runnerBackRate) : null} style={{ cursor: 'pointer' }}>
                                    <ValueBlinker
                                        rate={runnerBackRate}
                                        size={runnerBackSize}
                                        className={`back-1 ${backSelected ? "select" : "null"}`} /></dd>
                            </dl>
                        </td>
                        <td _ngcontent-eix-c3="" colSpan={3} style={{ padding: 0 }}>
                            <dl _ngcontent-eix-c3="" className="lay-gradient">
                                <dd _ngcontent-eix-c3="" onClick={() => (runnerLayRate != ' ' && runnerLayRate > 0) ? onClick('lay', runnerLayRate) : null} style={{ cursor: 'pointer' }} className="count 414464 lay_1 bookcount">
                                    <ValueBlinker
                                        rate={runnerLayRate}
                                        size={runnerLaySize}
                                        className={`lay-1 ${laySelected ? "select" : "null"}`}
                                    /></dd>
                                <dd _ngcontent-eix-c3="" style={{ cursor: 'pointer' }} className="count 414464 lay_2">
                                    <a _ngcontent-eix-c3=""> 1.31 <span _ngcontent-eix-c3="">20M</span></a></dd>
                                <dd _ngcontent-eix-c3="" className="count 414464 lay_3" id="back_1" style={{ cursor: 'pointer' }}>
                                    <a _ngcontent-eix-c3=""> 1.32 <span _ngcontent-eix-c3="">30M</span></a></dd>
                            </dl>
                        </td>
                    </tr>
                </>
            ) : (
                <>
                    <tr _ngcontent-kgr-c3="" className={`odd-row bets-selections ${className}`} style={{ position: 'relative' }} >
                        <td _ngcontent-kgr-c3="" class="team-name">
                            <span _ngcontent-kgr-c3="" class="title"> {runnerName} </span>
                            <span _ngcontent-kgr-c3="" class="minus-book" style={{ display: 'flex', lineHeight: '1' }} >
                                <i _ngcontent-kgr-c3="" class="fa fa-arrow-right"></i>  {firstPl}
                                {secondPl}</span></td>
                        <td _ngcontent-kgr-c3="" class="back-3 count 7461">
                            <a _ngcontent-kgr-c3="">1.06
                                <span _ngcontent-kgr-c3="">258.4K</span></a></td>
                        <td _ngcontent-kgr-c3="" class="back-2 count 7461">
                            <a _ngcontent-kgr-c3="">1.07 <span _ngcontent-kgr-c3="">281.7K</span></a></td>
                        <td _ngcontent-kgr-c3="" class="back-1 count 7461 spark">
                            <a _ngcontent-kgr-c3="" onClick={() => (runnerBackRate != ' ' && runnerBackRate > 0) ? onClick('back', runnerBackRate) : null}>
                                <ValueBlinker
                                    rate={runnerBackRate}
                                    size={runnerBackSize}
                                    className={`back-1 ${backSelected ? "select" : "null"}`}
                                />
                            </a></td>
                        <td _ngcontent-kgr-c3="" class="lay-1 count 7461 spark">
                            <a _ngcontent-kgr-c3="" onClick={() => (runnerLayRate != ' ' && runnerLayRate > 0) ? onClick('lay', runnerLayRate) : null}>
                                <ValueBlinker
                                    rate={runnerLayRate}
                                    size={runnerLaySize}
                                    className={`lay-1 ${laySelected ? "select" : "null"}`}
                                /></a></td>
                        <td _ngcontent-kgr-c3="" class="lay-2 count 7461 spark">
                            <a _ngcontent-kgr-c3="" >1.1 <span _ngcontent-kgr-c3="">51.7K</span></a></td>
                        <td _ngcontent-kgr-c3="" class="lay-3 count 7461">
                            <a _ngcontent-kgr-c3="">1.11
                                <span _ngcontent-kgr-c3="">48.9K</span></a></td> </tr> </>
            )}
        </>
    );
};

export default RunnerRow;