import React, { useState, useEffect } from 'react'
import Axios from 'axios';

export default function OpenBets(props) {

	const [matchOption, setmatchOption] = useState(1)
	const [view, setview] = useState(1)
	const [bets, setbets] = useState([])
	const [betVal1, setbetVal1] = useState([])
	const [betVal2, setbetVal2] = useState([])
	const [betVal3, setbetVal3] = useState([])
	const [betVal3F, setbetVal3F] = useState([])
	const [menuName, setmenuName] = useState('')

	const changeBetMenu = (obj) => {

		//e.preventDefault();
		if (!props.userid) return;
		if (obj.marketName === 'Match Odds') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Tied Match') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//	console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Completed Match') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Super Over') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 0.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 1.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 2.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Over/Under 3.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.marketName === 'Over/Under 4.5 Goals') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.marketName === 'To Win the Toss') {
			//setmatchOption(2);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Bookmaker') {
			setmatchOption(3);
			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {

					if (result.data) {

						let obj1 = [];
						let obj2 = [];

						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);


					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.marketName === 'Moneyline') {
			setmatchOption(3);
			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {

					if (result.data) {

						let obj1 = [];
						let obj2 = [];

						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);


					}

				}

				).catch(e => {
					console.log(e);
				});

		}

		else if (obj.marketName && obj.marketName != ' ') {
			setmatchOption(1);

			Axios.post('https://liveapi247.live/api1/getbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'YES') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'NO') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3F(obj3);

					}


				}

				).catch(e => {
					console.log(e);
				});

		}

	}
	useEffect(() => {

		if (!props.userid) return;

		Axios.post('https://liveapi247.live/api1/getbetsinfo', {

			id: props.userid,
			token: props.utoken,

		})
			.then(result => {
				//console.log('bets', result.data);
				if (result.data.length > 0) {
					var obj = result.data;

					setbets(obj);
					if (obj[0].marketName === 'Match Odds') {
						setmatchOption(2);
					}
					else if (obj[0].marketName === 'Tied Match') {
						setmatchOption(4);
					}
					else if (obj[0].marketName === 'Completed Match') {
						setmatchOption(5);
					}
					else if (obj[0].marketName === 'Super Over') {
						setmatchOption(6);
					}
					else if (obj[0].marketName === 'Over/Under 0.5 Goals') {
						setmatchOption(7);
					}
					else if (obj[0].marketName === 'Over/Under 1.5 Goals') {
						setmatchOption(8);
					}
					else if (obj.marketName === 'Over/Under 2.5 Goals' || obj.marketName === 'Over/Under 3.5 Goals' || obj.marketName === 'Over/Under 4.5 Goals') {
						setmatchOption(9);
					}
					else if (obj[0].marketName === 'To Win the Toss') {
						setmatchOption(12);
					}
					else if (obj[0].marketName === 'Moneyline') {
						setmatchOption(10);
					}
					else if (obj[0].marketName === 'Bookmaker') {
						setmatchOption(3);
					}
					else if (obj[0].marketName && obj[0].marketName != ' ') {
						setmatchOption(1);
					}

				}

			}

			).catch(e => {
				console.log(e);
			});

		/*  if(anyBets){

		  }
		   */
	}, [props.userid]);

	const openBetHistry = (obj) => {

		changeBetMenu(obj);
		setmenuName(obj.eventName + " - " + obj.marketName);
		if (obj.marketName === 'Match Odds') {
			setmatchOption(2);
		}
		else if (obj.marketName === 'Tied Match') {
			setmatchOption(4);
		}
		else if (obj.marketName === 'Completed Match') {
			setmatchOption(5);
		}
		else if (obj.marketName === 'Super Over') {
			setmatchOption(6);
		}
		else if (obj.marketName === 'Over/Under 0.5 Goals') {
			setmatchOption(7);
		}
		else if (obj.marketName === 'Over/Under 1.5 Goals') {
			setmatchOption(8);
		}
		else if (obj.marketName === 'Over/Under 2.5 Goals' || obj.marketName === 'Over/Under 3.5 Goals' || obj.marketName === 'Over/Under 4.5 Goals') {
			setmatchOption(9);
		}
		else if (obj.marketName === 'To Win the Toss') {
			setmatchOption(12);
		}
		else if (obj.marketName === 'Moneyline') {
			setmatchOption(10);
		}
		else if (obj.marketName === 'Bookmaker') {
			setmatchOption(3);
		}
		else if (obj.marketName && obj.marketName != ' ') {
			setmatchOption(1);
		}
	}
	console.log(betVal1);


	return (
		<React.Fragment>

			{view === 1 &&
				<ul _ngcontent-njs-c67="" id="eventMenuList" class="menu-list">

					{bets.map(function (item, index) {
						return (
							<nav _ngcontent-cdm-c0="" class="all-sports-list">
								<ul _ngcontent-cdm-c0="" class="sport-nav" key={index} onClick={() => { openBetHistry(item); setview(2); }}>
									<div _ngcontent-cdm-c0="" class="mb-betopens">
										<li _ngcontent-cdm-c0="" class="nav-item nav-dropdown">
											<a _ngcontent-cdm-c0="" class="nav-link nav-dropdown-toggle" >
												<span _ngcontent-cdm-c0="" class="bet-marketname">{item.eventName + " - " + item.marketName}</span>
											</a></li>
									</div></ul>
							</nav>

						)
					})}

				</ul>}



			{view === 2 &&
				<React.Fragment>



					<nav _ngcontent-cdm-c0="" class="all-sports-list">
						<ul _ngcontent-cdm-c0="" class="sport-nav">
							<div _ngcontent-cdm-c0="" class="mb-betopens">
								<li _ngcontent-cdm-c0="" class="nav-item nav-dropdown">
									<a _ngcontent-cdm-c0="" class="nav-link nav-dropdown-toggle" >
										<span _ngcontent-cdm-c0="" class="bet-marketname">{menuName}</span>
									</a></li>
							</div></ul>
					</nav>

				</React.Fragment>}



			{view === 2 &&
				<React.Fragment>
					<h3 _ngcontent-njs-c67="" id="txnHeader" >
						Matched
					</h3>
					{matchOption === 2 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>
								<table className='betpl-header'>
									{betVal1.length > 0 &&

										<thead>
											<tr>
												<td>Back</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Profit</td>
											</tr>
										</thead>
									}

									{betVal1.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
										if (betVal3.length == 0) return null;
										return (
											<tbody className='bets-back' key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="lay">Back</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td>{rate}</td>
													<td>{item.amount}</td>
													<td>{parseFloat(item.rate * item.amount).toFixed(2)}</td>
												</tr>
											</tbody>
											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
										)
									})}


									{betVal2.length > 0 && <thead>
										<tr> <td>lay(bet against)</td>
											<td>Odds</td>
											<td>Stake</td>
											<td>Liability</td>
										</tr>

									</thead>}
									{betVal2.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;
										return (<tbody className='bets-lay'>
											<tr> <td>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</td>
												<td>{rate}</td>
												<td>{item.amount}</td>
												<td>{parseFloat(item.rate * item.amount).toFixed(2)}</td>
											</tr>
										</tbody>)
									})}


									{/* {betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})} */}
								</table>
							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>

							</ul>
						</React.Fragment>}

					{matchOption === 4 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>
								<table className='betpl-header'>
									{betVal1.length > 0 &&
										<thead>
											<tr>
												<td>Back</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Profit</td>
											</tr>
										</thead>
									}

									{betVal1.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;

										return (
											<tbody key={index} >
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>
											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
										)
									})}



									{betVal2.length > 0 && <thead>
										<tr>
											<td>Lay</td>
											<td>Odds</td>
											<td>Stake</td>
											<td>Liability</td>
										</tr>
									</thead>}
									{betVal2.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;
										return (<tbody>
											<tr>
												<td><span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
													<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

												<td><span>{rate}</span></td>
												<td><span>{item.amount}</span></td>
												<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
											</tr>
										</tbody>)
									})}
								</table>
								{/* {betVal2.length > 0 &&
									<dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
										<dt>
											<h4 _ngcontent-njs-c67="" >Lay</h4>
										</dt>
										<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
									</dl>}
								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})} */}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 5 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>
								<table className='betpl-header'>
									{betVal1.length > 0 && <thead>
										<tr>
											<td>Back</td>
											<td>Odds</td>
											<td>Stake</td>
											<td>Profit</td>
										</tr>
									</thead>}
									{betVal1.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;

										return (<tbody key={index}>
											<tr>
												<td><span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
													<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

												<td><span>{rate}</span></td>
												<td><span>{item.amount}</span></td>
												<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
											</tr>
										</tbody>)
									})}

									{/* {betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								} */}

									{/* {betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})} */}



									{betVal2.length > 0 &&
										<thead>
											<tr>
												<td>Lay</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Liability</td>
											</tr>
										</thead>

									}


									{betVal2.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;
										return (
											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Lay</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>

										)
									})}
								</table>
							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 6 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>
								<table className='betpl-header'>
									{betVal1.length > 0 &&

										<thead>
											<tr>
												<td>Back</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Profit</td>
											</tr>
										</thead>
									}

									{betVal1.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;

										return (
											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Lay</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>

											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
										)
									})}



									{betVal2.length > 0 &&
										// 	<dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
										// 	<dt>
										// 		<h4 _ngcontent-njs-c67="" >Lay</h4>
										// 	</dt>
										// 	<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
										// </dl>
										<thead>
											<tr>
												<td>Lay</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Liability</td>
											</tr>
										</thead>

									}


									{betVal2.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;
										return (
											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Lay</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>
										)
									})}

								</table>
							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
					{/* 0.5 under */}
					{matchOption === 7 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>
								<table className='betpl-header'>
									{betVal1.length > 0 &&
										// 	<dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
										// 	<dt>
										// 		<h4 _ngcontent-njs-c67="" >Back</h4>
										// 	</dt>
										// 	<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
										// </dl>
										<thead>
											<tr>
												<td>Back</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Profit</td>
											</tr>
										</thead>
									}

									{betVal1.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;

										return (

											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>
										)
									})}



									{betVal2.length > 0 &&
										// <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
										// <dt>
										// 	<h4 _ngcontent-njs-c67="" >Lay</h4>
										// </dt>
										// <dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										// <dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// <dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
										// </dl>
										<thead>
											<tr>
												<td>Lay</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Liability</td>
											</tr>
										</thead>
									}


									{betVal2.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;
										return (
											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Lay</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>
										)
									})}
								</table>
							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{/* 1.5 under */}

					{matchOption === 8 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>
								<table className='betpl-header'>
									{betVal1.length > 0 &&
										// 	<dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
										// 	<dt>
										// 		<h4 _ngcontent-njs-c67="" >Back</h4>
										// 	</dt>
										// 	<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
										// </dl>
										<thead>
											<tr>
												<td>Back</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Profit</td>
											</tr>
										</thead>
									}

									{betVal1.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;

										return (

											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>

											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
										)
									})}



									{betVal2.length > 0 &&
										<thead>
											<tr>
												<td>Lay</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Liability</td>
											</tr>
										</thead>
										// 	<dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
										// 	<dt>
										// 		<h4 _ngcontent-njs-c67="" >Lay</h4>
										// 	</dt>
										// 	<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
										// </dl>
									}


									{betVal2.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;
										return (
											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Lay</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>
										)

									})}
								</table>
							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{/* 2.5 under   */}

					{matchOption === 9 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>
								<table className='betpl-header'>
									{betVal1.length > 0 &&
										// 	<dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
										// 	<dt>
										// 		<h4 _ngcontent-njs-c67="" >Back</h4>
										// 	</dt>
										// 	<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// 	<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
										// </dl>
										<thead>
											<tr>
												<td>Back</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Profit</td>
											</tr>
										</thead>
									}

									{betVal1.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;

										return (

											// <dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
											<tbody key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

													<td><span>{rate}</span></td>
													<td><span>{item.amount}</span></td>
													<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
												</tr>
											</tbody>


										)
									})}



									{betVal2.length > 0 &&
										<thead>
											<tr>
												<td>Lay</td>
												<td>Odds</td>
												<td>Stake</td>
												<td>Liability</td>
											</tr>
										</thead>
										// <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
										// <dt>
										// 	<h4 _ngcontent-njs-c67="" >Lay</h4>
										// </dt>
										// <dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										// <dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// <dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
										// </dl>
									}


									{betVal2.map(function (item, index) {
										var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

										if (betVal3.length == 0) return null;
										return (
											<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
												<dt>
													<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
													<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
												</dt>
												<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
												<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
												<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
												<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
													<ul>
														<li _ngcontent-njs-c67="" id="betId"></li>
														<li _ngcontent-njs-c67="" id="placeDate"></li>
													</ul>
												</dd>
											</dl>)
									})}
								</table>
							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{/* {basketball} */}
					{matchOption === 10 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 12 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Back</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 &&
									<dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
										<dt>
											<h4 _ngcontent-njs-c67="" >Lay</h4>
										</dt>
										<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
										<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
									</dl>
								}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 3 &&
						<React.Fragment>


							<table className='betpl-header'>

								{betVal1.length > 0 &&
									// <dl _ngcontent-njs-c67="" id="header_back" class="bets-head" style={{ display: ' flex' }}>
									// <dt>
									// 	<h4 _ngcontent-njs-c67="" >Back</h4>
									// </dt>
									// <dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									// <dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									// <dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
									// </dl>
									<thead>
										<tr>
											<td>Back</td>
											<td>Odds</td>
											<td>Stake</td>
											<td>Profit</td>
										</tr>
									</thead>
								}

								{betVal1.map(function (item, index) {

									if (betVal3.length == 0) return null;

									return (

										// <dl _ngcontent-njs-c67="" key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
										// 	<dt>
										// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
										// 		<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
										// 	</dt>
										// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
										// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
										// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
										// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
										// 		<ul>
										// 			<li _ngcontent-njs-c67="" id="betId"></li>
										// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
										// 		</ul>
										// 	</dd>
										// </dl
										<tbody className='bets-back' key={index}>
											<tr>
												<td><span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
													<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4></td>

												<td><span>{item.rate * 100}</span></td>
												<td><span>{item.amount}</span></td>
												<td><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></td>
											</tr>
										</tbody>
									)
								})}


								{betVal2.length > 0 && <dl _ngcontent-njs-c67="" id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4 _ngcontent-njs-c67="" >Lay</h4>
									</dt>
									<dd _ngcontent-njs-c67="" class="col-odds"><span>Odds</span></dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									if (betVal3.length == 0) return null;
									return (

										<dl _ngcontent-njs-c67="" key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}
							</table>


							<ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}><a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 1 &&
						<React.Fragment>

							<div _ngcontent-njs-c67="" id="txnList" class="slip-list" >
								<table className='betpl-header'>
									{/* fancy */}
									{betVal1.length > 0 &&
										// <dl _ngcontent-njs-c67="" id="header_yes" class="bets-head">
										// <dt>
										// 	<h4 _ngcontent-njs-c67="" >Yes</h4>
										// </dt>
										// <dd _ngcontent-njs-c67="" id="runsOdds" class="col-odds">Runs/Odds</dd>
										// <dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
										// <dd _ngcontent-njs-c67="" class="col-profit"><span>Profit</span></dd>
										// </dl>
										<thead>
											<tr>
												<td>Yes</td>
												<td>Runs/Odds</td>
												<td>Stake</td>
												<td>Profit</td>
											</tr>
										</thead>
									}

									{betVal1.map(function (item, index) {
										if (betVal3F.length == 0) return null;
										return (

											// <dl _ngcontent-njs-c67="" key={index} id="txnTemplate" class="bets-back" >
											// 	<dt>
											// 		<span _ngcontent-njs-c67="" id="sideType" class="back">Yes</span>
											// 		<h4 _ngcontent-njs-c67="" id="selectionName">{betVal3F[0].runnerName}</h4>
											// 	</dt>
											// 	<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="stake" class="col-stake"><span>{item.amount}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="pl" class="col-profit">
											// 		<span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											// 	<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
											// 		<ul>
											// 			<li _ngcontent-njs-c67="" id="betId"></li>
											// 			<li _ngcontent-njs-c67="" id="placeDate"></li>
											// 		</ul>
											// 	</dd>
											// </dl>
											<tbody className='bets-back' key={index}>
												<tr>
													<td><span _ngcontent-njs-c67="" id="sideType" class="back">Back</span>
														<h4 _ngcontent-njs-c67="" id="selectionName">{betVal3F[0].runnerName}</h4></td>

													<td>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</td>
													<td>{item.amount}</td>
													<td>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</td>
												</tr>
											</tbody>

										)
									})}
								</table>
								<div className="tab-content">

									<tab _ngcontent-dlf-c0="" className="active tab-pane">

										<div _ngcontent-dlf-c0="" className="slip-list" id="txnList" name="txn">
											<table className='betpl-header'>
												{betVal2.length > 0 &&
													// <ul _ngcontent-dlf-c0="" className="matched-head" id="laySlipHeader">
													// 	<li _ngcontent-dlf-c0="" className="col-bet">Lay (Bet Against)</li>
													// 	<li _ngcontent-dlf-c0="" className="col-odd">Odds</li>
													// 	<li _ngcontent-dlf-c0="" className="col-stake">Stake</li>
													// 	<li _ngcontent-dlf-c0="" className="col-profit">Liability</li></ul>
													<thead>
														<tr>
															<td>Lay (Bet Against)</td>
															<td>Odds</td>
															<td>Stake</td>
															<td>Liability</td>
														</tr>
													</thead>
												}
												{betVal2.map(function (item, index) {
													if (betVal3F.length == 0) return null;
													return (
														// <dl _ngcontent-dlf-c0="" className="slip-lay" id="txn_304145953" style={{ display: 'block' }}>
														// <dd _ngcontent-dlf-c0="" className="refer" id="betInfo">
														// 	<span _ngcontent-dlf-c0="" id="betId">Ref: 6528afff1179e5371768ecba</span>
														// 	<span _ngcontent-dlf-c0="" id="placeDate">13-10-2023 08:18:31</span></dd>
														// <dt _ngcontent-dlf-c0=""><span _ngcontent-dlf-c0="" className="slip-type">Lay</span>
														// 	<span _ngcontent-dlf-c0="" className="short-amount">{betVal3F[0].runnerName}</span>
														// 	<span _ngcontent-dlf-c0="" id="marketName">FANCY</span></dt>
														// <dd _ngcontent-dlf-c0="" className="col-odd" id="price">1

														// 	<span _ngcontent-dlf-c0="">{item.rate}</span></dd>
														// <dd _ngcontent-dlf-c0="" className="col-stake" id="size" title="$10">100</dd>
														// <dd _ngcontent-dlf-c0="" className="col-profit text-right" id="profitLiability">
														// 	<span _ngcontent-dlf-c0="" style={{ marginLeft: '5px' }}>-350 </span>
														// 	</dd></dl>
														<tbody className='bets-lay' key={index}>
															<tr>
																<td><span _ngcontent-njs-c67="" id="sideType" class="lay">Lay</span>
																	<h4 _ngcontent-njs-c67="" id="selectionName">{betVal3F[0].runnerName}</h4></td>

																<td>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</td>
																<td>{item.amount}</td>
																<td>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</td>
															</tr>
														</tbody>

													)
												})}
											</table>
										</div>
									</tab>
								</div>

								{/* {betVal2.length > 0 &&
									<dl _ngcontent-njs-c67="" id="header_no" class="bets-head" >
									<dt>
										<h4 _ngcontent-njs-c67="" >No</h4>
									</dt>
									<dd _ngcontent-njs-c67="" id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd _ngcontent-njs-c67="" class="col-stake"><span>Stake</span></dd>
									<dd _ngcontent-njs-c67="" class="col-profit"><span>Liability</span></dd>
									</dl>
								}


								{betVal2.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (
										<dl _ngcontent-njs-c67="" key={index} id="txnTemplate" class="bets-lay" >
											<dt>
												<span _ngcontent-njs-c67="" id="sideType" class="lay">No</span>
												<h4 _ngcontent-njs-c67="" id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd _ngcontent-njs-c67="" id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd _ngcontent-njs-c67="" id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd _ngcontent-njs-c67="" id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd _ngcontent-njs-c67="" id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li _ngcontent-njs-c67="" id="betId"></li>
													<li _ngcontent-njs-c67="" id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})} */}


							</div>

							{/* <ul _ngcontent-njs-c67="" id="openBetOption" class="check-list" style={{ display: 'flex' }}>
								<li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" id="showBetInfo" class="" >Bet Info</a></li>
								<li _ngcontent-njs-c67="" name="txnOption" style={{ display: 'flex' }}>
									<a _ngcontent-njs-c67="" id="averageOdds" class="" >Average Odds</a></li>
							</ul> */}
						</React.Fragment>}
				</React.Fragment>}

		</React.Fragment >
	)
}
