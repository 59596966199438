import React from 'react'

const Rightsports = () => {
    return (
        <React.Fragment>
            <div _ngcontent-lct-c0 className="aside-menu">
                <div _ngcontent-lct-c0 className="card mb-0 desktop-stream" style={{ display: 'block' }}>{/**/}{/**/}</div>
                <div _ngcontent-lct-c0 className>
                    <div _ngcontent-lct-c0 className="card open-bet mb-0 b-none">
                        <div _ngcontent-lct-c0 className="card-header card-accrdn openbetlist"><strong _ngcontent-lct-c0>Open Bets</strong><button _ngcontent-lct-c0 className="close show-xs cls-openbet" type="button"><span _ngcontent-lct-c0 aria-hidden="true">×</span></button></div>{/**/}</div>
                    <div _ngcontent-lct-c0 className="col-md-12 p-mobile-0 p-0">
                        <div _ngcontent-lct-c0 className="bet_slip over-wrap racing-tab " id="openBetSlip">
                            <tabset _ngcontent-lct-c0 className="matchunmatch-tab tab-container">
                                <ul className="nav nav-tabs">{/**/}</ul>
                                <div className="tab-content">{/**/}</div>
                            </tabset>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Rightsports
