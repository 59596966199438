import React from 'react'
import { Link } from 'react-router-dom'
const Menu = (props) => {
    return (
        <React.Fragment>
            <div _ngcontent-dde-c0="" className="menu-wrap">
                <div _ngcontent-dde-c0="" className="full-wrap">
                    <ul _ngcontent-dde-c0="" className="tab-menu pull-left">
                        <li _ngcontent-dde-c0="" className="ver-menu">
                            <Link _ngcontent-dde-c0="" to='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "active" : "null"}`}>Home</Link></li>
                        <li _ngcontent-dde-c0="" className="ver-menu">
                            <Link _ngcontent-dde-c0="" routerlinkactive="active" to="/d/dash/inplay">In-Play</Link></li>
                        <li _ngcontent-dde-c0="" className="ver-menu">
                            <Link _ngcontent-dde-c0="" routerlinkactive="active" to="/d/dash/multimarket">Multi Markets</Link></li>{/**/}
                        <li _ngcontent-dde-c0="" className="ver-menu">
                            <Link _ngcontent-dde-c0="" to="/d/dash/cricket" className="Cricket"> Cricket</Link></li>
                        <li _ngcontent-dde-c0="" className="ver-menu">
                            <a _ngcontent-dde-c0="" href="javascript:;" className="Tennis"> Tennis</a></li>
                        <li _ngcontent-dde-c0="" className="ver-menu">
                            <a _ngcontent-dde-c0="" href="javascript:;" className="Casino newlaunch"> Casino</a></li>
                        <li _ngcontent-dde-c0="" className="ver-menu">
                            <a _ngcontent-dde-c0="" href="javascript:;" className="Soccer"> Soccer</a></li>



                    </ul>
                    <ul _ngcontent-dde-c0="" className="tab-menu pull-right">{/**/}</ul>
                    <div _ngcontent-dde-c0="" className="slip_set-pop" id="set_pop">
                        <div _ngcontent-dde-c0="" className="overlay right-side">
                            <div _ngcontent-dde-c0="" className="side-wrap setting-wrap" id="settingSlide">
                                <div _ngcontent-dde-c0="" className="side-content" id="coinList">
                                    <h3 _ngcontent-dde-c0>Stake</h3>
                                    <dl _ngcontent-dde-c0="" className="setting-block stake-setting" id="editCustomizeStakeList">{/**/}
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0><input _ngcontent-dde-c0="" name="stack_value" type="text" className="ng-untouched ng-pristine ng-valid" /></dd>
                                        <dd _ngcontent-dde-c0="" className="col-stake_edit"><a _ngcontent-dde-c0="" className="btn-send ui-link" href="javascript:;" id="save">Save</a></dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Menu
