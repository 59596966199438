import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Account(props) {
    const [refresh, setrefresh] = useState(true);


    useEffect(() => {

        setTimeout(() => { setrefresh(false); }, 500);
    }, [])


    return (
        <React.Fragment>


            {refresh === true &&
                <div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
                    <ul _ngcontent-qsy-c0="" class="loading">
                        <li _ngcontent-qsy-c0="">
                            <div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
                                <div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
                                    <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                                    <div _ngcontent-qsy-c0=""></div>
                                </div></div></li>
                        <li _ngcontent-qsy-c0="">Loading...</li></ul></div>}

            {refresh === false &&
                <ng-component>
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-md-12 all-sports">
                                <h3 className="quickHeader text-left pl-2" style={{ display: 'block' }}>
                                    <i className="fa fa-user-circle-o" /> &nbsp;{props.userid}</h3>
                                <nav className="all-sports-list">
                                    <ul className="sport-nav">
                                        <div>
                                            <li appnavdropdown className="nav-item nav-dropdown">
                                                <Link className="nav-link nav-dropdown-toggle" to="/d/dash/profile"> My Profile </Link></li>
                                            <li appnavdropdown className="nav-item nav-dropdown">
                                                <Link className="nav-link nav-dropdown-toggle" href="#/rolling-commission"> Rolling Commission </Link></li>
                                            <li appnavdropdown className="nav-item nav-dropdown">
                                                <Link className="nav-link nav-dropdown-toggle" to="/d/dash/acstatement"> Account Statement </Link></li>
                                            <li appnavdropdown className="nav-item nav-dropdown">
                                                <Link className="nav-link nav-dropdown-toggle" to="/d/dash/bethistory"> Bets History </Link></li>
                                            <li appnavdropdown className="nav-item nav-dropdown">
                                                <Link className="nav-link nav-dropdown-toggle" to="/d/dash/profitloss"> Profit &amp; Loss </Link></li>
                                            <li appnavdropdown className="nav-item nav-dropdown">
                                                <Link className="nav-link nav-dropdown-toggle" href="#/password-history"> Password History </Link></li>
                                            <li appnavdropdown className="nav-item nav-dropdown">
                                                <Link className="nav-link nav-dropdown-toggle" to="/d/dash/activitylog"> Activity Log </Link></li>
                                        </div>
                                    </ul>
                                </nav><Link className="mbl-logout-btn" id="logout" onClick={() => { props.handleLogout(); }}>LOGOUT <i className="fa fa-sign-out ml-2" /></Link></div>
                        </div>
                    </div>
                </ng-component>
                // <div _ngcontent-njs-c67="" class="mob-main-content">

                //     <div _ngcontent-njs-c67="" class="path-wrap">
                //         <p class="account-id">
                //             <span>{props.userid}</span>
                //             <span class="time-zone"></span>
                //         </p>
                //     </div>

                //     <ul _ngcontent-njs-c67="" class="sports-side-menu-list">
                //     <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/myprofile" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;My Profile</Link></li>
                //     <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/balance" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Balance Overview</Link></li>
                //     <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67=""  to="/d/dash/AccountStatement" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Account Statement</Link></li>
                //     <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/mybets" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;My Bets</Link></li>
                //     <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67=""  to="/d/dash/bethistory" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Bets History</Link></li>
                //     <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67=""  to="/d/dash/ProfitLoss" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Profit &amp; Loss</Link></li>
                //     <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/activitylog" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Activity Log</Link></li>
                //     </ul>

                //     <a id="logout" className="logout" onClick={() => { props.handleLogout(); }}>LOGOUT
                //     </a>
                // </div>
            }

        </React.Fragment>
    )
}
