import React, { useState, useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import axios from 'axios';


const photos = []

const settings = {
    dots: false,
    slide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    className: 'slidesN'
}


export default function Carousel() {
    const [getBanners, setBanners] = useState([])

    // Fetch Sites Banner
    const getBannerSiteImages = async () => {

        try {
            let resp = await axios.post(
                'https://liveapi247.live/api1/getBanner',
                {
                    siteName: 'LEBUZZADMIN'
                }
            );
            if (resp) {
                // cosnsole.log(resp.data)
                if (resp.status == 200) {
                    return resp.data;
                }
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        async function fetchData() {
            setBanners(photos);
            let val = await getBannerSiteImages();
            if (val.length > 0) {
                let newResp = [{ url: val[0].banner1 }, { url: val[0].banner2 }, { url: val[0].banner3 }, { url: val[0].banner4 }, { url: val[0].banner5 }, { url: val[0].banner6 }, { url: val[0].banner7 }, { url: val[0].banner8 }, { url: val[0].banner9 }, { url: val[0].banner10 }]
                setBanners(newResp)
            }
        }
        fetchData()
    }, []);

    return (
        <React.Fragment>
            <Slider  {...settings} style={{ height: '18.66667vw' }}>
                {getBanners.filter((x, i) => x.url !== null).map((photo, id) => {
                    return (
                        <div _ngcontent-njs-c67="" key={id}>
                            <img _ngcontent-njs-c67="" src={photo.url} alt="cric" style={{ height: '18.66667vw', width: '100vw' }} />
                        </div>
                    )
                })}
            </Slider>
        </React.Fragment>
    )
}
