import React, { useEffect, useState } from 'react'
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

window.betHistoryView = 1;
window.day = 3;

toast.configure()



export default function Bethistory(props) {
	const [select, setselect] = useState(0)
	const [bethistory, setbethistory] = useState([])
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [sTime, setsTime] = useState('09:00')
	const [eTime, seteTime] = useState('08:59')
	const [eventType, seteventType] = useState('100');





	const changeStartDate = (val) => {
		setsDate(val);
	}
	const changeEndDate = (val) => {
		seteDate(val);
	}
	const changeStartTime = (val) => {
		setsTime(val);
	}
	const changeEndTime = (val) => {
		seteTime(val);
	}
	console.log(bethistory);

	const getBetHistory = () => {

		var start = '';
		var end = '';

		if (window.day === 3) {
			start = sDate + ' ' + sTime + ':00';
			end = eDate + ' ' + eTime + ':00';
		}
		else if (window.day === 1) {
			var now = moment();
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}
		else if (window.day === 2) {
			var now = moment().subtract(1, 'days');
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}


		axios.post('https://liveapi247.live/api1/bethistory1', {
			id: props.userid,
			token: props.utoken,
			startDate: start,
			endDate: end,
			view: window.betHistoryView




		})
			.then(result => {
				if (result.data.length == 0) {
					toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
				}

				if (result.status === 200) {
					setbethistory(result.data);


				}



			}

			).catch(e => {
				//setIsError(true);
			});

	}




	return (

		<React.Fragment>


			{/* <div class="centar-content report">
				<h2>My Bets</h2>
				<ul style={{ display: 'flex', width: '55%' }} class="tab col3">
					<li><a href="/d/dash/mybets">Current Bets</a></li>
					<li><a class="select" href="/d/dash/bethistory">Bets History</a></li>
					<li><a href="/d/dash/ProfitLoss">Profit &amp; Loss
					</a></li>
					<li><a href="/d/dash/casinoProfitLoss">Casino Report</a></li>
				</ul>
				<ul class="report-tab-wrap" >
					<li onClick={() => { setselect(0) }} className={`report-tab ${(select === 0) ? "select" : "null"}`} id="reportType_exchange" data-reporttabtype="0">
						Exchange
					</li>
					<li onClick={() => { setselect(1) }} className={`report-tab ${(select === 1) ? "select" : "null"}`} id="reportType_sportsBook" data-reporttabtype="2">
						Sportsbook
					</li>
					<li onClick={() => { setselect(2) }} className={`report-tab ${(select === 2) ? "select" : "null"}`} id="reportType_bookMaker" data-reporttabtype="3">
						BookMaker
					</li>
					<li onClick={() => { setselect(3) }} className={`report-tab ${(select === 3) ? "select" : "null"}`} id="reportType_binary" data-reporttabtype="5">
						Binary
					</li>
				</ul>
				<div class="function-wrap">
					<ul class="input-list">
						<div id="statusCondition">
							<li><label>Bet Status:</label></li>
							<li>
								<select name="betStatus" id="betStatus"><option value="2">Settled</option></select>
							</li>
						</div>
						<li><label>Period</label></li>
						<li>

							<ul class="input-list" style={{ display: 'inline-flex' }}>

								<DatePicker
									selectsStart
									dateFormat="yyyy-MM-dd"
									selected={startDate}
									placeholderText="YYYY-MM-DD"
									className="cal-input"
									onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
								/>
								<input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

								<DatePicker
									selectsEnd
									dateFormat="yyyy-MM-dd"
									placeholderText="YYYY-MM-DD"
									selected={endDate}
									onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
									className="cal-input"
								/>
								<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


							</ul>


						</li>

					</ul>

					<ul class="input-list">
						<li><a id="today" onClick={() => { window.day = 1; window.betHistoryView = 1; getBetHistory(); }} class="btn">Just For Today</a></li>
						<li><a id="yesterday" onClick={() => { window.day = 2; window.betHistoryView = 1; getBetHistory(); }} class="btn">From Yesterday</a></li>

						<li><a id="getPL" onClick={() => { window.day = 3; window.betHistoryView = 1; getBetHistory(); }} class="btn-send">Get History</a></li>
					</ul>
				</div>
				<div id="report">

					<table id="matchTable" class="table-s" style={{ display: 'table' }}>
						<tbody><tr>
							<th width="9%" class="align-L">Bet ID
							</th>
							<th width="9%" class="align-L">PL ID
							</th>
							<th width="" class="align-L">Market
							</th>
							<th width="12%">Selection
							</th>
							<th width="4%">Type
							</th>
							<th width="8%">Bet placed
							</th>

							<th width="8%">Stake
							</th>
							<th width="8%">Avg. odds matched
							</th>
							<th width="10%">Profit/Loss
							</th>

						</tr>

							{bethistory.map((item, index) => {
								var matchName;
								var runner;
								var odds;
								var profit = '';
								var matchtype;
								if (item.sportId == '4') {
									matchName = 'CRICKET';
								} else if (item.sportId == '1') {
									matchName = 'SOCCER';
								}
								else if (item.sportId == '2') {
									matchName = 'TENNIS';
								}
								if (item.betType == 'fancy') {
									runner = item.runnerName;
									odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
								}
								else if (item.betType == 'match') {
									odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
									if (item.teamName == 'A') {
										runner = item.runnerName1;
									}
									else if (item.teamName == 'B') {
										runner = item.runnerName2;
									}
									else if (item.teamName == 'T') {
										runner = 'The Draw';
									}
								}

								if (item.betType == 'fancy' && item.result) {
									if (item.type == "YES") {
										if (parseFloat(item.rate) <= parseFloat(item.result)) {

											profit = parseFloat(item.amount * item.teamName).toFixed(2);
										}
										else profit = item.amount * (-1);

									}
									else {
										if (parseFloat(item.rate) > parseFloat(item.result)) {
											profit = item.amount;
										}
										else profit = parseFloat((item.amount * item.teamName) * (-1)).toFixed(2);
									}
								}
								else if (item.betType == 'match' && item.winner) {
									if (item.type == "LAGAI") {
										if (item.teamName == 'A') {
											if (item.winner == "A") profit = parseFloat(item.rate * item.amount).toFixed(2);
											else profit = item.amount * (-1);
										}
										else if (item.teamName == "B") {
											if (item.winner == "B") profit = parseFloat(item.rate * item.amount).toFixed(2);
											else profit = item.amount * (-1);
										}
										else if (item.teamName == "T") {
											if (item.winner == "T") profit = parseFloat(item.rate * item.amount).toFixed(2);
											else profit = item.amount * (-1);
										}
									}
									else if (item.type == "KHAI") {
										if (item.teamName == 'A') {
											if (item.winner != "A") profit = item.amount;
											else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
										}
										else if (item.teamName == "B") {
											if (item.winner != "B") profit = item.amount;
											else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
										}
										else if (item.teamName == "T") {
											if (item.winner != "T") profit = item.amount;
											else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
										}
									}

									if ((item.runnerId3 == null || item.runnerId3 == '' || item.runnerId3 == ' ') && item.winner == "T") {
										profit = 0;
									}

								}

								if (item.type === 'LAGAI') {
									matchtype = 'BACK';
								}
								else if (item.type === 'KHAI') {
									matchtype = 'LAY';
								}
								else {
									matchtype = item.type;
								}



								return (
									<tr key={index} id="matchRow0" style={{ display: 'table-row' }}>
										<td class="align-L" id="playerID">{item.clientId}</td>
										<td class="align-L"><a id="betID" href="javascript: void(0);" class="expand-close" 
										onclick="BettingHistoryUtilHandler.toggleTx(0)">{item.id}</a></td>
										<td id="matchTitle" class="align-L">{matchName}<img class="fromto" src={Transparent} />
										<strong>{item.eventName}</strong>
										<img class="fromto" src={Transparent} />
										{item.betType == 'match' ? item.marketName : 'FANCY_BET'}</td>
										<td id="matchSelection">{runner}</td>
										<td   >
										<span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
										<td   >
										<span id="betPlaced" class="small-date">{item.betTime}</span></td>

										<td id="matchStake">{item.amount}</td>
										<td id="matchAvgOdds">{odds}</td>
										<td   ><span id="pol" class="small-date"><span className={`${profit >= 0 ? "green" : "red"}`}>{profit >= 0 ? profit : '(' + Math.abs(profit) + ')'}</span></span></td>

									</tr>
								)
							})}







						</tbody>
					</table>
					{bethistory.length > 0 && <p class="table-other">Betting History is shown net of commission.
					</p>}
					{bethistory.length == 0 && <p class="table-other">Betting History enables you to review the bets you have placed.
					</p>}
					{bethistory.length == 0 && <p class="table-other">Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.


					</p>}




					{bethistory.length == 0 && <p style={{ marginTop: '10px' }} class="table-other">Betting History is available online for the past 30 days.


					</p>}




					<div>

						{bethistory.length > 0 && <ul id="pageNumberContent" class="pages">
							<li id="prev"><a onClick={() => { if (window.betHistoryView > 1) { window.betHistoryView = window.betHistoryView - 1; getBetHistory(); } }} className={`${(window.betHistoryView > 1) ? "" : "disable"}`}>Prev</a></li>
							<li id="pageNumber"><a class="select" style={{ pointerEvents: ' none' }}>{window.betHistoryView}</a></li>
							<li id="next"><a onClick={() => { if (bethistory.length >= 50) { window.betHistoryView = window.betHistoryView + 1; getBetHistory(); } }} className={`${(bethistory.length >= 50) ? "" : "disable"}`} >Next</a></li></ul>}
					</div>

				</div>
			</div> */}
			<app-bet-history _nghost-nev-c5>
				<div _ngcontent-nev-c5 className="animated fadeIn divider-top">
					<div _ngcontent-nev-c5 className="row">
						<div _ngcontent-nev-c5 className="col-sm-12 col-md-12 p-mobile-1">
							<div _ngcontent-nev-c5 className="card card-report card-accent-primary">
								<div _ngcontent-nev-c5 className="card-body row p-0">
									<div _ngcontent-nev-c5 className="col-md-12 betHistory">
										<div _ngcontent-nev-c5 className="row">
											<div _ngcontent-nev-c5 className="col-md-12 row">
												<div _ngcontent-nev-c5 className="col-md-4 col-6">
													<div _ngcontent-nev-c5 className="form-group">
														<select _ngcontent-nev-c5 className="form-control ng-untouched ng-pristine ng-valid">
															<option _ngcontent-nev-c5 disabled value>Data Source</option>
															<option _ngcontent-nev-c5 value="LIVE">LIVE DATA</option>
															<option _ngcontent-nev-c5 value="BACKUP">BACKUP DATA</option>
															<option _ngcontent-nev-c5 value="OLD">OLD DATA</option></select>
													</div></div><div _ngcontent-nev-c5 className="col-md-4 col-6"
													>
													<div _ngcontent-nev-c5 className="form-group">
														<select _ngcontent-nev-c5 className="form-control ng-untouched ng-pristine ng-valid" name="betType">
															<option _ngcontent-nev-c5 disabled value>Select Sport</option>
															<option _ngcontent-nev-c5 value={eventType === '4'}>Cricket </option>
															<option _ngcontent-nev-c5 value={eventType === '2'}>Tennis </option>
															<option _ngcontent-nev-c5 value={66102}>Casino </option>
															<option _ngcontent-nev-c5 value={eventType === '1'} >Soccer </option>
															<option _ngcontent-nev-c5
															>Horse Racing </option>
															<option _ngcontent-nev-c5 >Virtual Sports </option>
															<option _ngcontent-nev-c5 >Basketball </option></select>
													</div></div>
												<div _ngcontent-nev-c5 className="col-md-4 col-12">
													<div _ngcontent-nev-c5 className="form-group">
														<select _ngcontent-nev-c5 className="form-control ng-untouched ng-pristine ng-valid" name="betStatus">
															<option _ngcontent-nev-c5 disabled value>Select Type</option>
															<option _ngcontent-nev-c5 value="settle">Settle</option>

														</select>
													</div></div>
												<div _ngcontent-nev-c5 className="col-md-4 col-12">
													<div _ngcontent-nev-c5 className="input-group">
														<DatePicker
															selectsStart
															dateFormat="yyyy-MM-dd"
															selected={startDate}
															placeholderText="YYYY-MM-DD"
															className="form-control ng-untouched ng-pristine ng-valid"
															onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
														/>
														<div _ngcontent-nev-c5 className="input-group-append">
															<button _ngcontent-nev-c5 className="btn btn-secondary" type="button">
																<i _ngcontent-nev-c5 className="fa fa-calendar-o" /></button>
														</div></div></div><div _ngcontent-nev-c5 className="col-md-4 col-12">
													<div _ngcontent-nev-c5 className="input-group">
														<DatePicker
															selectsEnd
															dateFormat="yyyy-MM-dd"
															placeholderText="YYYY-MM-DD"
															selected={endDate}
															onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
															className="form-control ng-untouched ng-pristine ng-valid"
														/>
														<div _ngcontent-nev-c5 className="input-group-append">
															<button _ngcontent-nev-c5 className="btn btn-secondary" type="button">
																<i _ngcontent-nev-c5 className="fa fa-calendar-o" /></button></div>
													</div></div><div _ngcontent-nev-c5 className="col-md-4 col-6">
													<button _ngcontent-nev-c5 className="btn btn-primary" type="button" onClick={() => { window.day = 3; window.betHistoryView = 1; getBetHistory(); }}>
														<strong _ngcontent-nev-c5>Get History</strong>
													</button>
												</div></div></div></div></div></div></div>
					</div><div _ngcontent-nev-c5 className="row">
						<div _ngcontent-nev-c5 className="col-sm-12 col-md-12 p-mobile-1">
							<div _ngcontent-nev-c5 className="card card-accent-primary">
								<div _ngcontent-nev-c5 className="card-header account-detail-head">Bet History </div>
								<div _ngcontent-nev-c5 className="card-body account-stat-body">
									<div _ngcontent-nev-c5 className="row">
										<div _ngcontent-nev-c5 className="col-md-12 p-mobile-0">
											<div _ngcontent-nev-c5 className="table-responsive">
												<div id="DataTables_Table_6_wrapper" className="dataTables_wrapper">
													<div className="dataTables_length" id="DataTables_Table_6_length">
														<label>Show
															<select name="DataTables_Table_6_length" aria-controls="DataTables_Table_6" className>
																<option value={10}>10</option>
																<option value={25}>25</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>entries</label>
													</div>
													<table _ngcontent-nev-c5 className="table table-bordered dataTable" datatable id="DataTables_Table_6" role="grid" aria-describedby="DataTables_Table_6_info">
														<thead _ngcontent-nev-c5 className="text-center">
															<tr _ngcontent-nev-c5 role="row">
																<th _ngcontent-nev-c5 className="sorting_desc" tabIndex={0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="Sport Name: activate to sort column ascending">Sport Name</th>
																<th _ngcontent-nev-c5 style={{ minWidth: '120px' }} className="sorting" tabIndex={
																	0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1}>Event Name</th>
																<th _ngcontent-nev-c5 className="sorting" tabIndex={0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1} aria-label="Market Name: activate to sort column ascending">Market Name</th>
																<th _ngcontent-nev-c5 className="sorting" tabIndex={0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1} aria-label="Selection: activate to sort column ascending">Selection</th>
																<th _ngcontent-nev-c5 className="sorting" tabIndex={0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1} aria-label="Type: activate to sort column ascending">Type</th>
																<th _ngcontent-nev-c5 className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="Odds Req.">Odds Req.</th>
																<th _ngcontent-nev-c5 className="sorting" tabIndex={0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1} aria-label="Stake: activate to sort column ascending">Stake</th>
																<th _ngcontent-nev-c5 style={{ minWidth: '65px' }} className="sorting" tabIndex={0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1} aria-label="Place Time: activate to sort column ascending">Place Time</th>
																<th _ngcontent-nev-c5 style={{ minWidth: '65px' }} className="sorting" tabIndex={0} aria-controls="DataTables_Table_6" rowSpan={1} colSpan={1} aria-label="Matched Time: activate to sort column ascending">Matched Time</th>
															</tr>
														</thead>
														<tbody _ngcontent-nev-c5>
															{bethistory.map((item, index) => {
																var matchName;
																var runner;
																var odds;
																var profit = '';
																var matchtype;
																if (item.sportId == '4') {
																	matchName = 'CRICKET';
																} else if (item.sportId == '1') {
																	matchName = 'SOCCER';
																}
																else if (item.sportId == '2') {
																	matchName = 'TENNIS';
																}
																if (item.betType == 'fancy') {
																	runner = item.runnerName;
																	odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
																}
																else if (item.betType == 'match') {
																	odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
																	if (item.teamName == 'A') {
																		runner = item.runnerName1;
																	}
																	else if (item.teamName == 'B') {
																		runner = item.runnerName2;
																	}
																	else if (item.teamName == 'T') {
																		runner = 'The Draw';
																	}
																}

																if (item.betType == 'fancy' && item.result) {
																	if (item.type == "YES") {
																		if (parseFloat(item.rate) <= parseFloat(item.result)) {

																			profit = parseFloat(item.amount * item.teamName).toFixed(2);
																		}
																		else profit = item.amount * (-1);

																	}
																	else {
																		if (parseFloat(item.rate) > parseFloat(item.result)) {
																			profit = item.amount;
																		}
																		else profit = parseFloat((item.amount * item.teamName) * (-1)).toFixed(2);
																	}
																}
																else if (item.betType == 'match' && item.winner) {
																	if (item.type == "LAGAI") {
																		if (item.teamName == 'A') {
																			if (item.winner == "A") profit = parseFloat(item.rate * item.amount).toFixed(2);
																			else profit = item.amount * (-1);
																		}
																		else if (item.teamName == "B") {
																			if (item.winner == "B") profit = parseFloat(item.rate * item.amount).toFixed(2);
																			else profit = item.amount * (-1);
																		}
																		else if (item.teamName == "T") {
																			if (item.winner == "T") profit = parseFloat(item.rate * item.amount).toFixed(2);
																			else profit = item.amount * (-1);
																		}
																	}
																	else if (item.type == "KHAI") {
																		if (item.teamName == 'A') {
																			if (item.winner != "A") profit = item.amount;
																			else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
																		}
																		else if (item.teamName == "B") {
																			if (item.winner != "B") profit = item.amount;
																			else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
																		}
																		else if (item.teamName == "T") {
																			if (item.winner != "T") profit = item.amount;
																			else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
																		}
																	}

																	if ((item.runnerId3 == null || item.runnerId3 == '' || item.runnerId3 == ' ') && item.winner == "T") {
																		profit = 0;
																	}

																}

																if (item.type === 'LAGAI') {
																	matchtype = 'BACK';
																}
																else if (item.type === 'KHAI') {
																	matchtype = 'LAY';
																}
																else {
																	matchtype = item.type;
																}



																return (<tr key={index} _ngcontent-nev-c5 className="text-center">
																	<td _ngcontent-nev-c5>{matchName}</td>
																	<td _ngcontent-nev-c5>{item.eventName}</td>
																	<td _ngcontent-nev-c5>{item.marketName}</td>
																	<td _ngcontent-nev-c5>{runner} </td>
																	<td _ngcontent-nev-c5>
																		<span _ngcontent-nev-c5 className="fw-b lay-text">
																			<span _ngcontent-nev-c5>{item.type}</span></span>
																	</td>
																	<td _ngcontent-nev-c5>{item.rate}

																	</td>
																	<td _ngcontent-nev-c5>
																		<strong _ngcontent-nev-c5>{item.amount}</strong></td>
																	<td _ngcontent-nev-c5>{item.betTime}</td>
																	<td _ngcontent-nev-c5>03-10-2023 11:58:07</td></tr>
																)
															})}</tbody>

														<tbody>
															<tr className="odd"><td valign="top" colSpan={9} className="dataTables_empty">No matching records found</td></tr></tbody></table>
													<div className="dataTables_info" id="DataTables_Table_6_info" role="status" aria-live="polite">Showing 1 to 2 of 2 entries</div>
													<div className="dataTables_paginate paging_full_numbers" id="DataTables_Table_6_paginate">
														<a className="paginate_button first disabled" aria-controls="DataTables_Table_6" data-dt-idx={0} tabIndex={0} id="DataTables_Table_6_first">First</a>
														<a className="paginate_button previous disabled" aria-controls="DataTables_Table_6" data-dt-idx={1} tabIndex={0} id="DataTables_Table_6_previous">Previous</a>
														<span>
															<a className="paginate_button current" aria-controls="DataTables_Table_6" data-dt-idx={2} tabIndex={0}>1</a></span>
														<a className="paginate_button next disabled" aria-controls="DataTables_Table_6" data-dt-idx={3} tabIndex={0} id="DataTables_Table_6_next">Next</a>
														<a className="paginate_button last disabled" aria-controls="DataTables_Table_6" data-dt-idx={4} tabIndex={0} id="DataTables_Table_6_last">Last</a></div>
												</div></div></div>
									</div></div></div>
						</div></div>
				</div>
			</app-bet-history>
		</React.Fragment>

	)
}
