import React, { useEffect, useState } from 'react'
// import Cup from '../images/sprite/cup.gif';
import CricketMatches from './CricketMatches';
import SearchBar from './SearchBar';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import axios from 'axios';
import moment from 'moment'
import Kabaddi from './Kabaddi';
import Support from './Support';
import Binary from './Binary';
import Transparent from '../images/sprite/transparent.gif'
import { Link } from 'react-router-dom';
// import horse from '../images/horsel.6a5281de.svg'
// import MT5 from '../images/mt5.4f09b4e8.svg'
import transprant from '../images/kingexch/transparent.gif'

import Cookies from 'universal-cookie';
import BasketballMatches from './BasketballMatches';
import Election from './Election';

const cookies = new Cookies();

require('moment-timezone');

export default function Highlights(props) {
	const [highlightTab, sethighlightTab] = useState(4)
	const [Searchbar, setSearchbar] = useState(false)
	const [countCricket, setCountCricket] = useState(0);
	const [countSoccer, setCountSoccer] = useState(0);
	const [CountBasketball, setCountBasketball] = useState(0);
	const [countTennis, setCountTennis] = useState(0);
	const [newrefresh, setnewrefresh] = useState(true);
	const [casinoWait, setcasinoWait] = useState(false);


	function changecount(cri, socc, tenn, BasBall) {

		setCountCricket(cri);
		setCountSoccer(socc);
		setCountTennis(tenn);
		setCountBasketball(BasBall);
	}

	useEffect(() => {
		axios.all([
			axios.get('https://liveapi247.live/api1/cricketData'),
			axios.get('https://liveapi247.live/api1/soccerData'),
			axios.get('https://liveapi247.live/api1/tennisData'),
			axios.get('https://liveapi247.live/api1/BasketballData')
		])
			.then(axios.spread((obj1, obj2, obj3, obj4) => {

				setnewrefresh(false)
				var count1 = 0;
				var count2 = 0;
				var count3 = 0;
				var count4 = 0;

				obj1.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj2.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})
				obj3.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj4.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj1.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count1 = count1 + 1
					}
				});
				obj2.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count2 = count2 + 1;
					}
				});
				obj3.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count3 = count3 + 1;
					}
				});

				obj4.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count4 = count4 + 1;
					}
				});


				changecount(count1, count2, count3, count4);



			}));
	}, [])

	const openSearchbar = () => {
		setSearchbar(true)
	}

	const closeSearchbar = () => {
		setSearchbar(false)
	}


	const openAESexy = (gameI) => {
		if (!props.isLoggedIn) {
			props.checkShowLogin(true);
			return;
		}
		else {
			var utoken = cookies.get('token');
			var user = cookies.get('id');
			var mil = Date.now();

			if (!user || !utoken) {
				return;
			}
			setnewrefresh(true);
			axios.post('https://liveapi247.live/api/gapcasino/game/url', {
				user: user,
				token: utoken,
				platform: 'GPL_DESKTOP',
				lobby_url: window.location.href,
				game_id: gameI

			})
				.then(result => {

					setTimeout(() => { setnewrefresh(false); }, 4000);
					if (result.data.url) {
						// window.open(result.data.url);
						//var newTab=window.open('about:blank');
						window.location.href = result.data.url;

					}


				})
				.catch(e => {

				});

		}
	}





	return (

		<React.Fragment>
			{newrefresh === true &&
				<div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
					<ul _ngcontent-qsy-c0="" class="loading">
						<li _ngcontent-qsy-c0="">
							<div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
								<div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
									<div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
									<div _ngcontent-qsy-c0=""></div>
								</div></div></li>
						<li _ngcontent-qsy-c0="">Loading...</li></ul></div>}

			{/* {newrefresh === false &&
				<div _ngcontent-njs-c67="" className='mob-main-content'  >
					{Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
					<div _ngcontent-njs-c67="" id="highlightLabel" className="highlight-fix">

						<a className="a-search" onClick={openSearchbar} >Search</a>
						<div _ngcontent-njs-c67="" id="frame" className="mob-nav-highlight ps ps--theme_default ps--active-x">


							<ul _ngcontent-njs-c67="" id="label">
								{props.isLoggedIn &&
									<li _ngcontent-njs-c67="" class="menu-casino">
										<span _ngcontent-njs-c67="" class="tag-new">New</span>
										<Link to="/CasinoList" id="casinoLoginBtn" ><img class="icon-casino" src={Transparent} />Casino</Link>
									</li>}

							

								<li _ngcontent-njs-c67="" id="highlightTab4" onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "select" : "null"}`}>
									<span _ngcontent-njs-c67="" id="tagLive" className="mob-live-number"><strong></strong>{countCricket}</span>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" className="icon-cricket" src={Cup} alt='gif' />Cricket</a>
								</li>

								

								<li _ngcontent-njs-c67="" id="highlightTab1" onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "select" : "null"}`}>
									<span _ngcontent-njs-c67="" id="tagLive" className="mob-live-number"><strong></strong>{countSoccer}</span>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" className="icon-soccer" src={Cup} alt='gif' />Soccer</a>
								</li>
								


								

								<li _ngcontent-njs-c67="" id="highlightTab2" onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "select" : "null"}`}>
									<span _ngcontent-njs-c67="" id="tagLive" className="mob-live-number"><strong></strong>{countTennis}</span>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" className="icon-tennis" src={Cup} alt='gif' />Tennis</a>
								</li>

								
								
							</ul>

							<div _ngcontent-njs-c67="" className="ps__scrollbar-x-rail"><div _ngcontent-njs-c67="" className="ps__scrollbar-x"  ></div></div><div _ngcontent-njs-c67="" className="ps__scrollbar-y-rail" ><div _ngcontent-njs-c67="" className="ps__scrollbar-y"  ></div></div></div>
					</div>

					<div _ngcontent-njs-c67="" className="mob-nav-highlight_list" style={{ height: 'auto', backgroundColor: 'pink' }}  >
						<h3>  Highlights</h3>

						<ul _ngcontent-njs-c67="" id="highlightList" className="mob-sports-highlight-list">
							
							{highlightTab === 1 && <SoccerMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 2 && <TennisMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 7522 && <BasketballMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
						
						</ul>
					</div>
					

				</div>
			} */}

			<router-outlet _ngcontent-dde-c0 />
			<ng-component>
				<div className="mobile-eventsearch">
					<div className="input-group">
						<ng-select className="w-100 ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid" name="SearchEvent" placeholder="Search Events" role="listbox">
							<div className="ng-select-container ng-has-value">
								<div className="ng-value-container">
									<div className="ng-placeholder">Search Events</div>{/**/}{/**/}{/**/}
									<div className="ng-value">{/**/}{/**/}<span aria-hidden="true" className="ng-value-icon left">×</span><span className="ng-value-label" /></div>{/**/}{/**/}
									<div className="ng-input"><input role="combobox" type="text" autoComplete="a7f9c183fd3e" autoCorrect="off" autoCapitalize="off" aria-expanded="false" /></div>
								</div>{/**/}{/**/}<span className="ng-clear-wrapper" title="Clear all"><span aria-hidden="true" className="ng-clear">×</span></span><span className="ng-arrow-wrapper"><span className="ng-arrow" /></span></div>{/**/}</ng-select>
					</div>
				</div>
				<div className="overlay-event" />
				<div className="animated fadeIn hideevent-search">
					<div className="row" style={{ marginTop: '0px' }}>
						<div className="mobile-men">
							<a className="a-search" href="javascript:;">Search</a>
							<ul className="menu-list">{/**/}
								<li onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "menu-item active" : "menu-item"}`}>
									<div>
										<img src={transprant} className="icon-Cricket" />
										<span className="menutext">Cricket</span></div>
								</li>
								<li onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "menu-item active" : "menu-item"}`}>
									<div>
										<img src={transprant} className="icon-Tennis" />
										<span className="menutext">Tennis</span></div>
								</li>
								<li className="menu-item newlaunch">
									<div><img src={transprant} className="icon-Casino" />
										<span className="menutext">Casino</span></div>
								</li>
								<li onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "menu-item active" : "menu-item"}`}>
									<div><img src={transprant} className="icon-Soccer" />
										<span className="menutext">Soccer</span></div>
								</li>



							</ul>
						</div>
						<div className="col-md-12 mobile-display-view">
							<div className="game-highlight-wrap">
								<h3>Highlights</h3>
								<tabset className="game-menu-tab hidden-xs homeevent tab-container">
									<ul className="nav nav-tabs">{/**/}
										<li className="nav-item active"><a className="nav-link active" id><span>Cricket</span>{/**/}</a></li>
										<li className="nav-item">
											<a className="nav-link" id><span>Soccer</span>{/**/}</a></li>
										<li className="nav-item">
											<a className="nav-link" id><span>Tennis</span>{/**/}</a></li>
									</ul>
									<div className="tab-content">
										<tab heading="Cricket" className="active tab-pane">
											<div className="row row-mob">
												<div className="col-md-12 mobile-width row-with">
													<table className="table table-hover">
														<thead>
															<tr>
																<th className="view-mobile" colSpan={2} />
																<th className="text-center">1</th>
																<th className="text-center">X</th>
																<th className="text-center">2</th>
																<th className="text-center" />
															</tr>
														</thead>
														<tbody>{/**/}
															<tr>
																<td><a className="match-name">
																	<span>testing2 vs testing3</span></a>{/**/}
																	<span className="in_play">In-Play</span>{/**/}
																	<span className="in_play"><i className="fa fa-television" />
																	</span>{/**/}</td>
																<td>{/**/}{/**/}{/**/}{/**/}</td>{/**/}
																<td className="count">
																	<span className="back">0</span>
																	<span className="lay">0</span></td>{/**/}{/**/}{/**/}
																<td className="count">
																	<span className="back">0.0</span>
																	<span className="lay">0.0</span></td>{/**/}
																<td className="count">
																	<span className="back">0</span>
																	<span className="lay">0</span></td>{/**/}
																<td className="pl-0">
																	<a id="multiMarketPin" style={{ cursor: 'pointer' }} title="Add to Multi Markets" className="add-pin">Pin</a></td>
															</tr>

															{/**/}</tbody>
													</table>
												</div>
											</div>
										</tab>
										<tab heading="Soccer" className="tab-pane">
											<div className="row">
												<div className="col-md-12 mobile-width">
													<div className="table-responsive">
														<table className="table table-hover">
															<thead>
																<tr>
																	<th className="view-mobile" colSpan={2} />
																	<th className="text-center">1</th>
																	<th className="text-center">X</th>
																	<th className="text-center">2</th>
																	<th className="text-center" />
																</tr>
															</thead>
															<tbody>{/**/}

																<tr>
																	<td><a className="match-name"><span>Real Sociedad v Athletic Bilbao</span></a>{/**/}{/**/}{/**/}
																		<p className="match-time">01-10-2023 00:30 </p>
																	</td>
																	<td>{/**/}{/**/}{/**/}{/**/}</td>{/**/}
																	<td className="count"><span className="back">0</span><span className="lay">0</span></td>{/**/}{/**/}
																	<td className="count"><span className="back">0</span><span className="lay">0</span></td>{/**/}{/**/}
																	<td className="count"><span className="back">0</span><span className="lay">0</span></td>{/**/}
																	<td className="pl-0"><a id="multiMarketPin" style={{ cursor: 'pointer' }} title="Add to Multi Markets" className="add-pin 1002023929163633542">Pin</a></td>
																</tr>{/**/}</tbody>
														</table>
													</div>
												</div>
											</div>
										</tab>
										<tab heading="Tennis" className="tab-pane">
											<div className="row">
												<div className="col-md-12 mobile-width">
													<table className="table table-hover">
														<thead>
															<tr>
																<th className="view-mobile" colSpan={2} />
																<th className="text-center">1</th>
																<th className="text-center">X</th>
																<th className="text-center">2</th>
																<th className="text-center" />
															</tr>
														</thead>
														<tbody>{/**/}

															<tr>
																<td><a className="match-name"><span>A Zverev v Davidovich Fokina</span></a>{/**/}{/**/}{/**/}
																	<p className="match-time">30-09-2023 18:30</p>
																</td>
																<td>{/**/}{/**/}{/**/}{/**/}</td>{/**/}
																<td className="count"><span className="back">0</span><span className="lay">0</span></td>{/**/}{/**/}{/**/}
																<td className="count"><span className="back">0.0</span><span className="lay">0.0</span></td>{/**/}
																<td className="count"><span className="back">0</span><span className="lay">0</span></td>{/**/}
																<td className="pl-0"><a id="multiMarketPin" style={{ cursor: 'pointer' }} title="Add to Multi Markets" className="add-pin 2002023929171348725">Pin</a></td>
															</tr>{/**/}</tbody>
													</table>
												</div>
											</div>
										</tab>
									</div>
								</tabset>
								<div className="events-mobile">
									<div className="row-mob">
										<div className="mob-width" >
											<div className="table-responsive tbl-mobile-event">
												<table className="table table-hover">
													<tbody>{/**/}
														{props.tabMenu === 4 || highlightTab === 4 && <CricketMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
														{highlightTab === 1 && <SoccerMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
														{highlightTab === 2 && <TennisMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
														{/**/}</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12">
							<div className="inner-footer">{/**/}
								<div className="support-wrap">
									<dl className="support-mail"><a className="rules-btn-home">Privacy Policy</a><a className="rules-btn-home arrow">KYC</a><a className="rules-btn-home arrow">Terms and Conditions</a><a className="rules-btn-home arrow">Rules and Regulations</a><a className="rules-btn-home arrow">Responsible Gambling</a></dl>
								</div>
							</div>
						</div>
						<div className="mobile-footer">{/**/}
							<div className="support-wrap">
								<dl className="support-mail"><a className="rules-btn-home">Privacy Policy</a><a className="rules-btn-home arrow">Rules and Regulations</a><a className="rules-btn-home arrow">KYC</a><a className="rules-btn-home arrow">Terms and Conditions</a><a className="rules-btn-home arrow">Responsible Gambling</a></dl>
							</div>{/**/}
							<div className="extra-wrap">{/**/}{/**/}</div>
						</div>
					</div>
				</div>{/**/}</ng-component>

		</React.Fragment >
	)
}
