import React, { memo } from 'react';


const LoadingOverlay = ({ show, style }) => {
    if (show) {
        return (
            <div _ngcontent-qsy-c0="" className="loading-wrap" id="loading">
                <ul _ngcontent-qsy-c0="" className="loading">
                    <li _ngcontent-qsy-c0="">
                        <div _ngcontent-qsy-c0="" className="loadingio-spinner-dual-ball-srpf31lqtit">
                            <div _ngcontent-qsy-c0="" className="ldio-dayih5pen3j">
                                <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                                <div _ngcontent-qsy-c0=""></div>
                            </div></div></li>
                    <li _ngcontent-qsy-c0="">Loading...</li></ul></div>

        );
    }
    return null;
};

export default memo(LoadingOverlay);
