import React, { useEffect, useState } from 'react'
//import './style.css'
import axios from 'axios';
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'

export default function AccountStatement(props) {
   const [Agentid, setAgentid] = useState('');
   const [Coin, setCoin] = useState('');
   const [FixLimit, setFixLimit] = useState('');
   const [CurrentTime, setCurrentTime] = useState('')
   const [accountStatement, setaccountStatement] = useState([])

   useEffect(() => {

      axios.post('https://liveapi247.live/api1/accountstatement', {
         id: props.userid,
         token: props.utoken,

      })
         .then(result => {


            setaccountStatement(result.data)

            //console.log(result);
            //setAgentid(result.data[0].agentId);
            //setCoin(result.data[0].coin);
            //setFixLimit(result.data[0].fixLimit);
            //setCurrentTime(result.data[0].timeCurr);



         }

         ).catch(e => {
            //setIsError(true);
         });

   }, [])

   return (
      <React.Fragment>


         <div _nghost-vsy-c4>
            <div _ngcontent-vsy-c4 className="animated fadeIn divider-top">
               <div _ngcontent-vsy-c4 className="row">
                  <div _ngcontent-vsy-c4 className="col-sm-12 col-md-12 p-mobile-1">
                     <div _ngcontent-vsy-c4 className="card card-accent-primary">
                        <div _ngcontent-vsy-c4 className="card-header account-detail-head"> Account Statement </div>
                        <div _ngcontent-vsy-c4 className="card-body account-stat-body">
                           <div _ngcontent-vsy-c4 className="row">
                              <div _ngcontent-vsy-c4 className="col-md-12 p-mobile-0">
                                 <div _ngcontent-vsy-c4 className="table-responsive">
                                    <div id="DataTables_Table_1_wrapper" className="dataTables_wrapper no-footer">

                                       <table
                                          _ngcontent-vsy-c4 className="table table-bordered dataTable no-footer" datatable id="DataTables_Table_1" role="grid" aria-describedby="DataTables_Table_1_info">
                                          <thead _ngcontent-vsy-c4>
                                             <tr _ngcontent-vsy-c4 className="text-center" role="row">
                                                <th _ngcontent-vsy-c4 className="sorting_desc" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Date/Time: activate to sort column ascending" aria-sort="descending">Date/Time</th>
                                                <th _ngcontent-vsy-c4 className="sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Deposit: activate to sort column ascending">Deposit</th>
                                                <th _ngcontent-vsy-c4 className="sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Withdraw: activate to sort column ascending">Withdraw</th>
                                                <th _ngcontent-vsy-c4 className="sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Balance: activate to sort column ascending">Balance</th>
                                                <th _ngcontent-vsy-c4 className="sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Remark: activate to sort column ascending">Remark</th>
                                                <th _ngcontent-vsy-c4 className="sorting_disabled" rowSpan={1} colSpan={1} aria-label="From/To">From/To</th>
                                             </tr>
                                          </thead>{/**/}{/**/}
                                          <tbody _ngcontent-vsy-c4>
                                             {accountStatement.map(function (item, index) {

                                                var obj1;
                                                var obj2;
                                                if (item.coin >= 0) {
                                                   obj1 = parseFloat(item.coin).toFixed(2);
                                                   obj2 = '-';
                                                }
                                                if (item.coin < 0) {
                                                   obj1 = '-';
                                                   obj2 = parseFloat(item.coin).toFixed(2);
                                                }
                                                var obj3 = parseFloat(item.creditLimit).toFixed(2);

                                                return (<tr _ngcontent-vsy-c3="" key={index} class="text-center">
                                                   <td _ngcontent-vsy-c3=""> {item.currTime}</td>
                                                   <td _ngcontent-vsy-c3=""> {obj1}</td>
                                                   <td _ngcontent-vsy-c3=""> {obj2}</td>
                                                   <td _ngcontent-vsy-c3="">{obj3} </td>
                                                   <td _ngcontent-vsy-c3="">
                                                      <span _ngcontent-vsy-c3="" class="fw-b lay-text">
                                                         <span _ngcontent-vsy-c3=""></span></span></td>
                                                   <td _ngcontent-vsy-c3=""> {item.agentId}</td>
                                                </tr>
                                                )
                                             })}
                                             
                                          </tbody>
                                       </table>
                                       <div className="dataTables_info" id="DataTables_Table_1_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                       <div className="dataTables_paginate paging_full_numbers" id="DataTables_Table_1_paginate">
                                          <a className="paginate_button first disabled" aria-controls="DataTables_Table_1" data-dt-idx={0} tabIndex={0} id="DataTables_Table_1_first">First</a><a className="paginate_button previous disabled" aria-controls="DataTables_Table_1"
                                             data-dt-idx={1} tabIndex={0} id="DataTables_Table_1_previous">Previous</a>
                                          <span />
                                          <a className="paginate_button next disabled" aria-controls="DataTables_Table_1" data-dt-idx={2} tabIndex={0} id="DataTables_Table_1_next">Next</a>
                                          <a
                                             className="paginate_button last disabled" aria-controls="DataTables_Table_1" data-dt-idx={3} tabIndex={0} id="DataTables_Table_1_last">Last</a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* <div _ngcontent-lst-c67="" class="centar-content report" >





            <div _ngcontent-lst-c67="" id="message" class="message-content success">
               <a _ngcontent-lst-c67="" class="close-button">Close</a>

            </div>

            <h2 _ngcontent-lst-c67="" >Account Statement</h2>

            <table _ngcontent-lst-c67="" id="table_log" class="table01">
               <tbody><tr>
                  <th _ngcontent-lst-c67="" width="15%" class="align-L">Date/Time</th>
                  <th _ngcontent-lst-c67="" width="18%">Deposit</th>
                  <th _ngcontent-lst-c67="" width="18%">Withdraw</th>
                  <th _ngcontent-lst-c67="" width="18%">Balance</th>
                  <th _ngcontent-lst-c67="" width="16%">Remark</th>
                  <th _ngcontent-lst-c67="" width="">From/To</th>
               </tr>

               </tbody>
               <tbody _ngcontent-lst-c67="" id="content">
                  {accountStatement.map(function (item, index) {

                     var obj1;
                     var obj2;
                     if (item.coin >= 0) {
                        obj1 = parseFloat(item.coin).toFixed(2);
                        obj2 = '-';
                     }
                     if (item.coin < 0) {
                        obj1 = '-';
                        obj2 = parseFloat(item.coin).toFixed(2);
                     }
                     var obj3 = parseFloat(item.creditLimit).toFixed(2);

                     return (
                        <tr _ngcontent-lst-c67="" id="tempTr" key={index}>
                           <td _ngcontent-lst-c67="" id="createDate" class="align-L">{item.currTime}</td>
                           <td _ngcontent-lst-c67="" id="deposit"> {obj1}</td>
                           <td _ngcontent-lst-c67="" id="withdraw">{obj2}</td>
                           <td _ngcontent-lst-c67="" id="balance"> {obj3}</td>
                           <td _ngcontent-lst-c67="" id="remark"></td>
                           <td _ngcontent-lst-c67="" >
                              <spen _ngcontent-lst-c67="" id="from">Master Agent</spen>
                              <img _ngcontent-lst-c67="" class="fromto" src={Transparent} />
                              <spen _ngcontent-lst-c67="" id="to">{item.agentId}</spen>
                           </td>
                        </tr>
                     )
                  })}
               </tbody>
            </table>

            <div _ngcontent-lst-c67="" >

               <ul _ngcontent-lst-c67="" id="pageNumberContent" class="pages">
                  <li _ngcontent-lst-c67="" id="prev">
                     <a _ngcontent-lst-c67="" href="javascript:void(0);" class="disable" style={{ pointerEvents: "none" }}>Prev</a></li>
                  <li _ngcontent-lst-c67="" id="pageNumber">
                     <a _ngcontent-lst-c67="" href="javascript:void(0);" class="select" style={{ pointerEvents: "none" }}>1</a></li>
                  <li _ngcontent-lst-c67="" id="next"><a href="javascript:void(0);" class="disable" style={{ pointerEvents: "none" }}>Next</a></li></ul>
            </div>
         </div> */}
      </React.Fragment>
   )
}
