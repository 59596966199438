import React, { useState, useEffect, memo } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from "react-router-dom";

// react query
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import { queryClient } from '../serives/react-query/provider';
import { getGameBalanceApiRq } from '../serives/react-query/api-actions';
import LoadingOverlay from "./components/loading-overlay/loading-overlay";

const cookies = new Cookies();

const CasinoModal = (propp) => {


  // console.log(props.provider,props.ProviderName);
  const [casinoAmount, setcasinoAmount] = useState('');
  const [getcasino, setgetcasino] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gamePlayUrl, setGamePlayUrl] = useState('');
  const [error, setError] = useState('');

  const history = useHistory();

  const props = propp.match.params;
  console.log('prop', props)
  console.log(props.provider, ' v',);
  // const Opengame = ()=>{
  //     if (!props.isLoggedIn) {
  //         props.checkShowLogin(true);
  //         return;
  //     }
  // else
  // {
  //     var utoken = cookies.get('token');
  //     var password = cookies.get('password');
  //     var user = cookies.get('id');
  //     var mil =  Date.now();

  //     if(!user || !utoken){
  //       return;
  //     }

  //     props.setcasinoWait(true);
  //     console.log(props.providertype);
  //     if(props.providertype ==='SL'){
  // axios.post('https://liveapi247.live/api1/getGameApi',{
  //     username:user,
  //  //  token:utoken,
  //    platform:'GPL_DESKTOP',
  //    "providercode": props.openProvider,
  //    "password": password,
  //    "type": props.providertype,
  //    lobby_url: window.location.href,
  //    gameid: props.gameCode
  //  })
  //      .then(result => {
  //         setTimeout(()=>{props.setcasinoWait(false);},4000);
  //       if(result.data.data.game_url){
  //         console.log(result.data.data.game_url);
  //        // window.open(result.data);
  //         //var newTab=window.open('about:blank');

  //         // window.location.href=result.data.data.game_url;

  //         // setModal(true);

  //        }
  //      })
  //      .catch(e => {
  //      });
  //     }
  //     else{
  //       axios.post('https://liveapi247.live/api1/getGameApi',{
  //         username:user,
  //      //  token:utoken,
  //        platform:'GPL_DESKTOP',
  //        "providercode": props.openProvider,
  //        "password": password,
  //        "type": props.providertype,
  //        lobby_url: window.location.href,
  //      })
  //      .then(result => {
  //       ///  setTimeout(()=>{props.setcasinoWait(false);},4000);
  //       console.log(result.data.data.game_url);
  //       if(result.data.data.game_url){
  //        // window.open(result.data);
  //         //var newTab=window.open('about:blank');

  //         // window.location.href=result.data.data.game_url;
  //           // setModal(true);
  //        }
  //      })
  //      .catch(e => {
  //      });
  //     }
  //    }
  // }


  // const openAESexy =  async()=>{
  //   handleModalCancel()
  //   props.setcasinoWait(true);
  //   //  e.preventDefault()
  //     // set provider code
  //   //  props.updatepropsTransaction(props.openProvider)

  //    localStorage.setItem('transactionCode', props.openProvider)
  //   //  // set modal status
  //    localStorage.setItem('transactionState', true);
  //     // props.settransactionCode(props.openProvider)
  //     // props.settransactionState(true)


  //    if (casinoAmount == 0 || casinoAmount == '') {
  //      Opengame();
  //     incasino(true);
  //       }
  //   else {
  //       if(props.balance >=casinoAmount){
  //       var utoken = cookies.get('token');
  //       var password = cookies.get('password');
  //       var user = cookies.get('id');
  //       if(!user || !utoken){
  //         return;
  //       }
  //      await axios.post('https://liveapi247.live/api1/makeTransfer',{
  //           "username" : user,
  //           "providercode" : props.openProvider,
  //           "password" : password,
  //           "type" : "0",
  //           "amount" : casinoAmount,
  //           "token" :utoken
  //        })
  //        .then( async result => {
  //           if(result.data.status == true){
  //               var provider_code = props.openProvider
  //               var casiono_value = 0;
  //               var casino_name = "";
  //               switch (provider_code) {

  //                   case 'GE':
  //                       casiono_value = props.GEbalance
  //                       casino_name = "ge_credit"
  //                     break;
  //                   case 'S2':
  //                       casiono_value = props.AEBalance
  //                      casino_name = "awc_credit"
  //                     break;
  //                     case 'RE':
  //                       casiono_value = props.REBalance
  //                      casino_name = "kingmaker_credit"
  //                     break;
  //                     case 'JL':
  //                       casiono_value = props.JLBalance
  //                      casino_name = "jili_credit"
  //                     break;
  //                     case 'EZ':
  //                       casiono_value = props.EZBalance
  //                      casino_name = "ezugi_credit"
  //                     break;
  //                 }
  //                 let mainBalance = parseFloat(props.balance).toFixed(2);
  //                 var remaining_balance = parseFloat(mainBalance)  - parseFloat(casinoAmount);
  //                 casiono_value = parseFloat(casiono_value)  + parseFloat(casinoAmount);
  //                 var data = {
  //                     id : user,
  //                     LimitCurr : remaining_balance,
  //                     casino_value : casiono_value,
  //                     casino_type : casino_name,
  //                     token : utoken
  //                 }

  //             await updateBalance(data);
  //           }
  //        })
  //        .catch(e => {
  //        });
  //       }
  //       else{
  //           toast.warn('You Have low blaance contact your agent to update your account!', {position:toast.POSITION.TOP_CENTER});
  //       }
  //      }
  //   }


  // const handleModalCancel = () => {
  //   props.handleCasinoBalfalse();
  // //  handleLoader();
  //   // localStorage.setItem('transactionState', false);
  //   // localStorage.setItem('transactionCode', '')
  // }

  // const updateBalance = async(data)=>{
  //   await  axios.post('https://liveapi247.live/api1/update_balance', data)
  //        .then(result => {
  //         props.setupdated(!props.updated);
  //         props.handleRefresh();
  //         handleModalCancel();
  //         Opengame();
  //        })
  //        .catch(e => {

  //        });
  // }

  //  const handleCasinoAmount = (amount) => {
  //   let value = amount;
  //   //console.log(value+"=="+typeof value);
  //  // console.log(value <= parseInt(localStorage.getItem('mainBalance')),value,localStorage.getItem('mainBalance'));
  //   if( value <= props.balance) {
  //     setcasinoAmount(value)
  //   }
  //    else {
  //     setcasinoAmount(props.balance)
  //     toast.error(`You cannot enter more than ${props.balance}`, {position:toast.POSITION.TOP_CENTER})
  //   }
  // }

  const utoken = cookies.get('token');
  // const password = cookies.get('password');
  const user = cookies.get('id');

  const setErrorMsg = (msg) => {
    setError('Insuficent balance');
    setTimeout(() => {
      setError('');
    }, 8000)
  }


  const getMainBalanceApi = async () => {
    return await axios.post('https://liveapi247.live/api1/refresh_balance_2', {
      id: user,
      token: utoken
    });
  }

  const depositeAndWithdrawAmmont = async ({ transferType, amount, callBack }) => {
    const type = transferType === 'd' ? "0" : transferType === 'w' ? "1" : null;

    await axios.post('https://liveapi247.live/api1/enterCasinoTransaction_2', {
      username: user,
      type,
      providercode: props.provider,
      amount,
      token: utoken,
    }).then(({ status, data }) => {
      if (status !== 200) {
        setErrorMsg(`Failed to ${transferType} balance game`)
      } else {
        if (callBack && data.message === "SUCCESS") {
          callBack();
          setError('');
        } else {
          setErrorMsg(`Failed to ${transferType} balance game`)
        }
      }

    });
  }

  const getGamePlayUrlApi = async () => {
    const gameId = props.providertype === 'SL' ? { gameid: props.gameCode } : {};
    return await axios.post('https://liveapi247.live/api1/getGameApi', {
      ...gameId,
      lobby_url: window.location.href,
      // password,
      platform: "GPL_DESKTOP",
      providercode: props.provider,
      type: props.providertype,
      username: user
    })
  }

  const getGameBalanceApi = async () => {
    return await axios.post('https://liveapi247.live/api1/getBalance', {
      providercode: props.provider,
      username: user,
    })
  }


  // open game
  useEffect(() => {
    // clear cookie
    document.cookie = "reCall=; max-age=0";
    // get main balnce
    getMainBalanceApi().then(blInfo => {
      const getBl = blInfo.data.mainBalance;
      const getExposure = blInfo.data.exposureBalance;
      const mainBalance = getBl ? parseFloat(getBl).toFixed(2) - 0.01 : 0;
      const balanceMinusFromExposure = mainBalance - Math.abs(getExposure);

      // deposite ammount on game
      getGamePlayUrlApi().then((rezult) => {
        const url = rezult.data?.data?.game_url;

        // deposite balance in game
        if (url && balanceMinusFromExposure > 0) {
          depositeAndWithdrawAmmont({
            transferType: "d",
            amount: balanceMinusFromExposure,
            callBack: () => {
              setGamePlayUrl(url);
              setTimeout(() => {
                setLoading(false)
              }, 1500)
            }
          });
        } else {
          setErrorMsg('Insuficent balance');
          setLoading(false)
        }
      });

    });


    // setTimeout(() => {
    //   setLoading(false);
    // }, 12000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoggedIn === true])


  // recall main blance on close
  // const fetchMainBlanceOnCloseModal = async() => {
  //   cookies.set('reCall', 'loading');
  //   // get game blance
  //    await getGameBalanceApi().then(async (rezult)=>{
  //     if(rezult.data.data){
  //       // revart game blance on account
  //       const gameBlance = await rezult.data.data.balance;

  //        await depositeAndWithdrawAmmont({
  //         transferType: "w",
  //         amount: gameBlance,
  //         // refresh blance
  //         callBack: ()=> {
  //            cookies.set('reCall', 'done');
  //            setTimeout(()=> {
  //             document.cookie = "reCall=; max-age=0";
  //            },150)
  //         }
  //       });

  //     }
  //   });
  // }

  // const queryClient = useQueryClient()
  // const getBlanceStatus = queryClient.getQueryData({ queryKey: ['getBlanceStatus'] });

  // useEffect(()=>{
  //   console.log('blanceData', getBlanceStatus)
  // },[getBlanceStatus])

  // const getCasinoBlance = useMutation({
  //   mutationFn: getGameBalanceApiRq,
  //   onSuccess: ()=> data => {
  //     queryClient.setQueryData(['getBlanceStatus'], data);
  //     console.log('getCasinoBlance success')
  //   }
  // })

  // const fetchMainBlanceOnCloseModal = async() => {
  //   cookies.set('reCall', 'loading');
  //   // get game blance
  //  getCasinoBlance.mutate({providercode: props.provider,
  //     username: user})
  // }
  //   // recall main balance on unmount
  //   useEffect(()=> {
  //     return ()=> {
  //       fetchMainBlanceOnCloseModal();
  //     }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   },[props]);


  // on close modal recall balance

  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser)
  //   window.addEventListener('unload', handleTabClosing)
  //   return () => {
  //       window.removeEventListener('beforeunload', alertUser)
  //       window.removeEventListener('unload', handleTabClosing)
  //   }
  // })

  // const handleTabClosing = async() => {
  //    await fetchMainBlanceOnCloseModal();

  //   alert('exit')
  // }

  // const alertUser = (event) => {
  //   event.preventDefault()
  //   event.returnValue = ''
  // }




  // close modal if your not logged in
  // useEffect(()=> {
  //   if(!props.isLoggedIn){
  //     history.push('/')
  //   }
  // },[props.isLoggedIn]);



  const header = () => (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#000000',
      padding: '0.5rem'
    }}>
      <div>
        <h4 style={{ color: 'white' }}>{props?.providerName}</h4>
      </div>
      <div>
        <button
          style={{
            color: 'white',
            backgroundColor: 'transparent',
            // right: 0,
            // position: 'absolute',
            // padding: '0  0 0.1rem 0.3rem',
            zIndex: 2,
            // borderRadius: '0 0 0 0.8rem',
            cursor: 'pointer',
            border: 0,
          }}
          onClick={() => history.push('/')}
        >
          <CloseIcon style={{ fontSize: '1.7rem', backgroundColor: 'red', }} />
        </button>
      </div>
    </div>
  )


  return (
    <div
      style={{
        height: '100vh', width: '100%',
        position: 'absolute'
      }}
    >
      {header()}
      <div
        style={{
          width: '100%',
          height: '100%',
          // position: 'absolute',
          // left: 0,
          // top: 0,
          backgroundColor: 'black'
        }}
      >

        {error && <h6 style={{ color: 'red', paddingTop: '0.8rem', textAlign: 'center' }}>{error}</h6>}

        <LoadingOverlay
            style={{
              height: '100vh', width: '100%', transform: 'translate(0, 0)', top: 0
            }}
            show={loading}
        />

        {/*{loading && (*/}
        {/*  <div style={{ zIndex: 2, backgroundColor: 'black', height: '100vh', width: '100%', transform: 'translate(0, 0)', position: 'relative', top: 0 }} _ngcontent-njs-c67="" class="loading-overlay" id="loading">*/}
        {/*    <div _ngcontent-njs-c67="" class="loading-wrap" style={{ display: " flex" }}>*/}
        {/*      <div _ngcontent-njs-c67="" class="loading">*/}
        {/*        <div></div>*/}
        {/*        <div></div>*/}
        {/*      </div>*/}
        {/*      <p>Loading...</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

        {gamePlayUrl && (
          <iframe
            autoPlay=""
            preload=""
            playsInline=""
            allowFullScreen={true}
            title="mob-live-stream"
            src={gamePlayUrl}
            sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
            style={{
              height: '100%',
              width: '100%',
              border: '0px',
              position: 'relative',
              zIndex: 1
            }}
          ></iframe>
        )}

      </div>


    </div>

  )
}

export default memo(CasinoModal);
