import React, { useState, useEffect, memo } from 'react'
import Axios from 'axios';
// import liveImg from '../../../images/sprite/cover-live.png';
import Transparent from '../../../images/sprite/transparent.gif';
import moment from 'moment';

const Tvstream = (props) => {
    const url = window.location.href;
    const para = url.split('/');
    const eventT = para[6];
    const eventid = para[7];
    const [ChannelId, setChannelId] = useState(null);
    const [ScoreData, setScoreData] = useState()
    const [ScoreId, setScoreId] = useState('')
    const [livemarpin, setlivemarpin] = useState(false)
    const [chId, setchId] = useState(null);


    const handlelivempin = () => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        } else {
            setlivemarpin(!livemarpin)
        }
    }

    useEffect(() => {
        async function fetchData() {

            if (!eventid) {
                props.changeLiveStreaming(null);
                // props.setliveStream(null);
                return;
            }
            await Axios.post("https://liveapi247.live/api1/livestreaming", {
                eventid: eventid,
            })
                .then((result) => {
                    if (result.status === 210) {

                        if (result.data[0].tvId && result.data[0].tvId != '') {
                            props.changeLiveStreaming(result.data[0].tvId);
                            setScoreData(result.data[0].scoreId);
                        }
                    }
                })
                .catch((e) => {
                    //setIsError(true);
                });
            if (!ScoreData || !ChannelId || !chId) {
                await Axios.post("https://liveapi247.com/api1/livestreaming", {
                    eventid: eventid,
                })
                    .then(result => {
                        if (result.status === 210) {
                            setScoreId(result.data[0].scoreId);
                            setchId(result.data[0].tvId2);
                        }
                    })
                    .catch((e) => {
                        //setIsError(true);
                    });
            }



            if (!eventid) return;
            await Axios.get(`https://lt-fn-cdn001.akamaized.net/techablesoftware/en/Etc:UTC/gismo/match_info/m` + eventid).then((result) => {
                var MatchId = result.data.queryUrl;
                var str = MatchId;
                var matches = str.match(/(\d+)/);
                setScoreId(matches[0]);

            }).catch((err) => {
                // console.log(err);
            })

            if (!eventid) return;
            await Axios.post('https://liveapi247.live/api1/getTvId', {
                eid: eventid
            })
                .then(result => {

                    if (result.status === 200) {
                        setChannelId(result.data.data.cid);
                    }
                }
                ).catch(e => {

                });

        }
        fetchData();
    }, [eventid]);
    let multiChannelId = chId != "" ? chId : ChannelId;

    return (
        <>
            {props.Tabcricscore === 1 && multiChannelId !== null && props.Tvopen && moment() >= moment(props.marketStartTime) && props.isLoggedIn === true &&

                <>

                    <div className="tv-compit">



                        <iframe style={{ height: '57vw', width: '100%', border: 0, zIndex: 100, position: 'absolute' }}
                            autoPlay="" preload="" playsinline=""
                            title="mob-live-stream"
                            allowFullScreen={false}
                            src={eventT == 0 ? `https://ss247.info/api/Nstream/2/${multiChannelId}` : `https://ss247.life/api/77b9132c6a19eece06105a0eed6d82a9ab716445/Nstreamapi.php?chid=${multiChannelId}`}
                            // src={props.liveStream}
                            // src={
                            //     thandler[0].T_Mode == 1 && thandler[0].S_discard == 0 ?
                            //         thandler[0].Source1 + multiChannelId + thandler[0].Source2 + getmyIPaddress
                            //         :
                            //         thandler[0].T_Mode == 1 && thandler[0].S_discard == 1 ?
                            //             thandler[0].Source2 + getmyIPaddress
                            //             :
                            //             thandler[0].Source1 + multiChannelId
                            // }
                            sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                        </iframe>

                    </div>

                </>
            }
            {/* iframe tv end  */}
            {
                props.Tabcricscore === 0 && eventT != '2378962' &&
                <div _ngcontent-njs-c67="" id="matchTrackerWrap" className="match-tracker-wrap" >
                    {(ScoreData && ScoreData !== undefined) && ScoreData != eventid ? (
                        <iframe style={{ height: '180px', width: '100%', border: 0 }}
                            autoPlay="" preload="" playsinline=""
                            title="mob-live-stream"
                            allowFullScreen={false}
                            src={`https://www.satsports.net/score_widget/index.html?id=${ScoreData}`}
                            sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                        </iframe>
                    ) : ScoreId &&
                    (<iframe style={{ height: '182px', width: '100%', border: 0 }}
                        autoPlay="" preload="" playsinline=""
                        title="mob-live-stream"
                        allowFullScreen={false}
                        src={`https://www.satsports.net/score_widget/index.html?id=${ScoreId}`}
                        sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                    </iframe>)}


                </div>
            }


        </>
    )
}

export default memo(Tvstream)
