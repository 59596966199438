import React from 'react'

const Sidemenu = () => {
    return (
        <React.Fragment>
            <div _ngcontent-dde-c0="" className="sidebar">
                <nav _ngcontent-dde-c0="" className="sidebar-nav ps ps--active-y">
                    <div _ngcontent-dde-c0="" className="top-head-sport"><a _ngcontent-dde-c0="" className="path-back"><i _ngcontent-dde-c0="" aria-hidden="true" className="fa fa-ellipsis-v" /></a>
                        <ul _ngcontent-dde-c0="" className="text-right mr-2">
                            <li _ngcontent-dde-c0=""><a _ngcontent-dde-c0="">Sports</a></li>
                        </ul>
                    </div>
                    <ul _ngcontent-dde-c0="" className="nav">{/**/}{/**/}
                        <div _ngcontent-dde-c0="">{/**/}
                            <li _ngcontent-dde-c0="" appnavdropdown className="nav-item nav-dropdown"><a _ngcontent-dde-c0="" appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Cricket </a></li>
                            <li _ngcontent-dde-c0="" appnavdropdown className="nav-item nav-dropdown"><a _ngcontent-dde-c0="" appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Tennis </a></li>
                            <li _ngcontent-dde-c0="" appnavdropdown className="nav-item nav-dropdown"><a _ngcontent-dde-c0="" appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Casino </a></li>
                            <li _ngcontent-dde-c0="" appnavdropdown className="nav-item nav-dropdown"><a _ngcontent-dde-c0="" appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Soccer </a></li>
                            <li _ngcontent-dde-c0="" appnavdropdown className="nav-item nav-dropdown"><a _ngcontent-dde-c0="" appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Horse Racing </a></li>
                            <li _ngcontent-dde-c0="" appnavdropdown className="nav-item nav-dropdown"><a _ngcontent-dde-c0="" appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Virtual Sports </a></li>
                            <li _ngcontent-dde-c0="" appnavdropdown className="nav-item nav-dropdown"><a _ngcontent-dde-c0="" appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Basketball </a></li>
                        </div>
                    </ul>
                    <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                        <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} /></div>
                    <div className="ps__rail-y" style={{ top: '0px', height: '681px', right: '0px' }}>
                        <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '656px' }} /></div>
                </nav>
            </div>
        </React.Fragment>
    )
}

export default Sidemenu
