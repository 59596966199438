import React from 'react'
// import Cup from '../images/sprite/cup.gif'
import Transparent from '../images/sprite/transparent.gif'
import { Link } from "react-router-dom";
import { Homeannounce } from './Homeannounce'
// import Promot from '../images/sprite/promot.gif'
import home from '../images/Icons/home.svg'
import timer from '../images/Icons/timer.svg'
import trophy from '../images/Icons/trophy.svg'
import pinpush from '../images/Icons/pinpush.svg'
import user from '../images/Icons/user.svg'
export default function Footer(props) {





	return (
		<div _ngcontent-njs-c65="" >
			{/* <nav _ngcontent-njs-c65="" >
				<ul _ngcontent-njs-c65="" >
					<li _ngcontent-njs-c65=""  id="sports">
						<a _ngcontent-njs-c65=""  href='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img _ngcontent-njs-c65=""  className="icon-sports" src={Cup} alt="gif" />Sports
						</a></li>
					<li _ngcontent-njs-c65=""  id="inPlay">
						<a _ngcontent-njs-c65=""  href='/d/dash/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img _ngcontent-njs-c65=""  className="icon-inplay" src={Cup} alt="gif" />In-Play
						</a></li>
					<li _ngcontent-njs-c65=""  id="home" className={`main-nav ${(props.tabMenu === 3) ? "select" : "null"}`}>
						<a _ngcontent-njs-c65=""  href='/' className="ui-link">
							<img _ngcontent-njs-c65=""  className="icon-home" src={Cup} alt="gif" />Home</a></li>
					<li _ngcontent-njs-c65=""  id="multiMarket">
						<a _ngcontent-njs-c65="" href='/d/dash/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img className="icon-pin" src={Cup} alt="gif" />Multi Markets
						</a></li>
					<li _ngcontent-njs-c65=""  id="account">
						{props.isLoggedIn && <Link to='/d/dash/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>< img className="icon-account" src={Cup} alt="gif" />Account</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link"><img className="icon-account" src={Cup} alt="gif" />Account</a>}
					</li>
				</ul>
			</nav> */}
			<nav>
				{/* <Homeannounce /> */}
				{/* <ul class="mini-lobby">

					<li id="mini" class="main-nav">
						<Link href="/" style={{ paddingTop: '4px' }} class="ui-link">

						</Link>
					</li>



					<li id="sports">
						<Link _ngcontent-njs-c65="" to='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img class="icon-sports" src={Transparent} />Sports
						</Link></li>


					<li id="inPlay">
						<Link _ngcontent-njs-c65="" to='/d/dash/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img class="icon-inplay" src={Transparent} />In-Play
						</Link></li>



					<li _ngcontent-njs-c65="" id="home">
						<Link _ngcontent-njs-c65="" to='/' className={`ui-link ${(props.tabMenu === 3) ? "select" : "null"}`}>
							<img class="icon-home" src={Transparent} />Home
						</Link></li>



					<li id="multiMarket">
						<Link _ngcontent-njs-c65="" to='/d/dash/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img class="icon-pin" src={Transparent} />Multi Markets
						</Link></li>
					<li id="account">
						{props.isLoggedIn && <Link to='/d/dash/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>
							<img class="icon-account" src={Transparent} />Account
						</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">	<img class="icon-account" src={Transparent} />Account</a>}
					</li>
				</ul> */}
				<nav _ngcontent-bya-c0="" className="mobile-footer-menu">
					<ul _ngcontent-bya-c0="">
						<li _ngcontent-bya-c0="">
							<a _ngcontent-bya-c0="" href='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "active" : "null"}`}  >
								<img _ngcontent-bya-c0="" className="icon-home" src={home} />Home</a>
						</li>
						<li _ngcontent-bya-c0="">
							<a _ngcontent-bya-c0="" href='/d/dash/inplay' className={`ui-link ${(props.tabMenu === 2) ? "active" : "null"}`} >
								<img _ngcontent-bya-c0="" className="icon-inplay" src={timer} />In-Play</a></li>
						<li _ngcontent-bya-c0="" className="main-nav">
							<a _ngcontent-bya-c0="" href='/d/dash/leftsport' className={`ui-link ${(props.tabMenu === 19) ? "active" : "null"}`}>
								<img _ngcontent-bya-c0="" className="icon-sports" src={trophy} />Sports</a>
						</li>
						<li _ngcontent-bya-c0="">
							<a _ngcontent-bya-c0="" className={`ui-link ${(props.tabMenu === 4) ? "active" : "null"}`} href='/d/dash/multimarket'>
								<img _ngcontent-bya-c0="" className="icon-pin" src={pinpush} />Multi Markets</a></li>
						<li _ngcontent-bya-c0="">
							{props.isLoggedIn && <Link _ngcontent-bya-c0="" to='/d/dash/myaccount' className="ui-link" >
								<img _ngcontent-bya-c0="" className="icon-account" src={user} />Account</Link>}
							{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} _ngcontent-bya-c0="" className="ui-link" >
								<img _ngcontent-bya-c0="" className="icon-account" src={user} />Account</a>}
						</li>
					</ul>
				</nav>
			</nav>
		</div>
	)
}
