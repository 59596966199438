
import React, { useEffect, useState, memo } from 'react'
import ConfirmBet from './ConfirmBet'
import TVStremaing from './TVStremaing';
import FancyBook from './Fancybook'
import Axios from 'axios';
import Transparent from '../images/sprite/transparent.gif';
import moment from 'moment';
import Cookies from 'universal-cookie';
// import liveImg from '../images/sprite/cover-live.png';
import { marketKeys, soketIoMarketKey, soketEndPoint, checkStrVsStr } from './utils';

// components
import Tvstream from './components/Livetvscore/Tvstream';
import RunnerHeader from './components/runner-header/runner-header';
import RunnerRow from './components/runner-row/runner-row';
import PlaceBetKeybord from './components/place-bet-keybord/place-bet-keybord';
import LoadingOverlay from './components/loading-overlay/loading-overlay';
import { useMutation } from "@tanstack/react-query";
import { refreshBalanceApiRq } from "../serives/react-query/api-actions";
import { queryClient } from "../serives/react-query/provider";

require('moment-timezone');

const cookies = new Cookies();
var matchEndedMarket = false;
var matchEndedBook = false;
var matchEndedManual = false;
var matchEndedComMatch = false;
var matchEndedSuperMatch = false;
var matchEndedTieMatch = false;

cookies.set('dupBet', "2", { path: '/' });


const io = require('socket.io-client');

const ranNum = Math.floor(Math.random() * 4) + 1;

var socket;

var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0, c13 = 0, c14 = 0, c15 = 0, c16 = 0, c17 = 0, c18 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30, s31, s32, s33, s34, s35, s36;

export const Newfullmarket = (props) => {

    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [ssstake7, setSsstake7] = useState(0);
    const [ssstake8, setSsstake8] = useState(0);
    const [Maxstake, setMaxstake] = useState(false)
    const [Bookstake, setBookstake] = useState(false)
    const [Fancypin, setFancypin] = useState(false)
    const [bookpin, setbookpin] = useState(false)
    const [marketData, setmarketData] = useState({});
    const [fancyData, setfancyData] = useState([])
    const [defaultStake, setdefaultStake] = useState('');
    const [dStake, setdStake] = useState('');
    const [selectFancy, setselectFancy] = useState(null)
    const [bookData, setbookData] = useState({})
    const [selectFancyLay, setselectFancyLay] = useState(true)
    const [click, setclick] = useState(-1);
    const [bOdds, setbOdds] = useState(' ')
    const [mOdds, setmOdds] = useState(' ')
    const [Maodds, setMaodds] = useState(' ')
    const [COdds, setCOdds] = useState(' ')
    const [SOdds, setSOdds] = useState(' ')
    const [TOdds, setTOdds] = useState(' ')
    const [oddsType, setoddsType] = useState('Back')
    const [teamName, setteamName] = useState('A')
    const [bookData2, setbookData2] = useState({})
    const [field, setfield] = useState(0);

    const [MatchoddsData, setMatchoddsData] = useState({});

    const [matchOddClickKeybordRunner, setMatchOddClickKeybordRunner] = useState(null);
    const [selectMatchBack, setselectMatchBack] = useState(null)
    const [selectBack, setselectBack] = useState(null)
    const [selectLay, setselectLay] = useState(true)


    const [showConfirmBet, setshowConfirmBet] = useState(false)
    const [showFancyBook, setshowFancyBook] = useState(false)
    const [fancySelection, setfancySelection] = useState(null)
    const [fancyRunner, setfancyRunner] = useState(null)

    const [Manualclick, setManualclick] = useState(false)
    const [Manualclick2, setManualclick2] = useState(false)
    const [Manualclick3, setManualclick3] = useState(false)
    const [SuperMatchclick, setSuperMatchclick] = useState(false)
    const [SuperMatchclick2, setSuperMatchclick2] = useState(false)
    const [SuperMatchclick3, setSuperMatchclick3] = useState(false)
    const [ComMatchclick, setComMatchclick] = useState(false)
    const [ComMatchclick2, setComMatchclick2] = useState(false)
    const [ComMatchclick3, setComMatchclick3] = useState(false)
    const [tieMatchClickKeybordRunner, setTieMatchClickKeybordRunner] = useState(null);
    const [comMatchClickKeybordRunner, setComMatchClickKeybordRunner] = useState(null);
    const [superMatchClickKeybordRunner, setSuperMatchClickKeybordRunner] = useState(null);
    const [WintossMatchClickKeybordRunner, setWintossMatchClickKeybordRunner] = useState(null);
    const [bookdataMatchClickKeybordRunner, setBookdataMatchClickKeybordRunner] = useState(null);
    const [selectManualBack, setselectManualBack] = useState(null)
    const [selectSuperMatchBack, setselectSuperMatchBack] = useState(null)
    const [selectCompMatchBack, setselectCompMatchBack] = useState(null)
    const [selectTieMatchBack, setselectTieMatchBack] = useState(null)
    const [Tabselect, settabselect] = useState(false)
    const [Tabcricscore, settabcricscore] = useState(0)

    const [showTieConfirmBet, setshowTieConfirmBet] = useState(false)
    const [showComConfirmBet, setshowComConfirmBet] = useState(false)
    const [showSupConfirmBet, setshowSupConfirmBet] = useState(false)
    const [showTossConfirmBet, setshowTossConfirmBet] = useState(false)

    const [pnlManual2, setpnlManual2] = useState({})
    const [pnlManual, setpnlManual] = useState({})

    const [pnlSuperMatch2, setpnlSuperMatch2] = useState({})
    const [pnlSuperMatch, setpnlSuperMatch] = useState({})
    const [pnlCompMatch2, setpnlCompMatch2] = useState({})
    const [pnlCompMatch, setpnlCompMatch] = useState({})
    const [pnlTieMatch2, setpnlTieMatch2] = useState({})
    const [pnlTieMatch, setpnlTieMatch] = useState({})
    const [sportsbook, setsportsbook] = useState(null)

    const [ManualData, setManualData] = useState({});
    const [Manualdata2, setManualdata2] = useState({})
    const [SuperMatchData, setSuperMatchData] = useState({});
    const [SuperMatchdata2, setSuperMatchdata2] = useState({})
    const [TieMatchData, setTieMatchData] = useState({});
    const [TieMatchdata2, setTieMatchdata2] = useState({})
    const [CompMatchData, setCompMatchData] = useState({});
    const [CompMatchdata2, setCompMatchdata2] = useState({})
    const [getOdds, setgetOdds] = useState([])
    const [fOdds, setfOdds] = useState(' ')
    const [fsize, setfsize] = useState(' ')
    const [acceptAnyOdds, setacceptAnyOdds] = useState(false)
    const [fancybettabtype, setfancybettabtype] = useState(1)
    const [fancybettabtypePrimium, setfancybettabtypePrimium] = useState(1)


    const [Tierunner1BackRate1, setTierunner1BackRate1] = useState(' ');
    const [Tierunner1BackSize1, setTierunner1BackSize1] = useState(' ');
    const [Tierunner1LayRate1, setTierunner1LayRate1] = useState(' ');
    const [Tierunner1LaySize1, setTierunner1LaySize1] = useState(' ');
    const [Tierunner2BackRate1, setTierunner2BackRate1] = useState(' ');
    const [Tierunner2LayRate1, setTierunner2LayRate1] = useState(' ');
    const [Tierunner2BackSize1, setTierunner2BackSize1] = useState(' ');
    const [Tierunner2LaySize1, setTierunner2LaySize1] = useState(' ');

    const [Suprunner1BackRate1, setSuprunner1BackRate1] = useState(' ');
    const [Suprunner1BackSize1, setSuprunner1BackSize1] = useState(' ');
    const [Suprunner1LayRate1, setSuprunner1LayRate1] = useState(' ');
    const [Suprunner1LaySize1, setSuprunner1LaySize1] = useState(' ');
    const [Suprunner2BackRate1, setSuprunner2BackRate1] = useState(' ');
    const [Suprunner2LayRate1, setSuprunner2LayRate1] = useState(' ');
    const [Suprunner2BackSize1, setSuprunner2BackSize1] = useState(' ');
    const [Suprunner2LaySize1, setSuprunner2LaySize1] = useState(' ');
    const [SupmarketBetStatus, setSupmarketBetStatus] = useState(0);

    const [Comrunner1BackRate1, setComrunner1BackRate1] = useState(' ');
    const [Comrunner1BackSize1, setComrunner1BackSize1] = useState(' ');
    const [Comrunner1LayRate1, setComrunner1LayRate1] = useState(' ');
    const [Comrunner1LaySize1, setComrunner1LaySize1] = useState(' ');
    const [Comrunner2BackRate1, setComrunner2BackRate1] = useState(' ');
    const [Comrunner2LayRate1, setComrunner2LayRate1] = useState(' ');
    const [Comrunner2BackSize1, setComrunner2BackSize1] = useState(' ');
    const [Comrunner2LaySize1, setComrunner2LaySize1] = useState(' ');
    const [CommarketBetStatus, setCommarketBetStatus] = useState(0);
    const [TossBetstatus, setTossBetstatus] = useState(0);

    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');

    //    to win toss
    const [tossrunner1BackRate251, settossrunner1BackRate251] = useState(' ');
    const [tossrunner1BackSize251, settossrunner1BackSize251] = useState(' ');
    const [tossrunner1LayRate251, settossrunner1LayRate251] = useState(' ');
    const [tossrunner1LaySize251, settossrunner1LaySize251] = useState(' ');
    const [tossrunner2BackRate251, settossrunner2BackRate251] = useState(' ');
    const [tossrunner2BackSize251, settossrunner2BackSize251] = useState(' ');
    const [tossrunner2LayRate251, settossrunner2LayRate251] = useState(' ');
    const [tossrunner2LaySize251, settossrunner2LaySize251] = useState(' ');

    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [pnlMarket, setpnlMarket] = useState({})
    const [pnlBook, setpnlBook] = useState({})
    const [pnlFancy, setpnlFancy] = useState([])
    const [totalStake, settotalStake] = useState('');
    const [pnlMarket2, setpnlMarket2] = useState({})
    const [pnlBook2, setpnlBook2] = useState({})
    const [ScoreData, setScoreData] = useState()
    const [hidematchOdds, sethidematchOdds] = useState({})
    const [newrefresh, setnewrefresh] = useState(true);
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [liquidityStatus, setliquidityStatus] = useState(0);
    const [fancyStakeInfo, setfancyStakeInfo] = useState(-1);
    const [coinFactor, setcoinFactor] = useState(1);
    const [onclickPremium, setonclickPremium] = useState(false)
    const [highlightTab, sethighlightTab] = useState(marketKeys.matchOdds);
    const [TiemarketBetStatus, setTiemarketBetStatus] = useState(0);
    const [loading, setloading] = useState(false);
    const [marketStartTime, setmarketStartTime] = useState('')
    const [chId, setchId] = useState(null);
    const [premiumRate, setpremiumRate] = useState([])
    const [Premclick, setPremclick] = useState(-1);
    const [Premclick2, setPremclick2] = useState(-1);
    const [selectPremLay, setselectPremLay] = useState(true);
    const [PreOdds, setPreOdds] = useState(' ')
    const [premiumfield, setpremiumfield] = useState(0);
    const [ChannelId, setChannelId] = useState(null);
    const [StatusSS, setStatusSS] = useState(0);


    // Goals market socket data state
    const [goalsSoccerSocket05Data, setGoalsSoccerSocket05Data] = useState({});
    const [goalsSoccerSocket15Data, setGoalsSoccerSocket15Data] = useState({});
    const [goalsSoccerSocket25Data, setGoalsSoccerSocket25Data] = useState({});
    const [goalsSoccerSocket35Data, setGoalsSoccerSocket35Data] = useState({});
    const [goalsSoccerSocket45Data, setGoalsSoccerSocket45Data] = useState({});


    // Goals market event data state
    const [goalsSoccerApiMarketData, setGoalsSoccerApiMarketData] = useState({});
    const [goalsSoccerApiPlMarketData, setGoalsSoccerApiPlMarketData] = useState({});
    const [goalsSoccerPlMarket2Data, setGoalsSoccerPlMarket2Data] = useState({});

    // Goals market onClick handle
    const [goalsOnKeyBordOn, setGoalsOnKeyBordOn] = useState({});

    // show confirmation
    const [showConfirmGoalsBet, setShowConfirmGoalsBet] = useState(false);

    const matchMarket = {
        matchOdds: marketData,
        bookmaker: bookData2,
        completedMatch: CompMatchData,
        superOver: SuperMatchData,
        tiedMatch: TieMatchData,
        winToss: ManualData,
    }

    // useEffect(() => {
    //     console.log('goalsSoccerApiMarketData', goalsSoccerApiMarketData)
    // }, [goalsSoccerApiMarketData])

    // useEffect(() => {
    //     console.log('goalsSoccerSocket05Data', goalsSoccerSocket05Data);
    //     console.log('goalsSoccerSocket15Data', goalsSoccerSocket15Data);
    //     console.log('goalsSoccerSocket25Data', goalsSoccerSocket25Data);
    //     console.log('goalsSoccerSocket35Data', goalsSoccerSocket35Data);
    //     console.log('goalsSoccerSocket45Data', goalsSoccerSocket45Data);
    //
    // }, [goalsSoccerSocket05Data, goalsSoccerSocket15Data, goalsSoccerSocket25Data, goalsSoccerSocket35Data, goalsSoccerSocket45Data])



    const url = window.location.href;
    const para = url.split('/');

    const eventT = para[6];
    const eventid = para[7];
    const marketid = para[8];

    var matchType = '';

    if (eventT == 4) {
        matchType = 'Cricket';
    }
    else if (eventT == 1) {
        matchType = 'Soccer';
    }
    else if (eventT == 2) {
        matchType = 'Tennis';
    }

    // tvs/core function
    const Openpoup = () => {
        settabselect(!Tabselect)
    }



    useEffect(() => {
        socket = io(soketEndPoint());
        // console.log('socket connected');
        socket.on('connect', (data) => {
            socket.emit('room1', eventid);
        });
        return () => {
            socket.close();
            // console.log('socket disconnected');
            s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;

        }
    }, [])


    useEffect(() => {
        if (!props.userid) return;

        Axios.post('https://liveapi247.live/api1/ssgetclickstake', {
            id: props.userid,
            token: props.utoken,
        }).then(result => {

            if (result.status === 210) {
                setcoinFactor(parseInt(result.data[0].coin_type));
                setdStake(result.data[0].defaultStake);
                setSsstake1(result.data[0].SSstake1);
                setSsstake2(result.data[0].SSstake2);
                setSsstake3(result.data[0].SSstake3);
                setSsstake4(result.data[0].SSstake4);
                setSsstake5(result.data[0].SSstake5);
                setSsstake6(result.data[0].SSstake6);
                setSsstake7(result.data[0].SSstake7);
                setSsstake8(result.data[0].SSstake8);
            }
        })
    }, [props.userid]);


    useEffect(() => {
        async function fetchData() {

            if (!eventid) return;

            let getfullMarketArray = [];
            let getTieMarketArray = [];
            let getComMarketArray = [];
            let getSuperMarketArray = [];
            let getbookMarketArray = [];
            let getclientwintossArray = [];

            await Axios.post('https://liveapi247.live/api1/getEventData', {
                eventId: eventid,
            })
                .then(result => {
                    if (result.data.length > 0) {
                        getfullMarketArray = result.data.filter(x => x.marketName === ("Match Odds" || "Moneyline"))
                        getTieMarketArray = result.data.filter(x => x.marketName === "Tied Match")
                        getComMarketArray = result.data.filter(x => x.marketName === "Completed Match")
                        getSuperMarketArray = result.data.filter(x => x.marketName === "Super Over")
                        getbookMarketArray = result.data.filter(x => x.marketName === "Bookmaker")
                        getclientwintossArray = result.data.filter(x => x.marketName === "To Win the Toss")

                        // soccer data
                        if (eventT == 1) {
                            // filter goal market data
                            result.data.map(eData => {
                                // eslint-disable-next-line no-unused-expressions
                                if (eData.marketName === marketKeys.overUnderGoals('0.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('1.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('2.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('3.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')] = eData
                                }
                                if (eData.marketName === marketKeys.overUnderGoals('4.5')) {
                                    goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')] = eData
                                }
                            })
                        }

                    }
                    //   console.log("getfullMarketArray", getfullMarketArray);
                })


            if ((getfullMarketArray.length > 0 && getfullMarketArray.matchStatus !== 1) || getfullMarketArray.length === 0) {
                matchEndedMarket = true;
                // debugger
            }
            // match odds
            if (getfullMarketArray[0]) {
                let obj = getfullMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setrunner1BackRate1(obj.runner1BackRate1);
                setrunner1LayRate1(obj.runner1LayRate1);
                setrunner2BackRate1(obj.runner2BackRate1);
                setrunner2LayRate1(obj.runner2LayRate1);
                sethidematchOdds(obj.oddsHide)
                if (obj.runnerId3) {
                    settieBackRate1(obj.tieBackRate1);
                    settieLayRate1(obj.tieLayRate1);
                }

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setmarketData(obj);

            }

            // tied match
            if (getTieMarketArray[0]) {
                let obj = getTieMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setTierunner1BackRate1(obj.runner1BackRate1);
                setTierunner1LayRate1(obj.runner1LayRate1);
                setTierunner2BackRate1(obj.runner2BackRate1);
                setTierunner2LayRate1(obj.runner2LayRate1);

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setTieMatchData(obj);
            }

            // complated match
            if (getComMarketArray[0]) {
                let obj = getComMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setComrunner1BackRate1(obj.runner1BackRate1);
                setComrunner1LayRate1(obj.runner1LayRate1);
                setComrunner1LayRate1(obj.runner2BackRate1);
                setComrunner2LayRate1(obj.runner2LayRate1);

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setCompMatchData(obj);
            }

            // super over match
            if (getSuperMarketArray[0]) {
                let obj = getSuperMarketArray[0];
                setmarketStartTime(obj.marketStartTime)
                setSuprunner1BackRate1(obj.runner1BackRate1);
                setSuprunner1LayRate1(obj.runner1LayRate1);
                setSuprunner2BackRate1(obj.runner2BackRate1);
                setSuprunner2LayRate1(obj.runner2LayRate1);


                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setSuperMatchData(obj);
            }


            // Bookmaker
            if (getbookMarketArray.length === 0 || (getbookMarketArray.length > 0 && getbookMarketArray[0].matchStatus != 1)) {
                matchEndedBook = true;
            }
            if (getbookMarketArray[0]) {
                let obj = getbookMarketArray[0];
                setbookData2(obj);
            }

            // to winn toss
            if ((getclientwintossArray.length > 0 && getclientwintossArray[0].matchStatus != 1) || getclientwintossArray.length === 0) {
                matchEndedMarket = true;
            }
            if (getclientwintossArray[0]) {
                let obj = getclientwintossArray[0];
                setmarketStartTime(obj.marketStartTime);
                settossrunner1BackRate251(obj.runner1BackRate1);
                settossrunner1LayRate251(obj.runner1LayRate1);
                settossrunner2BackRate251(obj.runner2BackRate1);
                settossrunner2LayRate251(obj.runner2LayRate1);

                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setManualData(obj);

            }

            if (newrefresh) {
                setnewrefresh(false);
            }
        }
        fetchData()
    }, []);

    if (matchEndedBook && matchEndedMarket && matchEndedTieMatch && matchEndedComMatch) {
        window.location.href = '/';
    }

    useEffect(() => {

        socket.on(eventid, (val) => {

            var value = JSON.parse(val);
            //console.log(value,'dash');
            if (value.messageType === soketIoMarketKey.matchOdds) {
                settotalStake(value.totalMatched);
                setmarketBetStatus(value.status);
                setStatusSS(value.SSstatus);

                if (value.status == '0' && liquidityStatus == 0) {
                    setliquidityStatus(1);
                }
                // r1
                setrunner1BackRate1(value.runner1BackRate1)
                setrunner1BackSize1(value.runner1BackSize1)
                setrunner1LayRate1(value.runner1LayRate1);
                setrunner1LaySize1(value.runner1LaySize1);
                // r2
                setrunner2BackRate1(value.runner2BackRate1);
                setrunner2BackSize1(value.runner2BackSize1);
                setrunner2LayRate1(value.runner2LayRate1);
                setrunner2LaySize1(value.runner2LaySize1);
                // r3
                settieBackRate1(value.tieBackRate1);
                settieBackSize1(value.tieBackSize1);
                settieLayRate1(value.tieLayRate1);
                settieLaySize1(value.tieLaySize1);

            }

            if (value[0] && value[0].messageType === soketIoMarketKey.fancy) {
                let temp = value;
                if (value[0].RateMode == 1) {

                    temp.sort(function (a, b) {

                        return moment(a.selectionId) - moment(b.selectionId);
                    });
                }
                else if (value[0].skymode == 'skyRate') {
                    temp = temp.filter((item, index) => item.gameStatus !== 18)
                    temp.sort(function (a, b) {
                        return a.selectionId - b.selectionId;
                    });
                }
                setfancyData(temp);

            }
            if (value[0] && value[0].messageType === soketIoMarketKey.premium) {
                let temp = value;
                //  console.log(temp);
                if (value[0].messageType === 'premium_Rate') {
                    temp.sort(function (a, b) {
                        return a.marketId - b.marketId;
                    });
                }
                setpremiumRate(temp);


            }

            if (value.messageType === soketIoMarketKey.bookmaker) {

                let temp = { ...bookData };
                temp.messageType = value.messageType;
                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2LayRate1 = value.runner2LayRate1;
                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieLayRate1 = value.tieLayRate1;

                //console.log('book1',value.tieBackRate1,value.tieBackRate1);
                setbookData(temp);

            }
            // Completed_Match_rate

            if (value.messageType === soketIoMarketKey.superOver) {

                setSupmarketBetStatus(value.status);

                // r1
                setSuprunner1BackRate1(value.runner1BackRate1)
                setSuprunner1LayRate1(value.runner1LayRate1)
                setSuprunner1BackSize1(value.runner1BackSize1);
                setSuprunner1LaySize1(value.runner1LaySize1);
                // r2
                setSuprunner2BackRate1(value.runner2BackRate1);
                setSuprunner2LayRate1(value.runner2LayRate1);
                setSuprunner2BackSize1(value.runner2BackSize1);
                setSuprunner2LaySize1(value.runner2LaySize1);

            }

            if (value.messageType === soketIoMarketKey.completedMatch) {

                setCommarketBetStatus(value.status);

                // r1
                setComrunner1BackRate1(value.runner1BackRate1)
                setComrunner1LayRate1(value.runner1LayRate1)
                setComrunner1BackSize1(value.runner1BackSize1);
                setComrunner1LaySize1(value.runner1LaySize1);
                // r2
                setComrunner2BackRate1(value.runner2BackRate1);
                setComrunner2LayRate1(value.runner2LayRate1);
                setComrunner2BackSize1(value.runner2BackSize1);
                setComrunner2LaySize1(value.runner2LaySize1);

            }

            if (value.messageType === soketIoMarketKey.winToss) {

                setTossBetstatus(value.status);

                // r1
                settossrunner1BackRate251(value.runner1BackRate1)
                settossrunner1LayRate251(value.runner1LayRate1)
                settossrunner1BackSize251(value.runner1BackSize1);
                settossrunner1LaySize251(value.runner1LaySize1);
                // r2
                settossrunner2BackRate251(value.runner2BackRate1);
                settossrunner2LayRate251(value.runner2LayRate1);
                settossrunner2BackSize251(value.runner2BackSize1);
                settossrunner2LaySize251(value.runner2LaySize1);

            }

            if (value.messageType === soketIoMarketKey.tiedMatch) {

                setTiemarketBetStatus(value.status);

                // r1
                setTierunner1BackRate1(value.runner1BackRate1)
                setTierunner1LayRate1(value.runner1LayRate1)
                setTierunner1BackSize1(value.runner1BackSize1);
                setTierunner1LaySize1(value.runner1LaySize1);
                // r2
                setTierunner2BackRate1(value.runner2BackRate1);
                setTierunner2LayRate1(value.runner2LayRate1);
                setTierunner2BackSize1(value.runner2BackSize1);
                setTierunner2LaySize1(value.runner2LaySize1);

            }


            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('0.5')]) {
                setGoalsSoccerSocket05Data(value)
            }
            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('1.5')]) {
                setGoalsSoccerSocket15Data(value)
            }
            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('2.5')]) {
                setGoalsSoccerSocket25Data(value)
            }
            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('3.5')]) {
                setGoalsSoccerSocket35Data(value)
            }

            if (value.messageType === soketIoMarketKey[marketKeys.overUnderGoals('4.5')]) {
                setGoalsSoccerSocket45Data(value)
            }

        })

        return () => {
            socket.removeAllListeners(eventid);
            setrunner1BackRate1(' '); setrunner2BackRate1(' ');
            setrunner1LayRate1(' '); setrunner2LayRate1(' ');
            setrunner1BackSize1(' '); setrunner2BackSize1(' ');
            setrunner1LaySize1(' '); setrunner2LaySize1(' ');

            setTierunner1BackRate1(' '); setTierunner2BackRate1(' ');
            setTierunner1LayRate1(' '); setTierunner2LayRate1(' ');
            setTierunner1BackSize1(' '); setTierunner2BackSize1(' ');
            setTierunner1LaySize1(' '); setTierunner2LaySize1(' ');

            setfancyData([]);

            // soccer goal market
            setGoalsSoccerSocket05Data({})
            setGoalsSoccerSocket15Data({})
            setGoalsSoccerSocket25Data({})
            setGoalsSoccerSocket35Data({})
            setGoalsSoccerSocket45Data({})
        }

    }, [])

    // to win toss
    //completematch
    const changeTossBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setWintossMatchClickKeybordRunner(2)

            setteamName('B');
        }
        else if (runner === 1) {

            setWintossMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {

            setWintossMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectManualBack(val1);
        setMaodds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    //completematch
    const changeCompMatchBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setComMatchClickKeybordRunner(2)

            setteamName('B');
        }
        else if (runner === 1) {
            setComMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setComMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectCompMatchBack(val1);
        setCOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    //super overmatch
    const changeSuperMatchBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setSuperMatchClickKeybordRunner(2)
            setteamName('B');
        }
        else if (runner === 1) {
            setSuperMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setSuperMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectSuperMatchBack(val1);
        setSOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    //Tie match
    const changeTieMatchBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setTieMatchClickKeybordRunner(2)
            setteamName('B');
        }
        else if (runner === 1) {

            setTieMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setTieMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectTieMatchBack(val1);
        setTOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }

    const changeBook = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (runner === 2) {
            setBookdataMatchClickKeybordRunner(2)
            setteamName('B');
        }
        else if (runner === 1) {
            setBookdataMatchClickKeybordRunner(1)
            setteamName('A');
        }
        else if (runner === 3) {
            setBookdataMatchClickKeybordRunner(3)
            setteamName('T');
        }
        setselectBack(val1);
        setbOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }

    const changeMatchodds = (val1, runner, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }

        setdefaultStake(dStake);
        if (runner === 2) {
            setteamName('B');
            setMatchOddClickKeybordRunner(2);
        }
        else if (runner === 1) {
            setMatchOddClickKeybordRunner(1);
            setteamName('A');
        }
        else if (runner === 3) {
            setMatchOddClickKeybordRunner(3);
            setteamName('T');
        }

        setselectMatchBack(val1);
        setmOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    // goals market onClick layback
    const onClickGoalMarketLayBack = (isBack, runner, odd, currMarket) => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }

        setGoalsOnKeyBordOn({
            show: true,
            isBack,
            runner,
            runnerName: goalsSoccerApiMarketData[currMarket][`runnerName${runner}`],
            odd,
            currMarket,
            temName: runner === 1 ? 'A' : runner === 2 ? 'B' : ''
        });

        setoddsType(isBack ? 'LAGAI' : 'KHAI');
    }
    const tmpOnCloseGoalMarketLayBack = () => {
        setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, show: false, isBack: null });
        setGoalsSoccerPlMarket2Data({});
    }

    const onCloseGoalMarketLayBack = () => {
        setGoalsOnKeyBordOn({});
        setGoalsSoccerPlMarket2Data({});
        setdefaultStake(dStake);
    }


    const closeMatchoddsBet = () => {
        setselectMatchBack(0);
        setMatchOddClickKeybordRunner(null);
    }
    const closeBookBet = () => {
        setselectBack(0);
        setBookdataMatchClickKeybordRunner(null)

    }


    const closeManualBet = () => {
        setselectManualBack(0);
        setManualclick2(false);
        setManualclick(false);
        setManualclick3(false);
    }
    const closeTieMatchBet = () => {
        setselectTieMatchBack(0);
        setTieMatchClickKeybordRunner(null);
    }
    const closeCompMatchBet = () => {
        setselectCompMatchBack(0);

        setComMatchClickKeybordRunner(null)


    }
    const closeSuperMatchBet = () => {
        setselectSuperMatchBack(0);
        setSuperMatchclick2(false);
        setSuperMatchclick(false);
        setSuperMatchclick3(false);
    }


    const changeDefaultStake = (event) => {
        setdefaultStake(event.target.value);
    }

    useEffect(() => {
        //console.log(defaultStake);

        if (matchOddClickKeybordRunner === 1 || matchOddClickKeybordRunner === 2 || matchOddClickKeybordRunner === 3) {

            var amount = defaultStake;
            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (pnlMarket.teamA && pnlMarket.teamB && pnlMarket.tie && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0)) {
                win_teamA = parseFloat(pnlMarket.teamA);
                win_teamB = parseFloat(pnlMarket.teamB);
                win_tie = parseFloat(pnlMarket.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(mOdds - 1);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(mOdds - 1);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(mOdds - 1);


                }

            }

            var obj = {};
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            //obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);


            setpnlMarket2(obj);



        }

        else if (bookdataMatchClickKeybordRunner == 1 || bookdataMatchClickKeybordRunner == 2 || bookdataMatchClickKeybordRunner == 3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlBook.teamA && pnlBook.teamB && pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0)) {
                win_teamA = parseFloat(pnlBook.teamA);
                win_teamB = parseFloat(pnlBook.teamB);
                win_tie = parseFloat(pnlBook.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(bOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(bOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlBook2(obj);
        }
        //super over match
        else if (SuperMatchclick || SuperMatchclick2 || SuperMatchclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlSuperMatch.teamA && pnlSuperMatch.teamB && pnlSuperMatch.tie && (pnlSuperMatch.teamA != 0 || pnlSuperMatch.teamB != 0 || pnlSuperMatch.tie != 0)) {
                win_teamA = parseFloat(pnlSuperMatch.teamA);
                win_teamB = parseFloat(pnlSuperMatch.teamB);
                win_tie = parseFloat(pnlSuperMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(SOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(SOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlSuperMatch2(obj);
        }
        //complate match
        else if (ComMatchclick || ComMatchclick2 || ComMatchclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlCompMatch.teamA && pnlCompMatch.teamB && pnlCompMatch.tie && (pnlCompMatch.teamA != 0 || pnlCompMatch.teamB != 0 || pnlCompMatch.tie != 0)) {
                win_teamA = parseFloat(pnlCompMatch.teamA);
                win_teamB = parseFloat(pnlCompMatch.teamB);
                win_tie = parseFloat(pnlCompMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(COdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(COdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlCompMatch2(obj);
        }
        //Tie match
        else if (tieMatchClickKeybordRunner == 1 || tieMatchClickKeybordRunner == 2) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlTieMatch.teamA && pnlTieMatch.teamB && pnlTieMatch.tie && (pnlTieMatch.teamA != 0 || pnlTieMatch.teamB != 0 || pnlTieMatch.tie != 0)) {
                win_teamA = parseFloat(pnlTieMatch.teamA);
                win_teamB = parseFloat(pnlTieMatch.teamB);
                win_tie = parseFloat(pnlTieMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(TOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(TOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlTieMatch2(obj);
        }
        // toss
        //Tie match
        else if (Manualclick || Manualclick2 || Manualclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlManual.teamA && pnlManual.teamB && pnlManual.tie && (pnlManual.teamA != 0 || pnlManual.teamB != 0 || pnlManual.tie != 0)) {
                win_teamA = parseFloat(pnlManual.teamA);
                win_teamB = parseFloat(pnlManual.teamB);
                win_tie = parseFloat(pnlManual.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(TOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(TOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            setpnlManual2(obj);
        }
        // goals market
        else if (goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket) {
            let amount = defaultStake;
            let win_teamA = 0;
            let win_teamB = 0;
            let win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamA && goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamB && goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.tie && (goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamA != 0 || goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamB != 0 || goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.tie != 0)) {
                win_teamA = parseFloat(goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamA);
                win_teamB = parseFloat(goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.teamB);
                win_tie = parseFloat(goalsSoccerApiMarketData[goalsOnKeyBordOn?.currMarket]?.tie);
            }

            if (oddsType == 'LAGAI') {
                if (goalsOnKeyBordOn?.temName == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);
                }
                else if (goalsOnKeyBordOn?.temName == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
            }

            else {

                if (goalsOnKeyBordOn?.temName == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
                else if (goalsOnKeyBordOn?.temName == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(goalsOnKeyBordOn.odd - 1);

                }

            }

            let obj = {};
            if (win_teamA.toFixed(2) == 'NaN') obj.teamA = 0; else obj.teamA = win_teamA.toFixed(2);
            if (win_teamB.toFixed(2) == 'NaN') obj.teamB = 0; else obj.teamB = win_teamB.toFixed(2);
            if (win_tie.toFixed(2) == 'NaN') obj.tie = 0; else obj.tie = win_tie.toFixed(2);

            goalsSoccerPlMarket2Data[goalsOnKeyBordOn?.currMarket] = obj

            // (goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('0.5'))
        }





    }, [defaultStake, goalsOnKeyBordOn])





    const handlebookpin = () => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        } else {
            setbookpin(!bookpin)
        }
    }
    const handlefancypin = () => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        } else {
            setFancypin(!Fancypin)
        }
    }



    const OpenfancyBet = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancy(1)
        setfield(1);

    }

    const OpenfancyBetLay = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancyLay(1);
        setfield(2);

    }
    const closeFancyBet = () => {
        setclick(-1);
        setselectFancy(0);
        setselectFancyLay(0);
    }

    const handleMaxStake = () => {
        setMaxstake(true)
    }

    const handleMaxStakeclose = () => {
        setMaxstake(false)
    }

    const handleFancyBook = (val) => {
        setshowFancyBook(val);
    }


    const refreshBalanceApiAction = useMutation({
        mutationFn: refreshBalanceApiRq,
        onMutate: () => {
            setloading(true);
        },
        onSuccess: rsp => {
            queryClient.setQueryData(['refreshBalanceStatus'], rsp);
            setloading(false);
        },
        onError: () => {
            setloading(false);
        }
    });

    const handleRefresh = () => {
        const utoken = cookies.get('token');
        const user = cookies.get('id');
        refreshBalanceApiAction.mutate({ id: user, token: utoken });
    };

    const plMarket = async (currMarketData) => {
        // if (!props.userid && props.isLoggedIn !== true) return;
        return await Axios.post('https://liveapi247.live/api1/plmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: currMarketData?.marketId
        }).then(result => {
            if (result.status === 210) {
                let obj = result.data;
                return obj;
            }
        })
    }


    const plFancy = () => {
        // if (!props.userid || !eventid && props.isLoggedIn !== true) return;
        Axios.post('https://liveapi247.live/api1/plfancy', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = {};
                    result.data.map((item) => {
                        obj[item.marketId] = item.exposure;
                    });
                    setpnlFancy(obj);
                }

            })
    }



    useEffect(() => {

        if (props.isLoggedIn === true && props.userid && props.utoken) {
            if (marketData.matchStatus == 1 && highlightTab == marketKeys.matchOdds) plMarket(matchMarket[marketKeys.matchOdds]).then(obj => setpnlMarket(obj))
            if (bookData2.matchStatus == 1 && (highlightTab == marketKeys.bookmaker || highlightTab == marketKeys.matchOdds)) plMarket(matchMarket[marketKeys.bookmaker]).then(obj => setpnlBook(obj))
            if (CompMatchData.matchStatus == 1 && highlightTab == marketKeys.completedMatch) plMarket(matchMarket[marketKeys.completedMatch]).then(obj => setpnlCompMatch(obj))
            if (SuperMatchData.matchStatus == 1 && highlightTab == marketKeys.superOver) plMarket(matchMarket[marketKeys.superOver]).then(obj => setpnlSuperMatch(obj))
            if (TieMatchData.matchStatus == 1 && highlightTab == marketKeys.tiedMatch) plMarket(matchMarket[marketKeys.tiedMatch]).then(obj => setpnlTieMatch(obj))
            if (ManualData.matchStatus == 1 && highlightTab == marketKeys.winToss) plMarket(matchMarket[marketKeys.winToss]).then(obj => setpnlManual(obj))

            // goals market pl
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('0.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('1.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('2.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('3.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')] = obj
                })
            }
            if (goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.matchStatus && highlightTab == marketKeys.overUnderGoals('4.5')) {
                plMarket(goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]).then(obj => {
                    goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')] = obj
                })
            }

            // goals market pl
            // if (SuperMatchData.matchStatus == 1 && marketKeys.overUnderGoals("0.5")) plMarket(matchMarket[marketKeys.superOver]).then(obj => setpnlSuperMatch(obj))
            // if (TieMatchData.matchStatus == 1 && marketKeys.overUnderGoals("1.5")) plMarket(matchMarket[marketKeys.tiedMatch]).then(obj => setpnlTieMatch(obj))
            // if (ManualData.matchStatus == 1 && marketKeys.overUnderGoals("2.5")) plMarket(matchMarket[marketKeys.winToss]).then(obj => setpnlManual(obj))
            // if (TieMatchData.matchStatus == 1 && marketKeys.overUnderGoals("3.5")) plMarket(matchMarket[marketKeys.tiedMatch]).then(obj => setpnlTieMatch(obj))
            // if (ManualData.matchStatus == 1 && marketKeys.overUnderGoals("4.5")) plMarket(matchMarket[marketKeys.winToss]).then(obj => setpnlManual(obj))

        }

        // return () => {
        //     setpnlManual({});
        //     setpnlMarket({});
        //     setpnlBook({});
        //     setpnlCompMatch({});
        //     setpnlSuperMatch({});
        //     setpnlTieMatch({});
        // }

    }, [props.isLoggedIn, props.userid, highlightTab, (marketData.marketId || bookData2.marketId)])

    useEffect(() => {
        if (props.isLoggedIn === true && props.userid && eventid && props.utoken) {
            plFancy();
        }
    }, [props.isLoggedIn, props.userid, eventid])


    const fancyBet = (selectionId, fld, runnerName) => {

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');
        var oddT;
        if (fld === 1) {
            oddT = 'NO';
        }
        else if (fld === 2) {
            oddT = 'YES';
        }

        Axios.post('https://liveapi247.live/api3/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: selectionId, //fancy selection id
            odds: fOdds,                  //run
            oddsType: oddT,           //YES NO
            betType: 'fancy',            //fancy
            coin: defaultStake,
            teamName: fsize,          //rate 110,100
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {
                setdefaultStake(dStake);
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddT, runner: runnerName, amount: defaultStake, rate: fOdds + '/' + fsize });
                    handleRefresh();
                    plFancy();
                }

                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == 8) {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }


            }

            ).catch(e => {
                console.log(e);

            });

    };


    // premium book
    const handlepremium = (vwall) => {
        setonclickPremium(vwall)
    }

    const bookBet = () => {
        placeBet(marketKeys.bookmaker);
    };

    const marketBet = () => {
        placeBet(marketKeys.matchOdds);
    };

    const SuperMatchBet = () => {
        placeBet(marketKeys.superOver);
    };

    //comMatch
    const ComMatchBet = () => {
        placeBet(marketKeys.completedMatch);
    };
    // TieMatch
    const TieMatchBet = () => {
        placeBet(marketKeys.tiedMatch);
    };

    // toss bet
    const TossBet = () => {
        placeBet(marketKeys.winToss);
    };


    const placeBet = async (currBetKey) => {
        // if (!props.userid) {
        //     return;
        // }
        // setloading(true);
        props.handleRefreshhh(5000, 'Placing bet...', 1);
        var dupBet = cookies.get('dupBet');

        let currOdds = 0;
        let customPayload = {};

        if (currBetKey === marketKeys.matchOdds) {
            currOdds = mOdds;
        }
        if (currBetKey === marketKeys.bookmaker) {
            currOdds = parseFloat(bOdds / 100 + 1).toFixed(2).toString();
            customPayload = { anyOdds: acceptAnyOdds };
        }
        if (currBetKey === marketKeys.completedMatch) {
            currOdds = COdds
        }
        if (currBetKey === marketKeys.superOver) {
            currOdds = SOdds
        }
        if (currBetKey === marketKeys.tiedMatch) {
            currOdds = TOdds
        }
        if (currBetKey === marketKeys.winToss) {
            currOdds = Maodds
        }
        if (currBetKey === marketKeys.overUnderGoals('0.5') || currBetKey === marketKeys.overUnderGoals('1.5') || currBetKey === marketKeys.overUnderGoals('2.5') || currBetKey === marketKeys.overUnderGoals('3.5') || currBetKey === marketKeys.overUnderGoals('4.5')) {
            customPayload = { odds: goalsOnKeyBordOn?.odd, marketId: goalsSoccerApiMarketData[currBetKey]?.marketId, teamName: goalsOnKeyBordOn.temName };
        }
        console.log('currBetKey', currBetKey)
        console.log('currOdds', currOdds)

        return await Axios.post('https://liveapi247.live/api3/placebets', {
            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: matchMarket[currBetKey]?.marketId,
            odds: currOdds,
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet,
            ...customPayload,
        }, { withCredentials: true }
        ).then(result => {
            props.handleRefreshhh(3000, 'Placing bet...', 2);
            // setloading(false);
            if (result.data == 6) {
                props.changeMsgNumber(1);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);

                handleRefresh();

                if (eventT == 1) {
                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: goalsOnKeyBordOn?.runnerName, amount: defaultStake, rate: goalsOnKeyBordOn.odd });
                    plMarket(goalsSoccerApiMarketData[currBetKey]).then(obj => {
                        goalsSoccerApiPlMarketData[currBetKey] = obj
                    })
                } else {
                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? matchMarket[currBetKey]?.runnerName1 : matchMarket[currBetKey]?.runnerName2, amount: defaultStake, rate: currOdds });
                    plMarket(matchMarket[currBetKey]).then(obj => {
                        if (currBetKey === marketKeys.matchOdds) setpnlMarket(obj)
                        if (currBetKey === marketKeys.bookmaker) setpnlBook(obj)
                        if (currBetKey === marketKeys.completedMatch) setpnlCompMatch(obj)
                        if (currBetKey === marketKeys.superOver) setpnlSuperMatch(obj)
                        if (currBetKey === marketKeys.tiedMatch) setpnlTieMatch(obj)
                        if (currBetKey === marketKeys.winToss) setpnlManual(obj)
                    });
                }

            }
            else if (result.data == 1) {
                props.changeMsgNumber(3);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }

            else if (result.data == 2) {
                props.changeMsgNumber(4);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 3) {
                props.changeMsgNumber(5);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 4) {
                props.changeMsgNumber(6);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 5) {
                props.changeMsgNumber(7);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 7) {
                props.changeMsgNumber(8);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 123) {
                props.changeMsgNumber(9);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 13) {
                props.changeMsgNumber(10);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data.charAt(0) == '8') {
                props.changeMsgNumber(11);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
                props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
            }
            else if (result.data.charAt(0) == 9) {
                props.changeMsgNumber(12);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
                props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
            } else {
                props.changeMsgNumber(7);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }


        }).catch(e => {
            console.log(e);
        });
    };


    //marketConfirmBox
    const marketBetClicked = () => {
        placeBet(goalsOnKeyBordOn?.currMarket)
        // setShowConfirmGoalsBet(false);
        // onCloseGoalMarketLayBack({});
    }

    const TossCloseBetClicked = () => {
        setshowTossConfirmBet(false);
    }

    //TieMatch ConfirmBox
    const TieMatchBetClicked = () => {
        setshowTieConfirmBet(true);
    }

    //superMatch confrimBox

    const SuperMatchBetClicked = () => {
        setshowSupConfirmBet(true);
    }
    const TossBetClicked = () => {
        setshowTossConfirmBet(true);
    }
    const SuperMatchcloseConfirmBet = () => {
        setshowSupConfirmBet(false);
    }

    //Com match

    const ComMatchBetClicked = () => {
        setshowComConfirmBet(true);

    }
    const ComMatchcloseConfirmBet = () => {
        setshowComConfirmBet(false);
    }

    const handleNewRefresh = () => {
        setloading(true);
        setTimeout(() => { setloading(false); }, 600);
    }


    const OpenPremiumBetLay = (index, index2) => {
        setdefaultStake(dStake);
        setPremclick(index);
        setPremclick2(index2);
        setselectPremLay(1);
        setpremiumfield(1);

    }

    const closePremiumBet = () => {
        setPremclick(-1);
        setPremclick2(-1);
        setselectPremLay(0);
    }


    let multiChannelId = chId != "" ? chId : ChannelId;

    return (
        <React.Fragment>
            <LoadingOverlay
                show={(loading || newrefresh)}
            />
            <div _nghost-vsy-c8>
                <div _ngcontent-vsy-c8="" className="animated fadeIn">
                    <div _ngcontent-vsy-c8="" className="row">

                        <div _ngcontent-vsy-c8="" className="col-md-12 scr-new-desgn">
                            <div _ngcontent-vsy-c8="" className="game-head cricket show-xs" id="gameHead">
                                <div _ngcontent-vsy-c8="" className="game-highlight-wrap ">

                                    <h3 _ngcontent-vsy-c8="" style={{ fontSize: '16px', lineHeight: '35px' }}>
                                        <span _ngcontent-vsy-c8="">Cricket</span>
                                        <span _ngcontent-vsy-c8="" onClick={() => { Openpoup() }} style={{ float: 'right', fontWeight: 'bold', marginRight: '10px', fontSize: '16px' }}>
                                            <i _ngcontent-vsy-c8="" className="fa fa-television" /></span></h3>
                                </div>
                                {Tabselect === true && < div _ngcontent-vsy-c8="" className="card-body p-0 mobile-stream">
                                    <div _ngcontent-vsy-c8="" className="live-stream noPreventDefault">
                                        <nav _ngcontent-vsy-c8="" className="ui-ribbon">
                                            <ul _ngcontent-vsy-c8="" className="list">
                                                <li _ngcontent-vsy-c8="" className={`item pebble ${(Tabcricscore === 0) ? "selected" : "null"}`} >
                                                    <div _ngcontent-vsy-c8="" className="link">
                                                        <div _ngcontent-vsy-c8="" className="container">
                                                            <div _ngcontent-vsy-c8="" className="title" onClick={() => { settabcricscore(0) }}>Live Score</div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li _ngcontent-vsy-c8="" className={`item pebble ${(Tabcricscore === 1) ? "selected" : "null"}`}>
                                                    <div _ngcontent-vsy-c8="" className="link">
                                                        <div _ngcontent-vsy-c8="" className="container">
                                                            <div _ngcontent-vsy-c8="" className="title" onClick={() => { settabcricscore(1) }}>Watch Live</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <Tvstream {...props} Tabcricscore={Tabcricscore} marketStartTime={marketStartTime} />
                                    <ul _ngcontent-smp-c3="" class="match-btn">
                                        <li _ngcontent-smp-c3="">
                                            <a _ngcontent-smp-c3="" id="liveMultiMarketPin" name="gameHeadPin" style={{ cursor: 'pointer' }} title="Add to Multi Markets" class="btn-pin 100202310312457845">Pin</a></li>
                                        <li _ngcontent-smp-c3="">
                                            <a _ngcontent-smp-c3="" class="btn-refresh" style={{ cursor: 'pointer' }}>Refresh</a></li></ul>
                                </div>}

                            </div>
                        </div>
                        <div className='col-md-12 match-list'>
                            <div _ngcontent-sxs-c3 className="market-type show-xs" id="naviMarket">
                                {eventT == 4 &&
                                    <ul _ngcontent-sxs-c3 id="naviMarketList">
                                        <li _ngcontent-sxs-c3 onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.matchOdds) }} className={`${(highlightTab === marketKeys.matchOdds) ? "select" : "null"}`} id="naviMarket_1_172614581" style={{ display: 'list-item' }}>
                                            <a _ngcontent-sxs-c3>Match Odds</a></li>
                                        {ManualData.matchStatus === '1' && <li _ngcontent-sxs-c3 onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.winToss) }} className={`${(highlightTab === marketKeys.winToss) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                            <a _ngcontent-sxs-c3>To Win the Toss</a></li>}
                                        {CompMatchData.matchStatus === '1' && <li _ngcontent-sxs-c3 onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.completedMatch) }} className={`${(highlightTab === marketKeys.completedMatch) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                            <a _ngcontent-sxs-c3>Completed Match</a>
                                        </li>}
                                        {TieMatchData.matchStatus === '1' && <li _ngcontent-sxs-c3 onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.tiedMatch) }} className={`${(highlightTab === marketKeys.tiedMatch) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                            <a _ngcontent-sxs-c3>Tied Match </a></li>}

                                        {SuperMatchData.matchStatus === '1' && <li _ngcontent-sxs-c3 onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.superOver) }} className={`${(highlightTab === marketKeys.superOver) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                            <a _ngcontent-sxs-c3>Super Over</a></li>}</ul>
                                }
                                {/* {eventT == 1 &&
                                    <ul _ngcontent-sxs-c3 id="naviMarketList">
                                        <li _ngcontent-sxs-c3 onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.matchOdds) }} className={`${(highlightTab === marketKeys.matchOdds) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                            <a _ngcontent-sxs-c3>Match Odds</a></li>
                                        {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].matchStatus == 1 && (<li _ngcontent-sxs-c3 onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.matchOdds) }} className={`${(highlightTab == marketKeys.overUnderGoals("0.5")) ? "select" : "null"}`} onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.overUnderGoals("0.5")); onCloseGoalMarketLayBack() }} style={{ display: 'list-item' }}>
                                            <a _ngcontent-sxs-c3>Over/Under 0.5 Goals</a></li>
                                        )}
                                        {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].matchStatus == 1 && (
                                            <li _ngcontent-sxs-c3 className={`${(highlightTab == marketKeys.overUnderGoals("1.5")) ? "select" : "null"}`} onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.overUnderGoals("1.5")); onCloseGoalMarketLayBack() }} style={{ display: 'list-item' }}>
                                                <a _ngcontent-sxs-c3>Over/Under 1.5 Goals</a></li>
                                        )}
                                        {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].matchStatus == 1 && (
                                            <li _ngcontent-sxs-c3 className={`${(highlightTab == marketKeys.overUnderGoals("2.5")) ? "select" : "null"}`} onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.overUnderGoals("2.5")); onCloseGoalMarketLayBack() }} style={{ display: 'list-item' }}>
                                                <a _ngcontent-sxs-c3>Over/Under 2.5 Goals</a></li>
                                        )}
                                        {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')].matchStatus == 1 && (
                                            <li _ngcontent-sxs-c3 className={`${(highlightTab == marketKeys.overUnderGoals("3.5")) ? "select" : "null"}`} onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.overUnderGoals("3.5")); onCloseGoalMarketLayBack() }} style={{ display: 'list-item' }}>
                                                <a _ngcontent-sxs-c3>Over/Under 3.5 Goals</a></li>
                                        )}
                                        {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].matchStatus == 1 && (
                                            <li _ngcontent-sxs-c3 className={`${(highlightTab == marketKeys.overUnderGoals("4.5")) ? "select" : "null"}`} onClick={() => { props.handleRefreshhh(500); sethighlightTab(marketKeys.overUnderGoals("4.5")); onCloseGoalMarketLayBack() }} style={{ display: 'list-item' }}>
                                                <a _ngcontent-sxs-c3>Over/Under 4.5 Goals</a></li>
                                        )}

                                    </ul>
                                } */}
                            </div>
                            {loading === false &&

                                <React.Fragment>
                                    {/* matchodd market */}
                                    {highlightTab === marketKeys.matchOdds && hidematchOdds != '1' &&

                                        <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                            <div _ngcontent-wrf-c3 className="card-matchodds">
                                                <strong _ngcontent-wrf-c3 className="match-odds">Match Odds
                                                    <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                    <strong _ngcontent-wrf-c3>€ 30.3M</strong></span></div>
                                            <div className='card-body p-0'>
                                                <div className='table-responsive'>
                                                    <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                        <RunnerHeader
                                                            totalStake={totalStake}
                                                            onHideMaxStack={handleMaxStakeclose}
                                                            maxStake={Maxstake && marketData.maxStack}
                                                            coinFactor={coinFactor} />
                                                        {marketData.matchStatus == 1 &&
                                                            <tbody _ngcontent-jxa-c3="">
                                                                <RunnerRow
                                                                    blockStatus={
                                                                        StatusSS === 1 || StatusSS === 0 || StatusSS === 2 &&
                                                                        <dd id="suspend" className="suspend" style={StatusSS != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                        </p></dd>
                                                                    }
                                                                    firstPl={pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) &&
                                                                        <li id="withoutBet" className={`${pnlMarket.teamA > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlMarket.teamA > 0 ? pnlMarket.teamA : '(' + Math.abs(pnlMarket.teamA) + ')'}</li>}
                                                                    secondPl={(matchOddClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' &&
                                                                        <li id="after" className={`${pnlMarket2.teamA > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }}  >{pnlMarket2.teamA > 0 ? pnlMarket2.teamA : '(' + Math.abs(pnlMarket2.teamA) + ')'}</li>}
                                                                    runnerName={marketData.runnerName1}
                                                                    backSelected={selectMatchBack === 1}
                                                                    laySelected={selectMatchBack === 2}
                                                                    runnerBackRate={runner1BackRate1}
                                                                    runnerBackSize={runner1BackSize1}
                                                                    runnerLayRate={runner1LayRate1}
                                                                    runnerLaySize={runner1LaySize1}
                                                                    onClick={(isLayOrBack, rate) => !(runner1BackRate1 >= 25) ? changeMatchodds(isLayOrBack == 'back' ? 1 : 2, 1, rate) : null}
                                                                    style={{
                                                                        order: 1,
                                                                    }}
                                                                    className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                                                />
                                                                {(matchOddClickKeybordRunner === 1 || matchOddClickKeybordRunner === 2 || matchOddClickKeybordRunner === 3) &&
                                                                    <PlaceBetKeybord
                                                                        layBack={(selectMatchBack === 1 || selectMatchBack === 3 || selectMatchBack === 5) ? "slip-back" : "slip-lay"}
                                                                        odds={mOdds}
                                                                        onClickOddMinus={() => (mOdds != '' || mOdds != ' ') ? setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) : null}
                                                                        onClickOddPlus={() => (mOdds != '' || mOdds != ' ') ? setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) : null}
                                                                        onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                        onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                        defaultStake={defaultStake}
                                                                        onInputChnageStack={e => setdefaultStake(e)}
                                                                        onCancel={() => { closeMatchoddsBet(); setdefaultStake(dStake); }}
                                                                        onPlace={() => {
                                                                            placeBet(marketKeys.matchOdds).then(() => {
                                                                                closeMatchoddsBet();
                                                                            })
                                                                        }}
                                                                        ssStack={{
                                                                            ssstake1,
                                                                            ssstake2,
                                                                            ssstake3,
                                                                            ssstake4,
                                                                            ssstake5,
                                                                            ssstake6,
                                                                            ssstake7,
                                                                            ssstake8
                                                                        }}
                                                                        minStack={marketData.minStack}
                                                                        coinFactor={coinFactor}
                                                                        style={{
                                                                            order: (matchOddClickKeybordRunner + 1)
                                                                        }}
                                                                    />}
                                                                {/* r2 */}
                                                                <RunnerRow
                                                                    blockStatus={StatusSS === 1 || StatusSS === 0 || StatusSS === 2 &&
                                                                        <dd id="suspend" className="suspend" style={StatusSS != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                        </p></dd>}
                                                                    firstPl={pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.teamB > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlMarket.teamB > 0 ? pnlMarket.teamB : '(' + Math.abs(pnlMarket.teamB) + ')'}</li>}
                                                                    secondPl={(matchOddClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.teamB > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlMarket2.teamB > 0 ? pnlMarket2.teamB : '(' + Math.abs(pnlMarket2.teamB) + ')'}</li>}
                                                                    runnerName={marketData.runnerName2}
                                                                    backSelected={selectMatchBack === 3}
                                                                    laySelected={selectMatchBack === 4}
                                                                    runnerBackRate={runner2BackRate1}
                                                                    runnerBackSize={runner2BackSize1}
                                                                    runnerLayRate={runner2LayRate1}
                                                                    runnerLaySize={runner2LaySize1}
                                                                    onClick={(isLayOrBack, rate) => !(runner2BackRate1 >= 25) ? changeMatchodds(isLayOrBack == 'slip-back' ? 3 : 4, 2, rate) : null}
                                                                    style={{
                                                                        order: 2,
                                                                    }}
                                                                    className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                                                />
                                                                {/* r3 */}
                                                                {marketData.runnerId3 && marketData.runnerId3 != ' ' &&
                                                                    <RunnerRow
                                                                        blockStatus={StatusSS === 1 || StatusSS === 0 || StatusSS === 2 &&
                                                                            <dd id="suspend" className="suspend" style={StatusSS != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                            </p></dd>}
                                                                        firstPl={pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.tie > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlMarket.tie > 0 ? pnlMarket.tie : '(' + Math.abs(pnlMarket.tie) + ')'}</li>}
                                                                        secondPl={(matchOddClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.tie > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlMarket2.tie > 0 ? pnlMarket2.tie : '(' + Math.abs(pnlMarket2.tie) + ')'}</li>}
                                                                        runnerName="The Draw"
                                                                        backSelected={selectMatchBack === 5}
                                                                        laySelected={selectMatchBack === 6}
                                                                        runnerBackRate={tieBackRate1}
                                                                        runnerBackSize={tieBackSize1}
                                                                        runnerLayRate={tieLayRate1}
                                                                        runnerLaySize={tieLaySize1}
                                                                        onClick={(isLayOrBack, rate) => !(tieBackRate1 >= 25) ? changeMatchodds(isLayOrBack == 'slip-back' ? 5 : 6, 3, rate) : null}
                                                                        style={{
                                                                            order: 3,
                                                                        }}
                                                                        className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                                                    />}

                                                            </tbody>

                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>}
                                    {/* tidematch market */}
                                    {highlightTab === marketKeys.tiedMatch &&
                                        <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                            <div _ngcontent-wrf-c3 className="card-matchodds">
                                                <strong _ngcontent-wrf-c3 className="match-odds">Tied Match
                                                    <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                    <strong _ngcontent-wrf-c3>€ 30.3M</strong></span>
                                            </div>
                                            <div className='card-body p-0'>
                                                <div className='table-responsive'>
                                                    <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                        <RunnerHeader
                                                            totalStake={totalStake}
                                                            onHideMaxStack={handleMaxStakeclose}
                                                            maxStake={Maxstake && TieMatchData.maxStack}
                                                            coinFactor={coinFactor} />
                                                        {TieMatchData.matchStatus == 1 && <tbody _ngcontent-jxa-c3="">
                                                            <RunnerRow
                                                                blockStatus={<dd id="suspend" className="suspend" style={TiemarketBetStatus != 1 ? { display: 'flex' } : { display: 'none' }}><p>Suspend
                                                                </p></dd>}
                                                                firstPl={pnlTieMatch.teamA && (pnlTieMatch.teamA != 0 || pnlTieMatch.teamB != 0 || pnlTieMatch.tie != 0) && <li id="withoutBet" className={`${pnlTieMatch.teamA > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlTieMatch.teamA > 0 ? pnlTieMatch.teamA : '(' + Math.abs(pnlTieMatch.teamA) + ')'}</li>}
                                                                secondPl={(tieMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlTieMatch2.teamA > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlTieMatch2.teamA > 0 ? pnlTieMatch2.teamA : '(' + Math.abs(pnlTieMatch2.teamA) + ')'}</li>}
                                                                runnerName="Yes"
                                                                backSelected={selectTieMatchBack === 1}
                                                                laySelected={selectTieMatchBack === 2}
                                                                runnerBackRate={Tierunner1BackRate1}
                                                                runnerBackSize={Tierunner1BackSize1}
                                                                runnerLayRate={Tierunner1LayRate1}
                                                                runnerLaySize={Tierunner1LaySize1}
                                                                onClick={(isLayOrBack, rate) => changeTieMatchBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                                                style={{
                                                                    order: 1,
                                                                }}
                                                            />
                                                            {(tieMatchClickKeybordRunner === 1 || tieMatchClickKeybordRunner === 2) &&
                                                                <PlaceBetKeybord
                                                                    layBack={(selectTieMatchBack === 1 || selectTieMatchBack === 3 || selectTieMatchBack === 5) ? "slip-back" : "slip-lay"}
                                                                    odds={TOdds}
                                                                    onClickOddMinus={() => (TOdds != '' || TOdds != ' ') ? setTOdds(parseFloat((parseFloat(TOdds) - 0.01).toFixed(2)).toString()) : null}
                                                                    onClickOddPlus={() => (TOdds != '' || TOdds != ' ') ? setTOdds(parseFloat((parseFloat(TOdds) + 0.01).toFixed(2)).toString()) : null}
                                                                    onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                    onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                    defaultStake={defaultStake}
                                                                    onInputChnageStack={e => setdefaultStake(e)}
                                                                    onCancel={() => { closeTieMatchBet(); setdefaultStake(dStake); }}
                                                                    // onPlace={() => { TieMatchBetClicked(); closeTieMatchBet(); }}
                                                                    onPlace={() => {
                                                                        placeBet(marketKeys.tiedMatch).then(() => {
                                                                            closeTieMatchBet();
                                                                        })
                                                                    }}
                                                                    ssStack={{
                                                                        ssstake1,
                                                                        ssstake2,
                                                                        ssstake3,
                                                                        ssstake4,
                                                                        ssstake5,
                                                                        ssstake6,
                                                                        ssstake7,
                                                                        ssstake8
                                                                    }}
                                                                    minStack={TieMatchData.minStack}
                                                                    coinFactor={coinFactor}
                                                                    style={{
                                                                        order: (tieMatchClickKeybordRunner + 1)
                                                                    }}
                                                                />}
                                                            {/* r2 */}
                                                            <RunnerRow
                                                                blockStatus={<dd id="suspend" className="suspend" style={TiemarketBetStatus != 1 ? { display: 'flex' } : { display: 'none' }}><p>Suspend
                                                                </p></dd>}
                                                                firstPl={pnlTieMatch.teamA && (pnlTieMatch.teamA != 0 || pnlTieMatch.teamB != 0 || pnlTieMatch.tie != 0) &&
                                                                    <li id="withoutBet" className={`${pnlTieMatch.teamB > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlTieMatch.teamB > 0 ? pnlTieMatch.teamB : '(' + Math.abs(pnlTieMatch.teamB) + ')'}</li>}
                                                                secondPl={tieMatchClickKeybordRunner && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlTieMatch2.teamB > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }}>{pnlTieMatch2.teamB > 0 ? pnlTieMatch2.teamB : '(' + Math.abs(pnlTieMatch2.teamB) + ')'}</li>}
                                                                runnerName="No"
                                                                backSelected={selectTieMatchBack === 3}
                                                                laySelected={selectTieMatchBack === 4}
                                                                runnerBackRate={Tierunner2BackRate1}
                                                                runnerBackSize={Tierunner2BackSize1}
                                                                runnerLayRate={Tierunner2LayRate1}
                                                                runnerLaySize={Tierunner2LaySize1}
                                                                onClick={(isLayOrBack, rate) => changeTieMatchBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                                                style={{
                                                                    order: 2
                                                                }}
                                                            />




                                                        </tbody>}
                                                    </table>

                                                </div>
                                            </div>
                                        </div>}
                                    {/* tierate componantend */}


                                    {/* complated market */}
                                    {highlightTab === marketKeys.completedMatch &&
                                        <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                            <div _ngcontent-wrf-c3 className="card-matchodds">
                                                <strong _ngcontent-wrf-c3 className="match-odds">Completed Match
                                                    <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                    <strong _ngcontent-wrf-c3>€ 30.3M</strong></span>
                                            </div>
                                            <div className='card-body p-0'>
                                                <div className='table-responsive'>
                                                    <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                        <RunnerHeader
                                                            totalStake={totalStake}
                                                            onHideMaxStack={handleMaxStakeclose}
                                                            maxStake={Maxstake && CompMatchData.maxStack}
                                                            coinFactor={coinFactor} />
                                                        {CompMatchData.matchStatus == 1 && <tbody _ngcontent-jxa-c3="">
                                                            <RunnerRow
                                                                firstPl={pnlCompMatch.teamA && (pnlCompMatch.teamA != 0 || pnlCompMatch.teamB != 0 || pnlCompMatch.tie != 0) &&
                                                                    <li id="withoutBet" className={`${pnlCompMatch.teamA > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlCompMatch.teamA > 0 ? pnlCompMatch.teamA : '(' + Math.abs(pnlCompMatch.teamA) + ')'}</li>}
                                                                secondPl={(ComMatchclick2 || ComMatchclick || ComMatchclick3) && defaultStake != ' ' && defaultStake != '' &&
                                                                    <li id="after" className={`${pnlCompMatch2.teamA > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlCompMatch2.teamA > 0 ? pnlCompMatch2.teamA : '(' + Math.abs(pnlCompMatch2.teamA) + ')'}</li>}
                                                                runnerName="Yes"
                                                                backSelected={selectCompMatchBack === 1}
                                                                laySelected={selectCompMatchBack === 2}
                                                                runnerBackRate={Comrunner1BackRate1}
                                                                runnerBackSize={Comrunner1BackSize1}
                                                                runnerLayRate={Comrunner1LayRate1}
                                                                runnerLaySize={Comrunner1LaySize1}
                                                                onClick={(isLayOrBack, rate) => changeCompMatchBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                                                style={{
                                                                    order: 1
                                                                }}
                                                            />
                                                            {(comMatchClickKeybordRunner === 1 || comMatchClickKeybordRunner === 2) &&
                                                                <PlaceBetKeybord
                                                                    layBack={(selectCompMatchBack === 1 || selectCompMatchBack === 3 || selectCompMatchBack === 5) ? "slip-back" : "slip-lay"}
                                                                    odds={COdds}
                                                                    onClickOddMinus={() => (COdds != '' || COdds != ' ') ? setCOdds(parseFloat((parseFloat(COdds) - 0.01).toFixed(2)).toString()) : null}
                                                                    onClickOddPlus={() => (COdds != '' || COdds != ' ') ? setCOdds(parseFloat((parseFloat(COdds) + 0.01).toFixed(2)).toString()) : null}
                                                                    onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                    onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                    defaultStake={defaultStake}
                                                                    onInputChnageStack={e => setdefaultStake(e)}
                                                                    onCancel={() => { closeCompMatchBet(); setdefaultStake(dStake); }}
                                                                    // onPlace={() => {
                                                                    //     ComMatchBetClicked();
                                                                    //     closeCompMatchBet();
                                                                    // }}
                                                                    onPlace={() => {
                                                                        placeBet(marketKeys.completedMatch).then(() => {
                                                                            closeCompMatchBet();
                                                                        })
                                                                    }}
                                                                    ssStack={{
                                                                        ssstake1,
                                                                        ssstake2,
                                                                        ssstake3,
                                                                        ssstake4,
                                                                        ssstake5,
                                                                        ssstake6,
                                                                        ssstake7,
                                                                        ssstake8
                                                                    }}
                                                                    minStack={CompMatchData.minStack}
                                                                    coinFactor={coinFactor}
                                                                    style={{
                                                                        order: (comMatchClickKeybordRunner + 1)
                                                                    }}
                                                                />}
                                                            {/* r2 */}
                                                            <RunnerRow
                                                                firstPl={pnlCompMatch.teamA && (pnlCompMatch.teamA != 0 || pnlCompMatch.teamB != 0 || pnlCompMatch.tie != 0) &&
                                                                    <li id="withoutBet" className={`${pnlCompMatch.teamB > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlCompMatch.teamB > 0 ? pnlCompMatch.teamB : '(' + Math.abs(pnlCompMatch.teamB) + ')'}</li>}
                                                                secondPl={(ComMatchclick2 || ComMatchclick || ComMatchclick3) && defaultStake != ' ' && defaultStake != '' &&
                                                                    <li id="after" className={`${pnlCompMatch2.teamB > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlCompMatch2.teamB > 0 ? pnlCompMatch2.teamB : '(' + Math.abs(pnlCompMatch2.teamB) + ')'}</li>}
                                                                runnerName="No"
                                                                backSelected={selectCompMatchBack === 3}
                                                                laySelected={selectCompMatchBack === 4}
                                                                runnerBackRate={Comrunner2BackRate1}
                                                                runnerBackSize={Comrunner2BackSize1}
                                                                runnerLayRate={Comrunner2LayRate1}
                                                                runnerLaySize={Comrunner2LaySize1}
                                                                onClick={(isLayOrBack, rate) => changeCompMatchBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                                                style={{
                                                                    order: 2
                                                                }}
                                                            />
                                                        </tbody>}
                                                    </table>

                                                </div>
                                            </div>
                                        </div>}

                                    {/* superover market */}
                                    {highlightTab === marketKeys.superOver &&
                                        <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                            <div _ngcontent-wrf-c3 className="card-matchodds">
                                                <strong _ngcontent-wrf-c3 className="match-odds">SuperOver Match
                                                    <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                    <strong _ngcontent-wrf-c3>€ 30.3M</strong></span>
                                            </div>
                                            <div className='card-body p-0'>
                                                <div className='table-responsive'>
                                                    <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                        <RunnerHeader
                                                            totalStake={totalStake}
                                                            onHideMaxStack={handleMaxStakeclose}
                                                            maxStake={Maxstake && SuperMatchData.maxStack}
                                                            coinFactor={coinFactor} />
                                                        {SuperMatchData.matchStatus == 1 && <tbody _ngcontent-jxa-c3="">
                                                            <RunnerRow
                                                                firstPl={pnlSuperMatch.teamA && (pnlSuperMatch.teamA != 0 || pnlSuperMatch.teamB != 0 || pnlSuperMatch.tie != 0) &&
                                                                    <li id="withoutBet" className={`${pnlSuperMatch.teamA > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlSuperMatch.teamA > 0 ? pnlSuperMatch.teamA : '(' + Math.abs(pnlSuperMatch.teamA) + ')'}</li>}
                                                                secondPl={(SuperMatchclick2 || SuperMatchclick || SuperMatchclick3) && defaultStake != ' ' && defaultStake != '' &&
                                                                    <li id="after" className={`${pnlSuperMatch2.teamA > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlSuperMatch2.teamA > 0 ? pnlSuperMatch2.teamA : '(' + Math.abs(pnlSuperMatch2.teamA) + ')'}</li>}
                                                                runnerName={SuperMatchData.runnerName1}
                                                                backSelected={selectSuperMatchBack === 1}
                                                                laySelected={selectSuperMatchBack === 2}
                                                                runnerBackRate={Suprunner1BackRate1}
                                                                runnerBackSize={Suprunner1BackSize1}
                                                                runnerLayRate={Suprunner1LayRate1}
                                                                runnerLaySize={Suprunner1LaySize1}
                                                                onClick={(isLayOrBack, rate) => changeSuperMatchBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                                                style={{
                                                                    order: 1
                                                                }}
                                                            />
                                                            {(superMatchClickKeybordRunner === 1 || superMatchClickKeybordRunner === 2 || superMatchClickKeybordRunner === 3) &&
                                                                <PlaceBetKeybord
                                                                    layBack={(selectCompMatchBack === 1 || selectCompMatchBack === 3 || selectCompMatchBack === 5) ? "slip-back" : "slip-lay"}
                                                                    odds={COdds}
                                                                    onClickOddMinus={() => (COdds != '' || COdds != ' ') ? setCOdds(parseFloat((parseFloat(COdds) - 0.01).toFixed(2)).toString()) : null}
                                                                    onClickOddPlus={() => (COdds != '' || COdds != ' ') ? setCOdds(parseFloat((parseFloat(COdds) + 0.01).toFixed(2)).toString()) : null}
                                                                    onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                    onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                    defaultStake={defaultStake}
                                                                    onInputChnageStack={e => setdefaultStake(e)}
                                                                    onCancel={() => { closeCompMatchBet(); setdefaultStake(dStake); }}
                                                                    // onPlace={() => { ComMatchBetClicked(); closeCompMatchBet(); }}
                                                                    onPlace={() => {
                                                                        placeBet(marketKeys.superOver).then(() => {
                                                                            closeCompMatchBet();
                                                                        })
                                                                    }}
                                                                    ssStack={{
                                                                        ssstake1,
                                                                        ssstake2,
                                                                        ssstake3,
                                                                        ssstake4,
                                                                        ssstake5,
                                                                        ssstake6,
                                                                        ssstake7,
                                                                        ssstake8
                                                                    }}
                                                                    minStack={CompMatchData.minStack}
                                                                    coinFactor={coinFactor}
                                                                    style={{
                                                                        order: (comMatchClickKeybordRunner + 1)
                                                                    }}
                                                                />}
                                                            {/* r2 */}
                                                            <RunnerRow
                                                                firstPl={pnlSuperMatch.teamA && (pnlSuperMatch.teamA != 0 || pnlSuperMatch.teamB != 0 || pnlSuperMatch.tie != 0) &&
                                                                    <li id="withoutBet" className={`${pnlSuperMatch.teamB > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlSuperMatch.teamB > 0 ? pnlSuperMatch.teamB : '(' + Math.abs(pnlSuperMatch.teamB) + ')'}</li>}
                                                                secondPl={(SuperMatchclick2 || SuperMatchclick || SuperMatchclick3) && defaultStake != ' ' && defaultStake != '' &&
                                                                    <li id="after" className={`${pnlSuperMatch2.teamB > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlSuperMatch2.teamB > 0 ? pnlSuperMatch2.teamB : '(' + Math.abs(pnlSuperMatch2.teamB) + ')'}</li>}
                                                                runnerName={SuperMatchData.runnerName2}
                                                                backSelected={selectSuperMatchBack === 3}
                                                                laySelected={selectSuperMatchBack === 4}
                                                                runnerBackRate={Suprunner2BackRate1}
                                                                runnerBackSize={Suprunner2BackSize1}
                                                                runnerLayRate={Suprunner2LayRate1}
                                                                runnerLaySize={Suprunner2LaySize1}
                                                                onClick={(isLayOrBack, rate) => changeMatchodds(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                                                style={{
                                                                    order: 2
                                                                }}
                                                            />
                                                        </tbody>}
                                                    </table>

                                                </div>
                                            </div>
                                        </div>}
                                    {/* wintoss market */}
                                    {highlightTab === marketKeys.winToss &&
                                        <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                            <div _ngcontent-wrf-c3 className="card-matchodds">
                                                <strong _ngcontent-wrf-c3 className="match-odds">WinToss Match
                                                    <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                    <strong _ngcontent-wrf-c3>€ 30.3M</strong></span>
                                            </div>
                                            <div className='card-body p-0'>
                                                <div className='table-responsive'>
                                                    <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                        <RunnerHeader
                                                            totalStake={totalStake}
                                                            onHideMaxStack={handleMaxStakeclose}
                                                            maxStake={Maxstake && SuperMatchData.maxStack}
                                                            coinFactor={coinFactor} />
                                                        {ManualData.matchStatus == 1 && <tbody _ngcontent-jxa-c3="">
                                                            <RunnerRow
                                                                firstPl={pnlManual.teamA && (pnlManual.teamA != 0 || pnlManual.teamB != 0 || pnlManual.tie != 0) &&
                                                                    <li id="withoutBet" className={`${pnlManual.teamA > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlManual.teamA > 0 ? pnlManual.teamA : '(' + Math.abs(pnlManual.teamA) + ')'}</li>}
                                                                secondPl={(Manualclick2 || Manualclick || Manualclick3) && defaultStake != ' ' && defaultStake != '' &&
                                                                    <li id="after" className={`${pnlManual2.teamA > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlManual2.teamA > 0 ? pnlManual2.teamA : '(' + Math.abs(pnlManual2.teamA) + ')'}</li>}
                                                                runnerName={ManualData.runnerName1}
                                                                backSelected={selectManualBack === 1}
                                                                laySelected={selectManualBack === 2}
                                                                runnerBackRate={tossrunner1BackRate251}
                                                                runnerBackSize={tossrunner1BackRate251}
                                                                runnerLayRate={tossrunner1LayRate251}
                                                                runnerLaySize={tossrunner1LaySize251}
                                                                onClick={(isLayOrBack, rate) => changeTossBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                                                style={{
                                                                    order: 1
                                                                }}
                                                            />
                                                            {(WintossMatchClickKeybordRunner === 1 || WintossMatchClickKeybordRunner === 2 || WintossMatchClickKeybordRunner === 3) &&
                                                                <PlaceBetKeybord
                                                                    layBack={(selectManualBack === 1 || selectManualBack === 3 || selectManualBack === 5) ? "back" : "lay"}
                                                                    odds={Maodds}
                                                                    onClickOddMinus={() => (Maodds != '' || Maodds != ' ') ? setMaodds(parseFloat((parseFloat(Maodds) - 0.01).toFixed(2)).toString()) : null}
                                                                    onClickOddPlus={() => (Maodds != '' || Maodds != ' ') ? setMaodds(parseFloat((parseFloat(Maodds) + 0.01).toFixed(2)).toString()) : null}
                                                                    onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                    onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                    defaultStake={defaultStake}
                                                                    onInputChnageStack={e => setdefaultStake(e)}
                                                                    onCancel={() => { closeManualBet(); setdefaultStake(dStake); }}
                                                                    // onPlace={() => { TossBetClicked(); closeManualBet(); }}
                                                                    ssStack={{
                                                                        ssstake1,
                                                                        ssstake2,
                                                                        ssstake3,
                                                                        ssstake4,
                                                                        ssstake5,
                                                                        ssstake6
                                                                    }}
                                                                    onPlace={() => {
                                                                        placeBet(marketKeys.winToss).then(() => {
                                                                            closeManualBet();
                                                                        })
                                                                    }}
                                                                    minStack={ManualData.minStack}
                                                                    coinFactor={coinFactor}
                                                                    style={{
                                                                        order: (WintossMatchClickKeybordRunner + 1)
                                                                    }}
                                                                />}
                                                            {/* r2 */}
                                                            <RunnerRow
                                                                firstPl={pnlManual.teamA && (pnlManual.teamA != 0 || pnlManual.teamB != 0 || pnlManual.tie != 0) &&
                                                                    <li id="withoutBet" className={`${pnlManual.teamB > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlManual.teamB > 0 ? pnlManual.teamB : '(' + Math.abs(pnlManual.teamB) + ')'}</li>}
                                                                secondPl={(Manualclick2 || Manualclick || Manualclick3) && defaultStake != ' ' && defaultStake != '' &&
                                                                    <li id="after" className={`${pnlManual2.teamB > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlManual2.teamB > 0 ? pnlManual2.teamB : '(' + Math.abs(pnlManual2.teamB) + ')'}</li>}
                                                                runnerName={ManualData.runnerName2}
                                                                backSelected={selectManualBack === 3}
                                                                laySelected={selectManualBack === 4}
                                                                runnerBackRate={tossrunner2BackRate251}
                                                                runnerBackSize={tossrunner2BackSize251}
                                                                runnerLayRate={tossrunner2LayRate251}
                                                                runnerLaySize={tossrunner2LaySize251}
                                                                onClick={(isLayOrBack, rate) => changeTossBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                                                style={{
                                                                    order: 2
                                                                }}
                                                            />
                                                        </tbody>}
                                                    </table>

                                                </div>
                                            </div>
                                        </div>}

                                    {/* bookmaker market */}
                                    {bookData2.matchStatus == 1 &&

                                        <div _ngcontent-eix-c3="">
                                            <div _ngcontent-eix-c3="" className="card mb-0 matchodd">
                                                <div _ngcontent-eix-c3="" className="card-matchodds">
                                                    <strong _ngcontent-eix-c3="" className="match-odds">Bookmaker
                                                        <span _ngcontent-eix-c3="" className="marketinfo ml-2" /></strong>
                                                    <span _ngcontent-eix-c3="" className="matched-count  pull-right">Matched
                                                        <strong _ngcontent-eix-c3="">€ 124.7M</strong></span></div>
                                                <div
                                                    _ngcontent-eix-c3="" className="card-body p-0">
                                                    <div _ngcontent-eix-c3="" className="table-responsive">
                                                        <table _ngcontent-eix-c3="" className="table table-hover betfair-tbl tbl-bets p-rltv" style={{ marginBottom: 0, background: '#FAF8D8' }}>
                                                            <thead _ngcontent-eix-c3="">

                                                                <th _ngcontent-eix-c3="" className />
                                                                <th _ngcontent-eix-c3="" colSpan={3} style={{ textAlign: 'right', paddingRight: '20px' }}>Back</th>
                                                                <th _ngcontent-eix-c3="" colSpan={3} style={{ textAlign: 'left', paddingLeft: '20px' }}>Lay</th>

                                                            </thead>
                                                            <tbody _ngcontent-eix-c3="">
                                                                <RunnerRow
                                                                    blockStatus={(bookData.gameStatus != 2 || bookData.runner1Status == 2) &&

                                                                        <div _ngcontent-eix-c3="" class="suspend-bookmaker">
                                                                            <span _ngcontent-eix-c3="" class="stats-text">{bookData.gameStatus == 10 && bookData.runner1Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                                                    }
                                                                    firstPl={pnlBook.teamA && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) &&
                                                                        <li id="before" className={`${pnlBook.teamA > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }}>{pnlBook.teamA > 0 ? pnlBook.teamA : '(' + Math.abs(pnlBook.teamA) + ')'}</li>}
                                                                    secondPl={(bookdataMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' &&
                                                                        <li id="after" className={`${pnlBook2.teamA > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }}>{pnlBook2.teamA > 0 ? pnlBook2.teamA : '(' + Math.abs(pnlBook2.teamA) + ')'}</li>}
                                                                    runnerName={bookData2.runnerName1}
                                                                    backSelected={selectBack === 1}
                                                                    laySelected={selectBack === 2}
                                                                    runnerBackRate={bookData.runner1BackRate1}
                                                                    runnerLayRate={bookData.runner1LayRate1}
                                                                    onClick={(isLayOrBack, rate) => changeBook(isLayOrBack == 'back' ? 1 : 2, 1, rate)}
                                                                    veriant={marketKeys.bookmaker}
                                                                    style={{
                                                                        order: 1
                                                                    }}
                                                                />
                                                                {(bookdataMatchClickKeybordRunner && !(bookData.gameStatus != 2) && (bookData.runner1Status == 2 || bookData.runner2Status == 2 || bookData.runner3Status == 2)) &&
                                                                    <PlaceBetKeybord
                                                                        layBack={(selectBack === 1 || selectBack === 3 || selectBack === 5) ? "slip-back" : "slip-lay"}
                                                                        odds={bOdds}
                                                                        onClickOddMinus={() => (bOdds != '' || bOdds != ' ') ? setbOdds(parseFloat((parseFloat(bOdds) - 0.01).toFixed(2)).toString()) : null}
                                                                        onClickOddPlus={() => (bOdds != '' || bOdds != ' ') ? setbOdds(parseFloat((parseFloat(bOdds) + 0.01).toFixed(2)).toString()) : null}
                                                                        onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                        onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(ManualData.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                        defaultStake={defaultStake}
                                                                        onInputChnageStack={e => setdefaultStake(e)}
                                                                        onCancel={() => { closeBookBet(); setdefaultStake(dStake); }}
                                                                        onPlace={(e) => { e.preventDefault(); placeBet(marketKeys.bookmaker); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }}
                                                                        ssStack={{
                                                                            ssstake1,
                                                                            ssstake2,
                                                                            ssstake3,
                                                                            ssstake4,
                                                                            ssstake5,
                                                                            ssstake6,
                                                                            ssstake7,
                                                                            ssstake8
                                                                        }}
                                                                        minStack={bookData2.minStack}
                                                                        coinFactor={coinFactor}
                                                                        style={{
                                                                            order: (bookdataMatchClickKeybordRunner + 1)
                                                                        }}
                                                                        oddInputDisable={true}
                                                                        renderBottom={(
                                                                            <div className="bet-setting" style={{ display: ' flex' }}>
                                                                                <a onClick={() => setacceptAnyOdds(!acceptAnyOdds)} className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`}>Accept Any Odds</a>
                                                                                <span id="sportsBookMaxStake" style={{ display: ' none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet"  >Max</a></span>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                }
                                                                {/* r2 */}
                                                                <RunnerRow
                                                                    blockStatus={((bookData.gameStatus != 2) || bookData.runner2Status == 2) &&
                                                                        // <dd id="suspend" className="suspend-fancy" ><p id="info"></p></dd>
                                                                        <div _ngcontent-eix-c3="" class="suspend-bookmaker">
                                                                            <span _ngcontent-eix-c3="" class="stats-text">{bookData.gameStatus == 10 && bookData.runner2Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                                                    }
                                                                    firstPl={pnlBook.teamB && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook.teamB > 0 ? "win" : "lose"}`} >{pnlBook.teamB > 0 ? pnlBook.teamB : '(' + Math.abs(pnlBook.teamB) + ')'}</li>}
                                                                    secondPl={(bookdataMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamB > 0 ? pnlBook2.teamB : '(' + Math.abs(pnlBook2.teamB) + ')'}</li>}
                                                                    runnerName={marketData.runnerName2}
                                                                    backSelected={selectBack === 3}
                                                                    laySelected={selectBack === 4}
                                                                    runnerBackRate={bookData.runner2BackRate1}
                                                                    runnerLayRate={bookData.runner2LayRate1}
                                                                    onClick={(isLayOrBack, rate) => changeBook(isLayOrBack == 'back' ? 3 : 4, 2, rate)}
                                                                    veriant={marketKeys.bookmaker}
                                                                    style={{
                                                                        order: 2
                                                                    }}
                                                                />
                                                                {/* r3 */}
                                                                {bookData2.runnerId3 && bookData2.runnerId3 != ' ' &&
                                                                    <RunnerRow
                                                                        blockStatus={((bookData.gameStatus != 2) || bookData.runner3Status == 2) && <dd id="suspend" className="suspend-fancy" ><p id="info">{bookData.gameStatus == 10 && bookData.runner3Status == 1 ? 'Ball Running' : 'Suspend'}</p></dd>}
                                                                        firstPl={pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook.tie > 0 ? "win" : "lose"}`} >{pnlBook.tie > 0 ? pnlBook.tie : '(' + Math.abs(pnlBook.tie) + ')'}</li>}
                                                                        secondPl={(bookdataMatchClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.tie > 0 ? pnlBook2.tie : '(' + Math.abs(pnlBook2.tie) + ')'}</li>}
                                                                        runnerName="The Draw"
                                                                        backSelected={selectBack === 5}
                                                                        laySelected={selectBack === 6}
                                                                        runnerBackRate={bookData.tieBackRate1}
                                                                        runnerLayRate={bookData.tieLayRate1}
                                                                        onClick={(isLayOrBack, rate) => changeBook(isLayOrBack == 'back' ? 5 : 6, 3, rate)}
                                                                        veriant={marketKeys.bookmaker}
                                                                        style={{
                                                                            order: 3
                                                                        }}
                                                                    />}



                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {eventT == 1 && (
                                        <>
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("0.5") && (
                                                <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                                    <div _ngcontent-wrf-c3 className="card-matchodds">
                                                        <strong _ngcontent-wrf-c3 className="match-odds">overUnderGoals 0.5
                                                            <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                        <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                            <strong _ngcontent-wrf-c3>€ 30.3M</strong></span></div>
                                                    <div className='card-body p-0'>
                                                        <div className='table-responsive'>
                                                            <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].matchStatus == 1 && (<RunnerHeader
                                                                    totalStake={goalsSoccerSocket05Data?.totalMatched}
                                                                    onHideMaxStack={handleMaxStakeclose}
                                                                    maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.maxStack}
                                                                    coinFactor={coinFactor} />)}
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].matchStatus == 1 &&
                                                                    <tbody _ngcontent-jxa-c3="">
                                                                        <RunnerRow
                                                                            blockStatus={
                                                                                goalsSoccerSocket05Data.status === 1 || goalsSoccerSocket05Data.status === 0 || goalsSoccerSocket05Data.status === 2 &&
                                                                                <dd id="suspend" className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>
                                                                            }
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')]?.teamA) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('0.5')]?.teamA) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.runnerName1}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            runnerBackRate={goalsSoccerSocket05Data.runner1BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket05Data.runner1BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket05Data.runner1LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket05Data.runner1LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('0.5'))}
                                                                            style={{
                                                                                order: 1,
                                                                            }}
                                                                            className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                                                        />
                                                                        {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('0.5')) && (goalsSoccerSocket05Data.gameStatus != 2 || goalsSoccerSocket05Data.runner1Status == 2 || goalsSoccerSocket05Data.runner2Status == 2)) &&
                                                                            <PlaceBetKeybord
                                                                                layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                                                odds={goalsOnKeyBordOn?.odd}
                                                                                onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                                                onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')].minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('0.5')].minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                                defaultStake={defaultStake}
                                                                                onInputChnageStack={e => setdefaultStake(e)}
                                                                                onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                                                onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                                                ssStack={{
                                                                                    ssstake1,
                                                                                    ssstake2,
                                                                                    ssstake3,
                                                                                    ssstake4,
                                                                                    ssstake5,
                                                                                    ssstake6
                                                                                }}
                                                                                minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].minStack}
                                                                                coinFactor={coinFactor}
                                                                                style={{
                                                                                    order: (goalsOnKeyBordOn?.runner + 1)
                                                                                }}
                                                                                oddInputDisable={true}
                                                                            />}
                                                                        {/* r2 */}
                                                                        <RunnerRow
                                                                            blockStatus={goalsSoccerSocket05Data.status === 1 || goalsSoccerSocket05Data.status === 0 || goalsSoccerSocket05Data.status === 2 &&
                                                                                <dd id="suspend" className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>}
                                                                            firstPl={pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.teamB > 0 ? "win" : "lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlMarket.teamB > 0 ? pnlMarket.teamB : '(' + Math.abs(pnlMarket.teamB) + ')'}</li>}
                                                                            secondPl={(matchOddClickKeybordRunner) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.teamB > 0 ? "to-win" : "to-lose"}`} style={{ listStyle: 'none', marginLeft: '5px' }} >{pnlMarket2.teamB > 0 ? pnlMarket2.teamB : '(' + Math.abs(pnlMarket2.teamB) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.runnerName2}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            runnerBackRate={goalsSoccerSocket05Data.runner2BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket05Data.runner2BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket05Data.runner2LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket05Data.runner2LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('0.5'))}
                                                                            style={{
                                                                                order: 2,
                                                                            }}
                                                                            className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                                                        />


                                                                    </tbody>

                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("1.5") && (
                                                <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                                    <div _ngcontent-wrf-c3 className="card-matchodds">
                                                        <strong _ngcontent-wrf-c3 className="match-odds">overUnderGoals 1.5
                                                            <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                        <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                            <strong _ngcontent-wrf-c3>€ 30.3M</strong></span></div>
                                                    <div className='card-body p-0'>
                                                        <div className='table-responsive'>
                                                            <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].matchStatus == 1 && (
                                                                    <RunnerHeader
                                                                        totalStake={goalsSoccerSocket15Data?.totalMatched}
                                                                        onHideMaxStack={handleMaxStakeclose}
                                                                        maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.maxStack}
                                                                        coinFactor={coinFactor} />)}
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].matchStatus == 1 &&
                                                                    <tbody _ngcontent-jxa-c3="">
                                                                        <RunnerRow
                                                                            blockStatus={
                                                                                goalsSoccerSocket15Data.status === 1 || goalsSoccerSocket15Data.status === 0 || goalsSoccerSocket15Data.status === 2 &&
                                                                                <dd id="suspend" className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>
                                                                            }
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamA) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.runnerName1}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            runnerBackRate={goalsSoccerSocket15Data.runner1BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket15Data.runner1BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket15Data.runner1LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket15Data.runner1LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('1.5'))}
                                                                            style={{
                                                                                order: 1,
                                                                            }}
                                                                            className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                                                        />
                                                                        {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('1.5')) && (goalsSoccerSocket15Data.gameStatus != 2 || goalsSoccerSocket15Data.runner1Status == 2 || goalsSoccerSocket15Data.runner2Status == 2)) && <PlaceBetKeybord
                                                                            layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                                            odds={goalsOnKeyBordOn?.odd}
                                                                            onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                                            onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                                            onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                            onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                            defaultStake={defaultStake}
                                                                            onInputChnageStack={e => setdefaultStake(e)}
                                                                            onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                                            onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                                            ssStack={{
                                                                                ssstake1,
                                                                                ssstake2,
                                                                                ssstake3,
                                                                                ssstake4,
                                                                                ssstake5,
                                                                                ssstake6
                                                                            }}
                                                                            minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].minStack}
                                                                            coinFactor={coinFactor}
                                                                            style={{
                                                                                order: (goalsOnKeyBordOn?.runner + 1)
                                                                            }}
                                                                        />}
                                                                        {/* r2 */}
                                                                        <RunnerRow
                                                                            blockStatus={goalsSoccerSocket15Data.status === 1 || goalsSoccerSocket15Data.status === 0 || goalsSoccerSocket15Data.status === 2 && <dd id="suspend" className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                            </p></dd>}
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('1.5')]?.teamB) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('1.5')]?.teamB) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.runnerName2}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            runnerBackRate={goalsSoccerSocket15Data.runner2BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket15Data.runner2BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket15Data.runner2LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket15Data.runner2LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('1.5'))}
                                                                            style={{
                                                                                order: 2,
                                                                            }}
                                                                            className={(runner1BackRate1 >= 25) ? "disabled" : "null"}
                                                                        />


                                                                    </tbody>

                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("2.5") && (
                                                <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                                    <div _ngcontent-wrf-c3 className="card-matchodds">
                                                        <strong _ngcontent-wrf-c3 className="match-odds">overUnderGoals 2.5
                                                            <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                        <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                            <strong _ngcontent-wrf-c3>€ 30.3M</strong></span></div>
                                                    <div className='card-body p-0'>
                                                        <div className='table-responsive'>
                                                            <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].matchStatus == 1 && (
                                                                    <RunnerHeader
                                                                        totalStake={goalsSoccerSocket25Data?.totalMatched}
                                                                        onHideMaxStack={handleMaxStakeclose}
                                                                        maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.maxStack}
                                                                        coinFactor={coinFactor} />)}
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].matchStatus == 1 &&
                                                                    <tbody _ngcontent-jxa-c3="">
                                                                        <RunnerRow

                                                                            blockStatus={
                                                                                goalsSoccerSocket25Data.status === 1 || goalsSoccerSocket25Data.status === 0 || goalsSoccerSocket25Data.status === 2 &&
                                                                                <dd id="suspend" className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>
                                                                            }
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamA) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.runnerName1}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            runnerBackRate={goalsSoccerSocket25Data.runner1BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket25Data.runner1BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket25Data.runner1LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket25Data.runner1LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('2.5'))}
                                                                            style={{
                                                                                order: 1,
                                                                            }}

                                                                        />
                                                                        {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('2.5')) && (goalsSoccerSocket25Data.gameStatus != 2 || goalsSoccerSocket25Data.runner1Status == 2 || goalsSoccerSocket25Data.runner2Status == 2)) &&
                                                                            <PlaceBetKeybord
                                                                                layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                                                odds={goalsOnKeyBordOn?.odd}
                                                                                onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                                                onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                                defaultStake={defaultStake}
                                                                                onInputChnageStack={e => setdefaultStake(e)}
                                                                                onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                                                onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                                                ssStack={{
                                                                                    ssstake1,
                                                                                    ssstake2,
                                                                                    ssstake3,
                                                                                    ssstake4,
                                                                                    ssstake5,
                                                                                    ssstake6
                                                                                }}
                                                                                minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].minStack}
                                                                                coinFactor={coinFactor}
                                                                                style={{
                                                                                    order: (goalsOnKeyBordOn?.runner + 1)
                                                                                }}
                                                                            />}
                                                                        {/* r2 */}
                                                                        <RunnerRow
                                                                            blockStatus={goalsSoccerSocket25Data.status === 1 || goalsSoccerSocket25Data.status === 0 || goalsSoccerSocket25Data.status === 2 &&
                                                                                <dd className="suspend" style={goalsSoccerSocket25Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>}
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('2.5')]?.teamB) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('2.5')]?.teamB) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.runnerName2}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            runnerBackRate={goalsSoccerSocket25Data.runner2BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket25Data.runner2BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket25Data.runner2LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket25Data.runner2LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('2.5'))}
                                                                            style={{
                                                                                order: 2,
                                                                            }}
                                                                        />


                                                                    </tbody>

                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("3.5") && (
                                                <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                                    <div _ngcontent-wrf-c3 className="card-matchodds">
                                                        <strong _ngcontent-wrf-c3 className="match-odds">overUnderGoals 3.5
                                                            <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                        <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                            <strong _ngcontent-wrf-c3>€ 30.3M</strong></span></div>
                                                    <div className='card-body p-0'>
                                                        <div className='table-responsive'>
                                                            <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.matchStatus == 1 && (
                                                                    <RunnerHeader
                                                                        totalStake={goalsSoccerSocket35Data?.totalMatched}
                                                                        onHideMaxStack={handleMaxStakeclose}
                                                                        maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.maxStack}
                                                                        coinFactor={coinFactor} />)}
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.matchStatus == 1 &&
                                                                    <tbody _ngcontent-jxa-c3="">
                                                                        <RunnerRow

                                                                            blockStatus={goalsSoccerSocket35Data.status === 1 || goalsSoccerSocket35Data.status === 0 || goalsSoccerSocket35Data.status === 2 &&
                                                                                <dd id="suspend" className="suspend" style={goalsSoccerSocket05Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>
                                                                            }
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamA) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.runnerName1}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            runnerBackRate={goalsSoccerSocket35Data.runner1BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket35Data.runner1BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket35Data.runner1LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket35Data.runner1LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('3.5'))}
                                                                            style={{
                                                                                order: 1,
                                                                            }}
                                                                        />
                                                                        {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('3.5')) && (goalsSoccerSocket35Data.gameStatus != 2 || goalsSoccerSocket35Data.runner1Status == 2 || goalsSoccerSocket35Data.runner2Status == 2)) &&
                                                                            <PlaceBetKeybord
                                                                                layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                                                odds={goalsOnKeyBordOn?.odd}
                                                                                onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                                                onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                                                onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                                onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                                defaultStake={defaultStake}
                                                                                onInputChnageStack={e => setdefaultStake(e)}
                                                                                onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                                                onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                                                ssStack={{
                                                                                    ssstake1,
                                                                                    ssstake2,
                                                                                    ssstake3,
                                                                                    ssstake4,
                                                                                    ssstake5,
                                                                                    ssstake6
                                                                                }}
                                                                                minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')].minStack}
                                                                                coinFactor={coinFactor}
                                                                                style={{
                                                                                    order: (goalsOnKeyBordOn?.runner + 1)
                                                                                }}
                                                                            />}
                                                                        {/* r2 */}
                                                                        <RunnerRow
                                                                            blockStatus={goalsSoccerSocket35Data.status === 1 || goalsSoccerSocket35Data.status === 0 || goalsSoccerSocket35Data.status === 2 &&
                                                                                <dd className="suspend" style={goalsSoccerSocket25Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>}
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('3.5')]?.teamB) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('3.5')]?.teamB) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.runnerName2}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            runnerBackRate={goalsSoccerSocket35Data.runner2BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket35Data.runner2BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket35Data.runner2LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket35Data.runner2LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('3.5'))}
                                                                            style={{
                                                                                order: 2,
                                                                            }}
                                                                        />


                                                                    </tbody>

                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.eventName && highlightTab == marketKeys.overUnderGoals("4.5") && (
                                                <div _ngcontent-wrf-c3="" className='card mb-3 matchodd'>
                                                    <div _ngcontent-wrf-c3 className="card-matchodds">
                                                        <strong _ngcontent-wrf-c3 className="match-odds">overUnderGoals 4.5
                                                            <span _ngcontent-wrf-c3 className="marketinfo ml-2" /></strong> {/**/}
                                                        <span _ngcontent-wrf-c3 className="matched-count  pull-right">Matched
                                                            <strong _ngcontent-wrf-c3>€ 30.3M</strong></span></div>
                                                    <div className='card-body p-0'>
                                                        <div className='table-responsive'>
                                                            <table className='table table-hover tbl-bets betfair-tbl p-rltv'>
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].matchStatus == 1 && (
                                                                    <RunnerHeader
                                                                        totalStake={goalsSoccerSocket45Data?.totalMatched}
                                                                        onHideMaxStack={handleMaxStakeclose}
                                                                        maxStake={Maxstake && goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.maxStack}
                                                                        coinFactor={coinFactor} />)}
                                                                {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].matchStatus == 1 &&
                                                                    <tbody _ngcontent-jxa-c3="">
                                                                        <RunnerRow

                                                                            blockStatus={
                                                                                goalsSoccerSocket45Data.status === 1 || goalsSoccerSocket45Data.status === 0 || goalsSoccerSocket45Data.status === 2 &&
                                                                                <dd id="suspend" className="suspend" style={goalsSoccerSocket45Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>
                                                                            }
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamA) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.runnerName1}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 1}
                                                                            runnerBackRate={goalsSoccerSocket45Data.runner1BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket45Data.runner1BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket45Data.runner1LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket45Data.runner1LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 1, rate, marketKeys.overUnderGoals('4.5'))}
                                                                            style={{
                                                                                order: 1,
                                                                            }}
                                                                        />
                                                                        {((goalsOnKeyBordOn?.show && goalsOnKeyBordOn?.currMarket == marketKeys.overUnderGoals('4.5')) && (goalsSoccerSocket45Data.gameStatus != 2 || goalsSoccerSocket45Data.runner1Status == 2 || goalsSoccerSocket45Data.runner2Status == 2)) && <PlaceBetKeybord
                                                                            layBack={goalsOnKeyBordOn?.isBack ? "back" : "lay"}
                                                                            odds={goalsOnKeyBordOn?.odd}
                                                                            onClickOddMinus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) - 0.01).toFixed(2)).toString() }) : null}
                                                                            onClickOddPlus={() => (goalsOnKeyBordOn?.odd != '' || goalsOnKeyBordOn?.odd != ' ') ? setGoalsOnKeyBordOn({ ...goalsOnKeyBordOn, odd: parseFloat((parseFloat(goalsOnKeyBordOn?.odd) + 0.01).toFixed(2)).toString() }) : null}
                                                                            onClickStackMinus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.minStack) * coinFactor).toString()) : (parseInt(defaultStake) > 0) ? setdefaultStake((parseInt(defaultStake) - 1).toString()) : null}
                                                                            onClickStackPlus={() => (defaultStake === '' || defaultStake === ' ') ? setdefaultStake((parseInt(goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.minStack) * coinFactor).toString()) : setdefaultStake((parseInt(defaultStake) + 1).toString())}
                                                                            defaultStake={defaultStake}
                                                                            onInputChnageStack={e => setdefaultStake(e)}
                                                                            onCancel={() => { onCloseGoalMarketLayBack(); setdefaultStake(dStake); }}
                                                                            onPlace={(e) => { e.preventDefault(); setShowConfirmGoalsBet(true); tmpOnCloseGoalMarketLayBack(); }}
                                                                            ssStack={{
                                                                                ssstake1,
                                                                                ssstake2,
                                                                                ssstake3,
                                                                                ssstake4,
                                                                                ssstake5,
                                                                                ssstake6
                                                                            }}
                                                                            minStack={goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].minStack}
                                                                            coinFactor={coinFactor}
                                                                            style={{
                                                                                order: (goalsOnKeyBordOn?.runner + 1)
                                                                            }}
                                                                        />}
                                                                        {/* r2 */}
                                                                        <RunnerRow
                                                                            blockStatus={goalsSoccerSocket45Data.status === 1 || goalsSoccerSocket45Data.status === 0 || goalsSoccerSocket45Data.status === 2 &&
                                                                                <dd className="suspend" style={goalsSoccerSocket45Data.status != 1 ? { display: 'flex', zIndex: 22 } : { display: 'none' }}><p>Suspend
                                                                                </p></dd>}
                                                                            firstPl={goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA && (goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamA != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB != 0 || goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.tie != 0) && <li id="withoutBet" className={`${goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? "win" : "lose"}`} >{goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB : '(' + Math.abs(goalsSoccerApiPlMarketData[marketKeys.overUnderGoals('4.5')]?.teamB) + ')'}</li>}
                                                                            secondPl={(goalsOnKeyBordOn?.show) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? "to-win" : "to-lose"}`} >{goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB > 0 ? goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB : '(' + Math.abs(goalsSoccerPlMarket2Data[marketKeys.overUnderGoals('4.5')]?.teamB) + ')'}</li>}
                                                                            runnerName={goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.runnerName2}
                                                                            backSelected={goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            laySelected={!goalsOnKeyBordOn?.isBack && goalsOnKeyBordOn?.runner == 2}
                                                                            runnerBackRate={goalsSoccerSocket45Data.runner2BackRate1}
                                                                            runnerBackSize={goalsSoccerSocket45Data.runner2BackSize1}
                                                                            runnerLayRate={goalsSoccerSocket45Data.runner2LayRate1}
                                                                            runnerLaySize={goalsSoccerSocket45Data.runner2LaySize1}
                                                                            onClick={(isLayOrBack, rate) => onClickGoalMarketLayBack(isLayOrBack == 'back', 2, rate, marketKeys.overUnderGoals('4.5'))}
                                                                            style={{
                                                                                order: 2,
                                                                            }}
                                                                        />


                                                                    </tbody>

                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {(eventT == 4 || eventT == 2378962) && <>
                                        {showFancyBook && <FancyBook runner={fancyRunner} userid={props.userid} token={props.utoken} handleFancyBook={handleFancyBook} eventId={eventid} selection={fancySelection} />}
                                        <div _ngcontent-mqd-c3="" className="" style={{ border: 'none', marginTop: '38px', padding: 0, background: 'none', height: '5px' }}>
                                            <div _ngcontent-mqd-c3="" className="p-rltv">
                                                <div _ngcontent-mqd-c3="" className="fancy-head" id="fancyBetHeadNew">
                                                    <h4 _ngcontent-mqd-c3="" className="in-play">
                                                        <span _ngcontent-mqd-c3="" id="headerName">Fancy Bet</span>
                                                        <a _ngcontent-mqd-c3="" className="btn-fancybet_rules" /></h4>{/**/}
                                                    {(eventT == 4 && props.isLoggedIn === true) && <h4 _ngcontent-mqd-c3="" className="in-play-sportbook">
                                                        <span _ngcontent-mqd-c3="" id="headerName">Sportsbook</span>
                                                        <a _ngcontent-mqd-c3="" className="btn-sportsbook_rules" /></h4>}
                                                </div>{/**/}

                                                {/**/}
                                            </div>
                                        </div>
                                        {moment() <= moment(marketStartTime) && fancyData.length == 0 &&

                                            <div id="noGameWrap" className="bets-wrap bets-info-line" style={{ display: ' block' }}>
                                                <p className="no-game">Fancybet market will be available after Event at In-Play</p>
                                            </div>

                                        }
                                        <div _ngcontent-mqd-c3 className="mb-1 fancy-card" style={{}}>
                                            <div _ngcontent-mqd-c3 className="card-header card-accrdn card-fancybet" />
                                            <div _ngcontent-mqd-c3 className="card-body p-0">
                                                <div _ngcontent-mqd-c3 className="table-responsive">
                                                    <table _ngcontent-mqd-c3 className="table table-hover tbl-bets fancy-bet p-rltv">
                                                        {fancyData.length > 0 && <thead _ngcontent-mqd-c3>
                                                            <tr>
                                                                <th _ngcontent-mqd-c3 className="minwidth" colSpan={2}>
                                                                    <marquee _ngcontent-mqd-c3 className="pull-right fancy-marquee" direction="scroll"><strong _ngcontent-mqd-c3 /></marquee>
                                                                </th>
                                                                <th _ngcontent-mqd-c3 className="lay-1">No</th>
                                                                <th _ngcontent-mqd-c3 className="back-1">Yes</th>
                                                                <th _ngcontent-mqd-c3 className="hidden-xs">Min/Max</th>
                                                            </tr>
                                                        </thead>}
                                                        <tbody _ngcontent-mqd-c3>
                                                            {fancyData.map(function (item, index) {

                                                                var pnl = null;
                                                                var pnl2 = null;


                                                                if (pnlFancy[item.selectionId]) {
                                                                    pnl = parseFloat(pnlFancy[item.selectionId]).toFixed(2);
                                                                }
                                                                if (click === index) {

                                                                    if (field === 1 && defaultStake != ' ' && defaultStake != '') {
                                                                        pnl2 = -(parseFloat(fsize / 100) * parseFloat(defaultStake)).toFixed(2);
                                                                    }
                                                                    else if (field === 2 && defaultStake != ' ' && defaultStake != '') {
                                                                        pnl2 = -Math.abs(defaultStake);
                                                                    }

                                                                }



                                                                return (
                                                                    <React.Fragment key={index}>

                                                                        <tr _ngcontent-mqd-c3 className="odd-row 101202310872837976">
                                                                            <td _ngcontent-mqd-c3 className="team-name valign-middle">
                                                                                <a _ngcontent-mqd-c3 className="title">{item.runnerName} </a>
                                                                                <span _ngcontent-mqd-c3 className={`${pnl > 0 ? "plus-book" : "minus-book"}`}>
                                                                                    <i _ngcontent-mqd-c3 className="fa fa-arrow-right" />{pnl > 0 ? pnl : '(' + Math.abs(pnl) + ')'}</span></td>
                                                                            <td _ngcontent-mqd-c3 className="valign-middle mbl-book"
                                                                                style={{ position: 'relative' }}>
                                                                                {pnl && <button _ngcontent-mqd-c3 className="book-btn btn btn-primary " onClick={() => {
                                                                                    setfancySelection(item.selectionId);
                                                                                    setfancyRunner(item.runnerName); handleFancyBook(true);
                                                                                }}>Book</button>}
                                                                                <a _ngcontent-tif-c3="" className="btn-fancy_info" onClick={() => { setfancyStakeInfo(index); }} />
                                                                                {fancyStakeInfo === index && <div _ngcontent-tif-c3="" id="fancy_popup2" className="fancy_info-popup 101202310872837976">
                                                                                    <dl _ngcontent-tif-c3=""><dt _ngcontent-tif-c3="">Min / Max</dt>
                                                                                        <dd _ngcontent-tif-c3="" id="minMax">{parseInt(item.minStack) * coinFactor} / {parseInt(item.maxStack) * coinFactor} </dd>
                                                                                    </dl><a _ngcontent-tif-c3="" className="close" onClick={() => { setfancyStakeInfo(-1); }} id="close-fancy_info">Close</a></div>}
                                                                            </td>

                                                                            <td _ngcontent-mqd-c3 id="fancy101202310872837976" className={`lay-1 count ${(selectFancy === 1 && click === index && field === 1) ? "select" : "null"}`}>
                                                                                <a _ngcontent-mqd-c3 onClick={() => { if (!props.isLoggedIn) { props.checkShowLogin(true); return; } OpenfancyBet(index); setfOdds(item.layPrice); setfsize(item.laySize); }} >{item.layPrice ? item.layPrice : '--'}<span>{item.laySize ? item.laySize : '--'}</span> </a>{/**/}</td>
                                                                            <td _ngcontent-mqd-c3 id="fancy101202310872837976" className={`back-1 count p-rltv ${(selectFancyLay === 1 && click === index && field === 2) ? "select" : "null"}`} >
                                                                                {(item.gameStatus != 2) && <div _ngcontent-ubj-c3="" class="spnd-fncy">
                                                                                    <span _ngcontent-ubj-c3="" class="stats-text">{item.gameStatus == 10 ? 'Ball Running' : 'Suspend'}</span></div>}
                                                                                <a _ngcontent-mqd-c3 onClick={() => { if (!props.isLoggedIn) { props.checkShowLogin(true); return; } OpenfancyBetLay(index); setfOdds(item.backPrice); setfsize(item.backSize); }} > {item.backPrice ? item.backPrice : '--'}<span>{item.backSize ? item.backSize : '--'}</span></a>{/**/}</td>
                                                                            <td _ngcontent-mqd-c3 className="min-max hidden-xs"> 100-25000</td>
                                                                        </tr>
                                                                        {/* betslips */}
                                                                        {click === index && <tr _ngcontent-vdu-c4="" className={`fancy-quick-tr ${field === 1 ? "slip-lay" : "slip-back"}`} style={{ display: 'table-row' }}>
                                                                            <td colspan="7">
                                                                                <dl _ngcontent-vdu-c4="" className="quick_bet-wrap" id="classWrap">
                                                                                    <dt _ngcontent-vdu-c4="" className id="fancyBetHeader">
                                                                                        <span _ngcontent-vdu-c4="" className="bet-check" id="fancyBetAcceptCheck" /></dt>
                                                                                    <dd _ngcontent-vdu-c4="" className="col-btn">
                                                                                        <a _ngcontent-vdu-c4="" className="btn" id="cancel" style={{ cursor: 'pointer' }}>Cancel</a></dd>
                                                                                    <dd _ngcontent-vdu-c4="" className="col-odd" id="oddsHeader">
                                                                                        <ul _ngcontent-vdu-c4="" className="quick-bet-confirm">
                                                                                            <li _ngcontent-vdu-c4="" id="runs">{fOdds} </li>
                                                                                            <li _ngcontent-vdu-c4="" className="quick-bet-confirm-title" id="odds">{fsize}</li>
                                                                                        </ul>
                                                                                    </dd>
                                                                                    <dd _ngcontent-vdu-c4="" className="col-stake">
                                                                                        <a _ngcontent-vdu-c4="" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(item.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} className="icon-minus show-xs" id="stakeDown" />
                                                                                        <input _ngcontent-vdu-c4="" type="number" onChange={(e) => { changeDefaultStake(e); }} value={defaultStake} className="ng-untouched ng-pristine ng-valid" />
                                                                                        <a _ngcontent-vdu-c4="" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(item.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} className="icon-plus show-xs" id="stakeUp" /></dd>
                                                                                    <dd _ngcontent-vdu-c4=""
                                                                                        className="col-send">
                                                                                        <button _ngcontent-vdu-c4="" className="btn-send disable" id="placeBet" style={{ cursor: 'pointer' }}>Place Bets</button></dd>
                                                                                    <dd _ngcontent-vdu-c4="" className="col-stake_list" id="stakePopupList" style={{ display: 'block' }}>
                                                                                        <ul _ngcontent-vdu-c4="">{/**/}
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake1)} style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake2)} style={{ cursor: 'pointer' }}> {ssstake2}</a></li>
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake3)} style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake4)} style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake5)} style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake6)} style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake7)} style={{ cursor: 'pointer' }}>{ssstake7}</a></li>
                                                                                            <li _ngcontent-vdu-c4=""><a _ngcontent-vdu-c4="" className="btn" onClick={() => setdefaultStake(ssstake8)} style={{ cursor: 'pointer' }}>{ssstake8}</a></li>

                                                                                        </ul>
                                                                                    </dd>
                                                                                    <div _ngcontent-vdu-c4="" className="show-xs betmobile-btn">
                                                                                        <dd _ngcontent-vdu-c4="" className="col-btn">
                                                                                            <a _ngcontent-vdu-c4="" className="btn" id="cancel" style={{ cursor: 'pointer' }} onClick={() => { closeFancyBet(); setdefaultStake(dStake); }}>Cancel</a></dd>
                                                                                        <dd _ngcontent-vdu-c4="" className="col-send">
                                                                                            <button _ngcontent-vdu-c4="" className="btn-send disable" id="placeBet" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); fancyBet(item.selectionId, field, item.runnerName); closeFancyBet(); props.handleRefreshhh(3000, 'Placing bet...', 1); setdefaultStake(dStake); }}>Place Bets</button></dd>
                                                                                    </div>
                                                                                </dl>
                                                                            </td>
                                                                        </tr>}

                                                                    </React.Fragment>)
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div> </>}
                                </React.Fragment>
                            }
                        </div >
                    </div >
                </div >
            </div >


        </React.Fragment >
    )
}
