import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment'
require('moment-timezone');

export default function Today(props) {

  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Basketball, setBasketball] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [cricketUpdate, setcricketUpdate] = useState(false);





  useEffect(() => {
    axios.get(
      `https://liveapi247.live/api1/BasketballData`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];

        result.data.map((item) => {
          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }

        });



        setBasketball(obj);
      })
      .catch(error => console.log(error));



    axios.get(
      `https://liveapi247.live/api1/cricketData`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {

          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }

        });


        setcricket(obj);
      })
      .catch(error => console.log(error));



    axios.get(
      `https://liveapi247.live/api1/fancy`)

      .then(result => {

        setFancy(result.data);

      })


      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/tennisData`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];

        result.data.map((item) => {
          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }

        });



        setTennis(obj);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/soccerData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];

        result.data.map((item) => {
          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }
        });



        setSoccer(obj);
        setrefresh(false);
      })


      .catch(error => console.log(error));

  }, [])

  console.log(cricket, "mitesh");
  return (

    <React.Fragment>

      {refresh === true &&
        <div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
          <ul _ngcontent-qsy-c0="" class="loading">
            <li _ngcontent-qsy-c0="">
              <div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
                <div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
                  <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                  <div _ngcontent-qsy-c0=""></div>
                </div></div></li>
            <li _ngcontent-qsy-c0="">Loading...</li></ul></div>}

      {/* {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false &&
        <div class="mob-nav-highlight_list" id="inPlayData">



        {cricket.length > 0 && <div _ngcontent-njs-c67="" id="inPlayEventType_4" style={{ display: 'block' }}>
          <h3 _ngcontent-njs-c67="" >Cricket</h3>
          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_4">

            {cricket.map(function (item, id) {

              var showtoday = false;
              var showFancy = false;
              var isFancy = false;

              if (fancy.includes(item.eventId)) {
                isFancy = true;

              };

              if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                showtoday = true;
              }
              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showFancy = true;
              }
              var str = item.marketStartTime;
              var res = str.slice(11, 16);



              var today = moment();
              var thatDay = moment(item.marketStartTime);



              return (
                <React.Fragment key={id}>

                  {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&



                    <li _ngcontent-njs-c67="" id="inPlayEvent_30062394" key={id} class="inplay-off">
                      <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
                        <dl _ngcontent-njs-c67="" >
                          <dt _ngcontent-njs-c67="" >
                            <span _ngcontent-njs-c67="" id="streamingIcon" class="game-live" style={{ display: 'flex' }}>game-live</span>
                            <span _ngcontent-njs-c67="" id="lowLiquidityTag" class="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>

                            {isFancy && <span _ngcontent-njs-c67="" id="fancyBetIcon" className={`game-fancy ${showFancy ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>Fancy</span>}
                            <span _ngcontent-njs-c67="" id="bookMakerIcon" class="game-bookmaker in-play" style={{ display: 'flex' }}><pre>in-play</pre>BookMaker</span>
                            <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                            <span _ngcontent-njs-c67="" id="inPlayStatus_30062394" class="time">{res}</span>

                          </dt>
                          <dd _ngcontent-njs-c67="" id="eventName">{item.eventName}</dd>
                        </dl>
                      </Link>
                      <a _ngcontent-njs-c67=""></a>
                    </li>

                  }
                </React.Fragment>
              )
            })}

          </ul>
        </div>}




        {Soccer.length > 0 && <div _ngcontent-njs-c67="" id="inPlayEventType_1" style={{ display: 'block' }}>
          <h3>Soccer</h3>
          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_1">


            {Soccer.map(function (item, id) {

              var showtoday = false;

              if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                showtoday = true;
              }

              var str = item.marketStartTime;
              var res = str.slice(11, 16);
              var today = moment();
              var thatDay = moment(item.marketStartTime);


              return (
                <React.Fragment key={id}>
                  {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&
                    <li _ngcontent-njs-c67="" id="inPlayEvent_-10065371" key={id} class="inplay-off">
                      <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
                        <dl _ngcontent-njs-c67="" >
                          <dt _ngcontent-njs-c67="" >
                            <span _ngcontent-njs-c67="" id="streamingIcon" class="game-live" style={{ display: 'flex' }}>game-live</span>

                            <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                            <span _ngcontent-njs-c67="" id="inPlayStatus_-10065371" class="time">{res}</span>

                          </dt>
                          <dd _ngcontent-njs-c67="" id="eventName">{item.eventName}</dd>
                        </dl>
                      </Link>
                      <a _ngcontent-njs-c67="" class="pin-off"></a>
                    </li>}
                </React.Fragment>
              )
            })}


          </ul>
        </div>}

        {Tennis.length > 0 && <div _ngcontent-njs-c67="" id="inPlayEventType_2" style={{ display: 'block' }}>
          <h3 _ngcontent-njs-c67="" >Tennis</h3>
          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_2">

            {Tennis.map(function (item, id) {
              //console.log('rendering');
              var showtoday = false;

              if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                showtoday = true;
              }

              var str = item.marketStartTime;
              var res = str.slice(11, 16);
              var today = moment();
              var thatDay = moment(item.marketStartTime);


              return (
                <React.Fragment key={id}>
                  {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&

                    <li _ngcontent-njs-c67="" id="inPlayEvent_-10065496" key={id} class="inplay-off">
                      <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
                        <dl _ngcontent-njs-c67="" >
                          <dt _ngcontent-njs-c67="" >

                            <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                            <span _ngcontent-njs-c67="" id="inPlayStatus_-10065496" class="time">{res}</span>

                          </dt>
                          <dd _ngcontent-njs-c67="" id="eventName">{item.eventName}</dd>
                        </dl>
                      </Link>
                      <a _ngcontent-njs-c67="" class="pin-off" ></a>
                    </li>
                  }
                </React.Fragment>
              )
            })}
          </ul>
        </div>}

      
      </div>} */}
      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false &&
        <>

          {cricket.length > 0 &&


            <div _ngcontent-tpv-c3 className="inplay-sportwise">
              <div _ngcontent-tpv-c3 className="sporthead sporthead0">
                <h3 _ngcontent-tpv-c3>Cricket </h3>
              </div>
              <table _ngcontent-tpv-c3 className="table table-hover inplaytbl0">
                <thead _ngcontent-tpv-c3>
                  <tr>
                    <th _ngcontent-tpv-c3 className="view-mobile" colSpan={2} />
                    <th _ngcontent-tpv-c3 className="text-center">1</th>
                    <th _ngcontent-tpv-c3 className="text-center">X</th>
                    <th _ngcontent-tpv-c3 className="text-center">2</th>
                  </tr>
                </thead>
                {cricket.map(function (item, id) {

                  var showtoday = false;
                  var showFancy = false;
                  var isFancy = false;

                  if (fancy.includes(item.eventId)) {
                    isFancy = true;

                  };

                  if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                    showtoday = true;
                  }
                  if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                    showFancy = true;
                  }
                  var str = item.marketStartTime;
                  var res = str.slice(11, 16);



                  var today = moment();
                  var thatDay = moment(item.marketStartTime);



                  return (
                    <React.Fragment key={id}>

                      {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&

                        <tbody _ngcontent-tpv-c3>
                          <tr _ngcontent-tpv-c3>
                            <td _ngcontent-tpv-c3 style={{ cursor: 'pointer' }}>
                              <Link _ngcontent-tpv-c3 to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} className="match-name" onClick={() => { props.changeState(true); }}>
                                <span _ngcontent-tpv-c3>{item.eventName}</span></Link>{/**/}
                              <span _ngcontent-tpv-c3 className="match-time">{item.marketStartTime}</span></td>
                            <td _ngcontent-tpv-c3>{/**/}{/**/}{/**/}{/**/}</td>{/**/}{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">{item.runner1BackRate1}</span>
                              <span _ngcontent-tpv-c3 className="lay">{item.runner1LayRate1}</span></td>{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">3.6</span>
                              <span _ngcontent-tpv-c3 className="lay">32</span></td>{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">{item.runner2BackRate1}</span>
                              <span _ngcontent-tpv-c3 className="lay">{item.runner2LayRate1}</span></td>{/**/}{/**/}{/**/}
                          </tr>

                        </tbody>
                      }
                    </React.Fragment>
                  )
                })}
              </table>
            </div>


          }
          {Soccer.length > 0 &&
            <div _ngcontent-tpv-c3 className="inplay-sportwise">
              <div _ngcontent-tpv-c3 className="sporthead sporthead0">
                <h3 _ngcontent-tpv-c3>Soccer </h3>
              </div>
              <table _ngcontent-tpv-c3 className="table table-hover inplaytbl0">
                <thead _ngcontent-tpv-c3>
                  <tr>
                    <th _ngcontent-tpv-c3 className="view-mobile" colSpan={2} />
                    <th _ngcontent-tpv-c3 className="text-center">1</th>
                    <th _ngcontent-tpv-c3 className="text-center">X</th>
                    <th _ngcontent-tpv-c3 className="text-center">2</th>
                  </tr>
                </thead>
                {Soccer.map(function (item, id) {

                  var showtoday = false;

                  if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                    showtoday = true;
                  }

                  var str = item.marketStartTime;
                  var res = str.slice(11, 16);
                  var today = moment();
                  var thatDay = moment(item.marketStartTime);


                  return (
                    <React.Fragment key={id}>
                      {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&

                        <tbody _ngcontent-tpv-c3>
                          <tr _ngcontent-tpv-c3>
                            <td _ngcontent-tpv-c3 style={{ cursor: 'pointer' }}>
                              <Link _ngcontent-tpv-c3 to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} className="match-name" onClick={() => { props.changeState(true); }}>
                                <span _ngcontent-tpv-c3>{item.eventName}</span></Link>{/**/}
                              <span _ngcontent-tpv-c3 className="match-time">{item.marketStartTime}</span></td>
                            <td _ngcontent-tpv-c3>{/**/}{/**/}{/**/}{/**/}</td>{/**/}{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">{item.runner1BackRate1}</span>
                              <span _ngcontent-tpv-c3 className="lay">{item.runner1LayRate1}</span></td>{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">3.6</span>
                              <span _ngcontent-tpv-c3 className="lay">32</span></td>{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">{item.runner2BackRate1}</span>
                              <span _ngcontent-tpv-c3 className="lay">{item.runner2LayRate1}</span></td>{/**/}{/**/}{/**/}
                          </tr>

                        </tbody>
                      }
                    </React.Fragment>
                  )
                })}
              </table>
            </div>

          }
          {Tennis.length > 0 &&
            <div _ngcontent-tpv-c3 className="inplay-sportwise">
              <div _ngcontent-tpv-c3 className="sporthead sporthead0">
                <h3 _ngcontent-tpv-c3>Tennis </h3>
              </div>
              <table _ngcontent-tpv-c3 className="table table-hover inplaytbl0">
                <thead _ngcontent-tpv-c3>
                  <tr>
                    <th _ngcontent-tpv-c3 className="view-mobile" colSpan={2} />
                    <th _ngcontent-tpv-c3 className="text-center">1</th>
                    <th _ngcontent-tpv-c3 className="text-center">X</th>
                    <th _ngcontent-tpv-c3 className="text-center">2</th>
                  </tr>
                </thead>
                {Tennis.map(function (item, id) {
                  //console.log('rendering');
                  var showtoday = false;

                  if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                    showtoday = true;
                  }

                  var str = item.marketStartTime;
                  var res = str.slice(11, 16);
                  var today = moment();
                  var thatDay = moment(item.marketStartTime);


                  return (
                    <React.Fragment key={id}>
                      {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&


                        <tbody _ngcontent-tpv-c3>
                          <tr _ngcontent-tpv-c3>
                            <td _ngcontent-tpv-c3 style={{ cursor: 'pointer' }}>
                              <Link _ngcontent-tpv-c3 to={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} className="match-name" onClick={() => { props.changeState(true); }}>
                                <span _ngcontent-tpv-c3>{item.eventName}</span></Link>{/**/}
                              <span _ngcontent-tpv-c3 className="match-time">{item.marketStartTime}</span></td>
                            <td _ngcontent-tpv-c3>{/**/}{/**/}{/**/}{/**/}</td>{/**/}{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">{item.runner1BackRate1}</span>
                              <span _ngcontent-tpv-c3 className="lay">{item.runner1LayRate1}</span></td>{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">3.6</span>
                              <span _ngcontent-tpv-c3 className="lay">32</span></td>{/**/}{/**/}
                            <td _ngcontent-tpv-c3 className="count">
                              <span _ngcontent-tpv-c3 className="back">{item.runner2BackRate1}</span>
                              <span _ngcontent-tpv-c3 className="lay">{item.runner2LayRate1}</span></td>{/**/}{/**/}{/**/}
                          </tr>

                        </tbody>
                      }
                    </React.Fragment>
                  )
                })}
              </table>
            </div>

          }
        </>}

      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && Basketball.length === 0) && refresh === false &&
        <div id="noMultiMarkets" className="no-data">
          <h3>There are currently no matches Today.</h3>
          <p>Please check upcoming matches.</p>
        </div>
      }

    </React.Fragment>
  )
}
