import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
import Cookies from 'universal-cookie';
require('moment-timezone');

const cookies = new Cookies();

export default function CricketMatches(props) {

  const [match, setMatch] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);






  // vvvv

  useEffect(() => {

    Axios.get(
      `https://liveapi247.live/api1/cricketData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }

      })



      .catch(error => console.log(error));

    Axios.get(
      `https://liveapi247.live/api1/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://liveapi247.live/api1/fancy`)

      .then(result => {

        setFancy(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }

      })


      .catch(error => console.log(error));




  }, []);





  return (
    <React.Fragment>


      {newrefresh === true &&
        // <div _ngcontent-njs-c67="" className="loading-overlay" id="loading">
        // <div _ngcontent-njs-c67="" className="loading-wrap " style={{ display: " flex" }}>
        //   <div class="loading">
        //     <div></div>
        //     <div></div>
        //   </div>
        //   <p>Loading...</p>
        // </div>
        // </div>
        <div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
          <ul _ngcontent-qsy-c0="" class="loading">
            <li _ngcontent-qsy-c0="">
              <div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
                <div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
                  <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                  <div _ngcontent-qsy-c0=""></div>
                </div></div></li>
            <li _ngcontent-qsy-c0="">Loading...</li></ul></div>
      }



      {newrefresh === false && <  >

        {match.map(function (item, id) {

          var showColor = false;
          var showFancy = false;
          var isFancy = false;
          var showtv = false;
          var isbookmaker = false;


          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }



          if (fancy.includes(item.eventId)) {
            isFancy = true;

          }
          if (tv.includes(item.eventId)) {
            showtv = true;
          }
          if (bookmaker.includes(item.eventId)) {
            isbookmaker = true;

          }

          if (moment().diff(moment(item.marketStartTime), 'minutes') >= 0) {
            showColor = true;
            showFancy = true;
          }

          return (
            // <div _ngcontent-njs-c67="" key={id}>

            //   <li _ngcontent-njs-c67="" id="highlightEvent_-10020846" className={`inplay${showColor ? "-on" : "-off"}`}>
            //     <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} id="eventName" onClick={() => { props.changeState(true); }}>
            //       <dl _ngcontent-njs-c67="" >
            //         <dt _ngcontent-njs-c67="" >
            //           {moment() >= moment(item.marketStartTime) &&
            //             <span _ngcontent-njs-c67="" id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}

            //           {isFancy && <span _ngcontent-njs-c67="" id="fancyBetIcon" className={`game-fancy ${showFancy ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>Fancy</span>}
            //           {isbookmaker &&
            //             <span id="bookMakerIcon" _ngcontent-njs-c67="" className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
            //           {moment() >= moment(item.marketStartTime) && <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>}

            //           {moment() >= moment(item.marketStartTime) &&
            //             <span id="dateTimeInfo" _ngcontent-njs-c67="" className="time">In-Play</span>}
            //           {moment() < moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">{matchTime}</span>}

            //         </dt>
            //         <dd>
            //           {item.eventName} </dd>
            //       </dl>
            //     </Link>
            //     <a id={MKPIN + id} _ngcontent-njs-c67="" className={`pin-off`} onClick={() => Multimarketpin(id, item.eventId)}></a>
            //   </li>
            // </div>
            <>
              <tr key={id}>
                <td>
                  <Link to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }} className="match-name" >
                    <span>{item.eventName} </span></Link>{/**/}
                  {moment() >= moment(item.marketStartTime) && <span className="in_play">In-Play</span>}
                  {moment() >= moment(item.marketStartTime) && <span className="in_play">
                    <i className="fa fa-television" /></span>}
                  {moment() < moment(item.marketStartTime) && <p className="match-time">
                    {matchTime}</p>}
                </td>
                <td>{/**/}{/**/}{/**/}{/**/}
                  {isFancy && <span class="game-fancy mr-1">F</span>}</td>
                <td className="pl-0 v-mid">
                  <a className="pin-off-mobile" id="multiMarketPin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a>
                </td>
              </tr>

            </>
          )
        })}
      </>}
    </React.Fragment>
  )
}
