import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { suid } from 'rand-token';
import axios from 'axios';
import FirsttimeLogin from './Mobilefirstlogin'



const cookies = new Cookies();

window.flag = 0;

var cc;
var tt;


export default function Loginmobile(props) {
	const [Socialicon, setSocialicon] = useState(0);
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [userid, setId] = useState("");
	const [password, setPassword] = useState("");
	const [captchaSuccess, setCaptcha] = useState(false);
	const [loading, setloading] = useState(false);
	const [firstlogin, setfirstlogin] = useState(0)





	function createCaptcha() {
		if (document.getElementById('captch')) {
			document.getElementById("captch").remove();
		}

		var captcha = [];
		while (captcha.length < 4) {
			//below code will not allow Repetition of Characters
			var index = Math.floor(Math.random() * 10); //get the next character from the array

			captcha.push(index);

		}
		var canv = document.createElement("canvas");
		canv.style.width = 'inherit';
		canv.id = "captch";
		canv.width = 70;
		canv.height = 50;

		var ctx = canv.getContext("2d");
		ctx.font = "600 30px Arial";
		ctx.fillText(captcha.join(""), 0, 30)
		//ctx.strokeText(captcha.join(""), 0, 30);
		//storing captcha so that can validate you can save it somewhere else according to your specific requirements
		cc = captcha.join("");

		if (document.getElementById("popupcaptcha")) {
			document.getElementById("popupcaptcha").appendChild(canv);
		}
		else {
			document.getElementById("captcha").appendChild(canv);
		}




	}


	function validateCaptcha(event) {
		//event.preventDefault();

		if (event.target.value === cc) {
			setCaptcha(true);

		} else {
			setCaptcha(false);
		}

	}

	useEffect(() => {

		createCaptcha();

	}, []);



	const handleLogin = () => {


		if (userid === '') {

			createCaptcha();
			//toast.warn('Username can not be blank!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Username is empty";


			return;
		}
		if (password === '') {
			createCaptcha();
			//toast.warn('Password can not be blank!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Password is empty";
			return;
		}

		if (!captchaSuccess) {

			createCaptcha();
			// toast.warn('Captcha is not valid!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Captcha is not valid!";
			return;
		}


		var demoid1 = 'demo01';
		//var demoid2 = 'demo02'
		var token = suid(16);
		if (userid.toUpperCase() === demoid1.toUpperCase()) {
			token = '123456789';
		}
		tt = token;

		cookies.set('id', userid, { path: '/' });
		cookies.set('token', token, { path: '/' });
		cookies.set('password', password, { path: '/' });

		axios.post('https://liveapi247.live/api1/userlogin', {
			id: userid,
			password: password,
			token: token
		})
			.then(result => {

				if (result.status === 201) {

					setfirstlogin(1);

				}
				else if (result.status === 202) {

					setfirstlogin(2);

				}

				else if (result.status === 200) {

					setLoggedIn(true);
					//props.checkLogin(true);
					setCaptcha(false);
					window.location.href = '/home';
					//window.location.reload();


				}
				else if (result.status === 205) {
					setCaptcha(false);
					//toast.warn('User Blocked!', {position:toast.POSITION.TOP_CENTER})
					document.getElementById("errorMsg").innerHTML = "User Blocked!";
					createCaptcha();
				}
				else {
					setCaptcha(false);
					//toast.warn('Username or password incorrect!', {position:toast.POSITION.TOP_CENTER})
					document.getElementById("errorMsg").innerHTML = "Username or password incorrect!";
					createCaptcha();
				}
			}

			).catch(e => {
				setIsError(true);
			});



	};



	return (
		<React.Fragment>
			{firstlogin == 0 &&
				<React.Fragment>
					<modal-container className="modal fade show" role="dialog" tabIndex={-1} aria-modal="true" style={{ display: 'block', height: "100vw" }}>
						<div role="document" className="modal-dialog modal-login">
							<div className="modal-content">
								<div _ngcontent-eoi-c0 className="login-page-new" style={{ minHeight: '100vh' }}>
									<header _ngcontent-eoi-c0 className="login-head">
										<a _ngcontent-eoi-c0 onClick={() => { props.checkShowLogin(false) }} className="ui-link" />{/**/}
										<h1 _ngcontent-eoi-c0 style={{ background: 'url("https://s3.ap-southeast-1.amazonaws.com/cdnuniverse.site/kingexchange.png")' }}>Exchange</h1>
									</header>
									<dl _ngcontent-eoi-c0 className="form-login">
										<div _ngcontent-eoi-c0 noValidate className="ng-touched ng-dirty ng-valid">
											<dd _ngcontentx-eoi-c0>
												<input _ngcontent-eoi-c0 className="form-control ng-touched ng-dirty ng-valid" formcontrolname="username" placeholder="username" value={userid} onChange={(e) => { setId(e.target.value) }} />{/**/}

											</dd>
											<dd _ngcontent-eoi-c0>
												<input _ngcontent-eoi-c0 type="password" className="form-control ng-touched ng-dirty ng-valid" formcontrolname="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />{/**/}

											</dd>
											<dd _ngcontent-eoi-c0 style={{ position: 'relative' }} >
												<input _ngcontent-eoi-c0 className="form-control ng-touched ng-dirty ng-valid" formcontrolname="validation" placeholder="validation" onChange={(e) => { validateCaptcha(e) }} />{/**/}
												<div id="popupcaptcha" className="CaptchaPopup" style={{
													position: 'absolute', top: '3px', right: '12px'
												}}></div>
												<span _ngcontent-eoi-c0 id="errorMsg" className="err-invalid-modal error-message" />
											</dd>
											<dd _ngcontent-eoi-c0>
												<button _ngcontent-eoi-c0 className="btn-send ui-link" onClick={handleLogin}>Login</button></dd>
										</div>
									</dl>
								</div>
							</div>
						</div>
					</modal-container>

				</React.Fragment>
			}
			{(firstlogin == 1 || firstlogin == 2) &&

				<FirsttimeLogin password={password} firstlogin={firstlogin} userid={userid} utoken={tt} />

			}
		</React.Fragment>
	)

}
