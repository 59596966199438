
import timer from '../images/Icons/timer.svg';
import pinpush from '../images/Icons/pinpush.svg';
import cricketball from '../images/Icons/cricket-ball.svg';
import soccerball from '../images/Icons/soccer-ball.svg';
import tennisball from '../images/Icons/tennis.svg'

// const Left = () => {

//     return (
//         <React.Fragment>
//             <div className="animated fadeIn">
//                 <div className="row">
//                     <div className="col-md-12 all-sports">
//                         <h3 className="quickHeader" style={{ display: 'block' }}>Quick Links</h3>
//                         <ul className="quick_link" id="quickBoard" style={{ display: 'flex' }}>
//                             <li><a href="#/inplay">
//                                 <img src={timer} />In-Play</a></li>
//                             <li><a href="#/multimarket">
//                                 <img src={pinpush} />Multi Markets</a></li>
//                             <li><a><img src={cricketball} />Cricket</a></li>
//                             <li><a><img src={soccerball} />Soccer</a></li>
//                             <li><a><img src={tennisball} />Tennis</a></li>
//                         </ul>
//                         <h3 className="quickHeader" style={{ display: 'block', cursor: 'pointer' }}>All Sports</h3>
//                         <nav className="all-sports-list">
//                             <ul className="sport-nav">{/**/}
//                                 <div>{/**/}
//                                     <li appnavdropdown className="nav-item toggleSidbar nav-dropdown">{/**/}
//                                         <a appnavdropdowntoggle className="nav-link nav-dropdown-toggle" href="javascript:;"> Women's Caribbean Premier League </a>
//                                         <ul className="nav-dropdown-items p-0 show-link">
//                                             <li className="nav-item toggleSidbar">{/**/}<a className="nav-link ml-2 " href="#/fullmarket/4002023910101610482/4"><i aria-hidden="true" className="fa fa-arrow-right" /> Barbados Royals W v Guyana Amazon Warriors W</a></li>
//                                         </ul>
//                                     </li>
//                                 </div>{/**/}</ul>
//                         </nav>
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     )
// }

// export default Left

import React, { useState } from 'react'
import Loading from "../images/sprite/loading40.gif"
import axios from 'axios'
import { Link } from "react-router-dom";
import Transparent from '../images/sprite/transparent.gif'
import { useParams } from 'react-router-dom';

export function MenuList(props) {

    const fetchData = (item) => {
        if (item.MarketName) {
            props.changeMenu([{ MarketName: 'Match Odds', fullmarket: true, eventId: item.eventId, marketId: item.marketId, eventType: item.sportId }]);
        }

        if (item.eventId) {
            props.changeMenu([{ MarketName: 'Match Odds', eventId: item.eventId, marketId: item.marketId, eventType: item.sportId }]);
        }
        else if (item.competitionId) {
            axios.get(
                `https://liveapi247.live/api1/events/2`, {
                params: {
                    eventType: item.sportId,
                    competitionId: item.competitionId
                }
            })

                .then(result => {
                    props.changeMenu(result.data);
                    //setMenu(result.data); 
                })
                .catch(error => console.log('error'));
        }
        else if (item.eventType) {

            axios.get(
                `https://liveapi247.live/api1/events/1`, {
                params: {
                    eventType: item.eventType
                }
            })

                .then(result => {
                    props.changeMenu(result.data);
                })
                .catch(error => console.log('error'));
        }
        else {

        }
    }
    const handleData = (item) => {
        var arr1 = props.stack;
        if (item.eventId && props.stack.length < 4) {
            arr1.push({ eventId: item.eventId, label: item.eventName });

        }

        else if (item.competitionId) {
            arr1.push({ competitionId: item.competitionId, label: item.competitionName });


        }
        else if (item.eventType && item.sport) {
            arr1.push({ eventType: item.eventType, label: item.sport });

        }
        else {
        }

        props.changeState(arr1);



        fetchData(item);



    }



    return (
        <div>
            {props.menu.map(function (item, id) {
                let item1 = null;
                let item2 = null;

                if (item.MarketName) {
                    item2 = item.MarketName;


                }
                if (item.eventId) {
                    item2 = item.eventName;
                    item1 = item.eventId;

                }

                else if (item.competitionId) {
                    item2 = item.competitionName;
                    item1 = item.competitionId;

                }
                else if (item.eventType && item.sport) {
                    item2 = item.sport;
                    item1 = item.eventType;

                }

                if (item.MarketName) {

                    return (
                        <li className="nav-item toggleSidbar" key={item.eventType}>{/**/}
                            <Link className="nav-link ml-2 " to={`/d/dash/fullmarket/${item.eventType}/${item.eventId}/${item.marketId}`}>
                                <i aria-hidden="true" className="fa fa-arrow-right" /> {item.MarketName}</Link></li>

                    );
                }

                else {
                    return (
                        <li key={id} appnavdropdown className="nav-item toggleSidbar nav-dropdown">{/**/}
                            <a appnavdropdowntoggle className="nav-link nav-dropdown-toggle" onClick={() => { handleData(item) }}>{item2}</a>
                            <ul className="nav-dropdown-items p-0 show-link">

                            </ul>
                        </li>

                    );
                }

            })}


        </div>);
}

export function BarList(props) {

    //const [menu, setMenu] = useState(props.stack);

    const changeProps = (id) => {
        var arr = props.stack.slice(0, id + 1);;

        props.changeState(arr);

        if (arr.length === 1) {
            props.changeMenu([{ eventType: 4, sport: 'Cricket' }, { eventType: 1, sport: 'Soccer' }, { eventType: 2, sport: 'Tennis' }, { eventType: 7522, sport: 'BasketBall' }, { eventType: 2378962, sport: 'Election' }]);

        }
        else if (arr.length === 2) {
            axios.get(
                `https://liveapi247.live/api1/events/1`, {
                params: {
                    eventType: arr[1].eventType
                }
            })

                .then(result => {
                    props.changeMenu(result.data);




                })


                .catch(error => console.log('error'));


        }
        else if (arr.length === 3) {

            axios.get(
                `https://liveapi247.live/api1/events/2`, {
                params: {
                    eventType: arr[1].eventType,
                    competitionId: arr[2].competitionId
                }
            })

                .then(result => {
                    props.changeMenu(result.data);


                })


                .catch(error => console.log('error'));

        }
        else if (arr.length === 4) {

        }


    }


    return (
        <div>
            {props.stack.map(function (item, id) {

                return (


                    <li _ngcontent-njs-c64="" key={id} class="">
                        <a _ngcontent-njs-c64="" onClick={() => { changeProps(id); }}>{item.label}</a>
                    </li>
                );


            })}


        </div>);
}

export default function Left(props) {

    const [stack, setStack] = useState([{ sportId: 0, label: 'Sports' }]);
    const [menu, setMenu] = useState([{ eventType: 4, sport: 'Cricket' }, { eventType: 1, sport: 'Soccer' }, { eventType: 2, sport: 'Tennis' }, { eventType: 7522, sport: 'BasketBall' }, { eventType: 2378962, sport: 'Election' }]);
    const { eventType } = useParams();
    const changeState = (obj) => {
        var obj1 = [];
        for (let i = 0; i < obj.length; i++) {
            obj1[i] = obj[i];
        }
        setStack(obj1);

    };
    const changeMenu = (obj) => {
        if (obj[0] && obj[0].fullmarket) {
            props.changeState(true);

        }
        var obj1 = [];
        for (let i = 0; i < obj.length; i++) {
            obj1[i] = obj[i];
        }
        setMenu(obj1);

    };


    return (
        <>
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-md-12 all-sports">
                        <h3 className="quickHeader" style={{ display: 'block' }}>Quick Links</h3>
                        <ul className="quick_link" id="quickBoard" style={{ display: 'flex' }}>
                            <li><a href="/d/dash/inplay">
                                <img src={timer} />In-Play</a></li>
                            <li><a href="/d/dash/multimarket">
                                <img src={pinpush} />Multi Markets</a></li>
                            <li><a><img src={cricketball} />Cricket</a></li>
                            <li><a><img src={soccerball} />Soccer</a></li>
                            <li><a><img src={tennisball} />Tennis</a></li>
                        </ul>
                        <h3 className="quickHeader" style={{ display: 'block', cursor: 'pointer' }}>All Sports</h3>
                        <nav className="all-sports-list">
                            <ul className="sport-nav">{/**/}
                                <div>{/**/}
                                    <MenuList stack={stack} changeState={changeState} menu={menu} changeMenu={changeMenu} />
                                </div>{/**/}</ul>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <div _ngcontent-njs-c64="" class="col-left">
                <div _ngcontent-njs-c64="" class="sub_path" style={{ height: 'calc(100% - 0px)' }}>
                    <div _ngcontent-njs-c64="" class="path">
                        Sports
                     
                    </div>


                    <ul _ngcontent-njs-c64="" class="sports-path-popup" id="show">
                        <li _ngcontent-njs-c64="" class=""  >
                            <a >Cricket</a>
                        </li>
                        <li _ngcontent-njs-c64="" class="">
                            <a  >Tennis</a>
                        </li>
                        <li class="">
                            <a  >.</a>
                        </li>
                        <li _ngcontent-njs-c64="" class="node"><a   >Sports</a></li>

                    </ul>




                    <ul _ngcontent-njs-c64="" class="sports-side-menu-list" >

                        <MenuList stack={stack} changeState={changeState} menu={menu} changeMenu={changeMenu} />
                    </ul>


                </div>
            </div> */}
        </>

    )
}
