import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import Loading from '../images/sprite/loading40.gif'
import axios from 'axios';

export default function FancyBook(props) {

    const [refresh, setrefresh] = useState(true);
    const [bookList, setbookList] = useState([]);

    useEffect(() => {

        // if(!props.userid) return;
        axios.post('https://liveapi247.live/api2/FancyExposure', {
            id: props.userid,
            token: props.token,
            eventId: props.eventId,
            selectionId: props.selection


        }).then(result => {
            setrefresh(false);
            if (result.status === 200) {

                var res = [];
                for (let key in result.data) {

                    if (result.data.hasOwnProperty(key)) {
                        let obj = {};
                        obj.run = key;
                        obj.position = result.data[key];

                        res.push(obj);


                    }
                }
                if (res.length < 7) {
                    res = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '];
                }
                //console.log(res)
                setbookList(res);

            }

        }
        ).catch(e => {
            //setIsError(true);
        });
    }, [])
    return (
        <React.Fragment>

            {refresh === true &&
                <div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
                    <ul _ngcontent-qsy-c0="" class="loading">
                        <li _ngcontent-qsy-c0="">
                            <div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
                                <div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
                                    <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                                    <div _ngcontent-qsy-c0=""></div>
                                </div></div></li>
                        <li _ngcontent-qsy-c0="">Loading...</li></ul></div>}
            <modal-container class="modal fade show" role="dialog" tabindex="-1" aria-modal="true" style={{ display: 'block' }}>
                <div role="document" class="modal-dialog modal-book">
                    <div class="modal-content">
                        <div _ngcontent-hum-c4="" class="modal-content">
                            <div _ngcontent-hum-c4="" class="modal-header bg-primary bet-slip-head">
                                <h6 _ngcontent-hum-c4="" class="modal-title">Book</h6>
                                <button _ngcontent-hum-c4="" class="close" type="button">
                                    <span _ngcontent-hum-c4="" aria-hidden="true" onClick={() => { props.handleFancyBook(false); }}>×</span></button></div>
                            <div _ngcontent-hum-c4="" class="modal-body p-0">
                                <div _ngcontent-hum-c4="" class="col-md-12 book-popup">
                                    {refresh === false && <table _ngcontent-hum-c4="" class="book-tbl">
                                        <thead _ngcontent-hum-c4="" class="bg-default">
                                            <th _ngcontent-hum-c4="">Run</th>
                                            <th _ngcontent-hum-c4="">Amount</th></thead>
                                        <tbody _ngcontent-hum-c4="">
                                            {bookList.map((item, index) => {
                                                return (<tr _ngcontent-hum-c4="" class="back-1">
                                                    <td _ngcontent-hum-c4="">{item.run}</td>
                                                    <td _ngcontent-hum-c4=""><strong> {item != ' ' ? parseFloat(item.position).toFixed(2) : item.position}</strong></td></tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>}</div></div></div></div></div></modal-container>
            {/* <div _ngcontent-njs-c65="" id="fancyBetBookLeftSide" className="overlay" style={{ display: 'flex' }}>
                <div _ngcontent-njs-c65="" id="sideWrap" className="side-wrap full-pop">
                    <title _ngcontent-njs-c65="" >aagun</title>
                    <div _ngcontent-njs-c65="" className="side-head">
                        <h3 _ngcontent-njs-c65="" className>Book Position</h3>
                        <a _ngcontent-njs-c65="" className="close" onClick={() => { props.handleFancyBook(false); }}>Close</a>
                    </div>

                    <div _ngcontent-njs-c65="" id="sideContent" className="side-content">
                        <div _ngcontent-njs-c65="" className="path-wrap">
                            <ul _ngcontent-njs-c65="" >
                                <li _ngcontent-njs-c65="" id="headerName">{props.runner}</li>
                            </ul>
                        </div>
                        {refresh === false &&
                            <table _ngcontent-njs-c65="" id="table" className="table table-fancy">
                                <tbody _ngcontent-njs-c65="" >
                                    <tr _ngcontent-njs-c65="" >
                                        <th _ngcontent-njs-c65="" width="40%">Runs</th>
                                        <th _ngcontent-njs-c65="" width="60%">Amount</th>
                                    </tr>

                                    {bookList.map((item, index) => {
                                        return (
                                            <tr _ngcontent-njs-c65="" id="runsEx" style={{ display: 'table-row' }}>
                                                <td _ngcontent-njs-c65="" id="runs" className="col-back"><strong>{item.run}</strong></td>
                                                <td _ngcontent-njs-c65="" id="exposure" className="col-back"><strong> {item != ' ' ? parseFloat(item.position).toFixed(2) : item.position}</strong></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>}
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    )
}