import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
require('moment-timezone');

export default function SoccerMatches(props) {


  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [ScoreData, setScoreData] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);


  // vvvv

  useEffect(() => {

    Axios.get(
      `https://liveapi247.live/api1/soccerData`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }
      })


      .catch(error => console.log(error));

    Axios.get(
      `https://liveapi247.live/api1/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));


    Axios.get(
      `https://liveapi247.live/api1/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));


  }, []);


  // console.log('Soccer');
  return (

    <React.Fragment>

      {newrefresh === true &&
        <div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
          <ul _ngcontent-qsy-c0="" class="loading">
            <li _ngcontent-qsy-c0="">
              <div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
                <div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
                  <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                  <div _ngcontent-qsy-c0=""></div>
                </div></div></li>
            <li _ngcontent-qsy-c0="">Loading...</li></ul></div>}


      {newrefresh === false &&
        <div className="events-mobile">

          <div className="table-responsive tbl-mobile-event">

            {match.map(function (item, id) {
              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }
              var showtv = false;
              var isbookmaker = false;

              var showColor = false;

              var matchTime;
              var today = moment();
              var tommorow = moment().add(1, 'day');

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;

              }

              if (moment(item.marketStartTime).isSame(today, 'day')) {
                matchTime = moment(item.marketStartTime).format('LT');
              }
              else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
              }
              else {
                matchTime = item.marketStartTime;
              }

              if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
                showColor = true;
              }


              return (


                <table className="table table-hover" key={id}>
                  <tbody>{/**/}
                    <tr>
                      <td><Link to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} className="match-name">
                        <span>{item.eventName}</span></Link>{/**/}
                        {moment() >= moment(item.marketStartTime) && <span className="in_play">In-Play</span>}
                        {moment() < moment(item.marketStartTime) && <p className="match-time ">{matchTime}</p>}
                      </td>
                      <td>{/**/}{/**/}{/**/}{/**/}</td>
                      <td className="pl-0 v-mid">
                        <a className="pin-off-mobile" id="multiMarketPin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a></td>
                    </tr>{/**/}</tbody>
                </table>

                // <li _ngcontent-njs-c67="" key={id} id="highlightEvent_29869541" className={`inplay${showColor ? "-on" : "-off"}`}>
                //   <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
                //     <dl>
                //       <dt>
                //         {moment() >= moment(item.marketStartTime) &&
                //           <span _ngcontent-njs-c67="" id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
                //         {isbookmaker &&
                //           <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
                //         {moment() >= moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium soccer</span>}
                //         {moment() >= moment(item.marketStartTime) &&
                //           <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">In-Play {elapsTime}</span>}
                //         {moment() < moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">{matchTime}</span>}
                //       </dt>

                //       {/* <span class="in_play">1 - 0</span> */}
                //       <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                //     </dl>
                //   </Link>
                //   <a _ngcontent-njs-c67="" class="pin-off" ></a>
                // </li>
              )
            })}
          </div>

        </div >
      }
    </React.Fragment >
  )
}
