import stringSimilarity from "string-similarity";

export const marketKeys = {
    matchOdds: "matchOdds",
    bookmaker: "bookmaker",
    winToss: "winToss",
    superOver: "superOver",
    tiedMatch: "tiedMatch",
    completedMatch: "completedMatch",
    moneyLine: "moneyLine",
    fancy: "fancy",
    overUnderGoals: (val) => `Over/Under ${val} Goals`
}

export const soketIoMarketKey = {
    matchOdds: "betfair_match_rate",
    bookmaker: "bookmaker_match_rate",
    winToss: "toss_match_rate",
    superOver: "Super_over_match",
    tiedMatch: "Tied_Match_Rate",
    completedMatch: "Completed_Match_rate",
    fancy: "session_rate",
    moneyLine: "betfair_match_rate",
    premium: "premium_Rate",
    miniBookMaker: "minibookmaker_match_rate",
    lunchFavorite: 'lunchFavorite_match_rate',
    [marketKeys.overUnderGoals('0.5')]: 'betfair_match_rate05',
    [marketKeys.overUnderGoals('1.5')]: 'betfair_match_rate15',
    [marketKeys.overUnderGoals('2.5')]: 'betfair_match_rate25',
    [marketKeys.overUnderGoals('3.5')]: 'betfair_match_rate35',
    [marketKeys.overUnderGoals('4.5')]: 'betfair_match_rate45',
}

export const soketEndPoint = () => {
    const ranNum = Math.floor(Math.random() * 4) + 1;
    let ENDPOINT;
    if (ranNum == 1) {
        ENDPOINT = "https://liveapi247.live:2053";
    } else if (ranNum == 2) {
        ENDPOINT = "https://liveapi247.live:2083";
    } else if (ranNum == 3) {
        ENDPOINT = "https://liveapi247.live:2087";
    } else {
        ENDPOINT = "https://liveapi247.live:2096";
    }
    return ENDPOINT;
}


export const checkStrVsStr = (str1, str2, matchRate) => stringSimilarity.findBestMatch(str1, str2).bestMatch.rating > (matchRate ? matchRate : 0.42);


