import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment'
import Cookies from 'universal-cookie';
require('moment-timezone');

const cookies = new Cookies();

export default function TennisMatches(props) {

  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);
  const [ScoreData, setScoreData] = useState([])


  // vvvv


  useEffect(() => {

    axios.get(
      `https://liveapi247.live/api1/tennisData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }


      })


      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));

  }, []);




  return (

    <React.Fragment>

      {newrefresh === true &&
        <div _ngcontent-qsy-c0="" class="loading-wrap" id="loading">
          <ul _ngcontent-qsy-c0="" class="loading">
            <li _ngcontent-qsy-c0="">
              <div _ngcontent-qsy-c0="" class="loadingio-spinner-dual-ball-srpf31lqtit">
                <div _ngcontent-qsy-c0="" class="ldio-dayih5pen3j">
                  <div _ngcontent-qsy-c0=""></div><div _ngcontent-qsy-c0=""></div>
                  <div _ngcontent-qsy-c0=""></div>
                </div></div></li>
            <li _ngcontent-qsy-c0="">Loading...</li></ul></div>}



      {newrefresh === false && <div className="events-mobile">
        <div className="row">
          <div className="col-md-12 mobile-width">
            <div className="table-responsive tbl-mobile-event">

              {match.map(function (item, id) {
                let customScore = '';
                let elapsTime = '';
                let filteredData = ScoreData &&
                  ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                // let filteredData = [];
                if (filteredData && filteredData.length > 0) {
                  customScore = filteredData[0].score;
                  // elapsTime = filteredData[0].timeElapsed;
                }

                var showtv = false;
                var isbookmaker = false;

                var showInplay = false

                var matchTime;
                var today = moment();
                var tommorow = moment().add(1, 'day');

                if (moment(item.marketStartTime).isSame(today, 'day')) {
                  matchTime = moment(item.marketStartTime).format('LT');
                }
                else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                  matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
                }
                else {
                  matchTime = item.marketStartTime;
                }


                if (tv.includes(item.eventId)) {
                  showtv = true;
                };
                if (bookmaker.includes(item.eventId)) {
                  isbookmaker = true;

                };


                if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
                  showInplay = true;
                }
                return (
                  <table className="table table-hover" key={id}>
                    <tbody>{/**/}
                      <tr>
                        <td><Link to={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} className="match-name">
                          <span>{item.eventName}</span></Link>{/**/}
                          {moment() >= moment(item.marketStartTime) && <span className="in_play">In-Play</span>}
                          {moment() < moment(item.marketStartTime) && <p className="match-time ">{matchTime}</p>}
                        </td>
                        <td>{/**/}{/**/}{/**/}{/**/}</td>
                        <td className="pl-0 v-mid">
                          <a className="pin-off-mobile" id="multiMarketPin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a></td>
                      </tr>{/**/}</tbody>
                  </table>
                  // <li _ngcontent-njs-c67="" key={id} id="highlightEvent_29869541" className={`inplay${showInplay ? "-on" : "-off"}`}>
                  //   <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }}>
                  //     <dl>
                  //       <dt>
                  //         {moment() >= moment(item.marketStartTime) &&
                  //           <span _ngcontent-njs-c67="" id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
                  //         {isbookmaker &&
                  //           <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker ${showInplay ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
                  //         {moment() >= moment(item.marketStartTime) && <span _ngcontent-njs-c67="" id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium Tennis</span>}
                  //         {moment() >= moment(item.marketStartTime) &&
                  //           <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">In-play</span>}

                  //         {moment() < moment(item.marketStartTime) &&
                  //           <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">{matchTime}</span>}
                  //       </dt>
                  //       <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                  //     </dl>
                  //   </Link>
                  //   <a _ngcontent-njs-c67="" class="pin-off"></a>

                  // </li>

                )
              })}
            </div>
          </div>
        </div>
      </div>}
    </React.Fragment >
  )
}
